import React, { useEffect, useState } from "react";
import { MotifButton } from "@ey-xd/motif-react";
import _ from "lodash";
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import noIcon from "../../../../assets/image/No.svg";
import warningCircle from "../../../../assets/image/warning-circle.svg";
import "../../InstantInsights/RefreshInsightsModal.css";
import WorkSpaceService from "../../../../services/workspaceService";
import "./FavoriteResponseDetail.css";

export default function FavoriteDeleteModal(props) {
    
    const workspaceService = new WorkSpaceService();
    /**
     * This method handles delete favorite prompt button click.
     */
    const handleDeleteBtnClick = async(prompt) => { 
        await workspaceService.deleteWorkspaceFavoritePrompts(prompt.id,prompt.type);     
        props.setShowDeleteModal(false); 
        props.setShowDeletePrompt(true);     
    }

    /**
     * This method handles cancel button click.
     */
    const handleCancelBtnClick = () => {
        props.setShowDeleteModal(false);        
    }
    
    return (
        <MotifModal
            id="motifModalRefreshInsights"
            size="md" 
            show={true}
             className="genericModal modalNoHeaderBorder modalMotifFooterModal"
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
            onClose={() => {
                props.setShowDeleteModal(false);
            }}
        >
            <MotifModalHeader
                closeButtonProps={{
                    "aria-label": "Custom Close Button aria-label",
                    title: "Close popup",
                }}
                className='closeModalHeader'
            >
                <div className='closeSymbolContainer'>
                <img className='warningCircle' alt='icon' src={warningCircle} /></div>
            </MotifModalHeader>
            <MotifModalBody className="modal-description">
            <React.Fragment key=".0">
                        <div className='closeProjText'>Remove from Favorites </div>
                        <div id="closeProjectLines">
                            <span >
                            Confirm you would like to remove from Favorites. This action cannot<br /> be undone.
                            </span>
                        </div>
                    </React.Fragment>
            </MotifModalBody>
            <MotifModalFooter className='modalFooter'>
                    <React.Fragment key=".0">
                        <MotifButton
                            label="btnConfirm"
                            onClick={() => { handleDeleteBtnClick(props.favoriteResponse) }}
                            size="medium"
                            type="button"
                            className='btnConfirm btnConfirmFav'
                        >
                            Confirm
                        </MotifButton>
                        <MotifButton
                            label="btnCancel"
                            onClick={() => {  handleCancelBtnClick()  }}
                            size="medium"
                            type="button"
                            variant="secondary"
                            className='btnCancel btnCancelFav'
                        >
                            Cancel
                        </MotifButton>
                    </React.Fragment>
                </MotifModalFooter>
        </MotifModal>
    )
}