import { Component } from "react";
import axios from "axios";
import CommonService from "./commonService.js";
const requestData = require("../data/model/request");
let CreateDataList = requestData.CreateData;

export class ApiManager extends Component {
  commonService = new CommonService();
  constructor() {
    super();
    this.apiUrl = this.commonService.getAPIUrl();
    this.email = sessionStorage.getItem('Email');
    this.fullName = sessionStorage.getItem('CurrentUserName');
    this.firstName = sessionStorage.getItem('userFirstName');
    this.groupName= localStorage.getItem('groupNamePlatform') ? localStorage.getItem('groupNamePlatform') : localStorage.getItem('groupName');
  }

  static getInstance() {
    return new ApiManager();
  }
  // Add member to the project
  AddMembers(memberRequest) {

    axios.post(this.apiUrl + "Member/AddMembers", memberRequest)
      .then(Response => {
        //
      })
      .catch(error => {
        console.error('Error', error);
      })
  }
  // Create new project
  async CreateNewProject(DetailData = CreateDataList) {
    try {
      const createNewProjectrequest = {
        adminId: this.email,
        fullName: this.fullName,
        firstName:this.firstName,
        groupName:this.groupName,
        category: DetailData.category,
        paceId: DetailData.paceId,
        engagementId: DetailData.engagementId,
        confidential: DetailData.confidential,
        projectName: DetailData.projectName,
        clientName: DetailData.clientName,
        area: DetailData.area,
        region: DetailData.region,
        country: DetailData.country,
        dealSize: DetailData.dealSize,
        targetSector: DetailData.targetSector,
        target_SubSector: DetailData.targetSubSector,
        target_Sub_SubSector: DetailData.targetSubSubSector,
        dataHostingLocation: DetailData.dataHostingLocation,
        description: DetailData.description,
        editFlag: DetailData.editFlag,
        project_uuid: DetailData.projectuuid,
        projectScope: DetailData.projectScope
      };

      const response = await axios.post(
        this.apiUrl + "Project/CreateProject",        
        createNewProjectrequest
      );

      return (DetailData.editFlag == "Y" ? response : response.data);
    }
    catch (error) {
      this.commonService.logException(
        error,
        "Create Project Component",
        "Insert Or Update Data"
      );
    }
  }

  // Get Pace Id details
  async GetPaceId(paceId) {
    try {
      return await axios
        .get(
          this.apiUrl + "Project/GetPaceId?paceId=" + paceId + "",
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Create Project Component",
        "Get Pace Id"
      );
    }
  };
}
export default ApiManager