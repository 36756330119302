import { React, useContext, useEffect, useState } from "react";
import { MotifButton, MotifTooltip } from "@ey-xd/motif-react";
import _ from "lodash";
import MotifToast from "@ey-xd/motif-react/Toast";
import eyeImage from "../../../../../assets/image/eye.svg";
import "./PromptAction.css"
import check_circle from "../../../../../assets/image/check-circle.svg";
import PlatformService from "../../../../../services/platformService";
import ConfirmPublishPromptModal from "./ConfirmPublishPromptModal";

const PromptAction = (props) => {
  const platformService = new PlatformService();
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);

  const handleCancelPublish = () => {
    const status = "false"
    props.closePopup(status);
  };
  const handleConfirmPublish = async () => {
    const SPLId = props.checkedPrompts.map((item) => item.platformPromptId).join(",");
    const requestPayload = {
      SPLId: SPLId,
    };
    let response = await platformService.ConfirmPublish(requestPayload)
    if (response == 200) {
      props.getSystemPrompts();
      setShowToast(true);
      setToastMessage("Prompt(s) published successfully");
    }
    const status = "false"
    props.closePopup(status)
  };
  useEffect(() => {
    setShowToast(false);
  }, [props.hideToast])

  return (
    <>
      <div className="ii-prompt-action-section">
        <MotifButton
          label="btnPublishIIPrompt"
          className="li-move-prompt"
          disabled={props.checkedPrompts.length === 0}
          onClick={props.PublishButtonClick}
        >
          <img src={eyeImage} alt="Publish Prompt"></img>
          Publish
        </MotifButton>
      </div>

      {showToast && (
        <MotifToast
          icons={{ toast: <img src={check_circle} alt=""></img> }}

          variant="success"
          className={"publish-motif-toast"}
          position="bottom"
          onClose={() => setShowToast(false)}
        >
          <div className="toast-body">{toastMessage}</div>
        </MotifToast>
      )}
      {
        props.showConfirmPublishTestPrompt === true && (
          <ConfirmPublishPromptModal
            close={handleCancelPublish}
            checkedPrompts={props?.checkedPrompts}
            handleConfirmPublish={handleConfirmPublish}
            tab={props.tab}
          />
        )
      }
    </>
  );
};

export default PromptAction;
