/**
 * Component to display document table
 * Modified On: 19-12-2024
 */
//  Generic imports
import React, { useEffect, useState } from "react";
import { getDocument } from "pdfjs-dist";
import PropTypes from 'prop-types';
import _ from "lodash";

//  Motif components imports
import {
  MotifErrorMessage,
  MotifFormField,
  MotifToast,
  MotifButton,
  MotifInput,
  MotifLabel,
  MotifTooltip,
} from "@ey-xd/motif-react";
import MotifTable from "@ey-xd/motif-react/Table";

//  Internal imports
import "./DocumentTable.css";
import Utils from "../../../utils/Utils";
import GraphService from "../../../services/graphService";
import {
  allDocumentsRemovedMsg,
  documentRemovedMsg,
  documentsReplacedMsg,
} from "../../../data/constant";

//  Icons imports
import iconDoc from "../../../assets/image/page.svg";
import iconKey from "../../../assets/image/key.svg";
import iconEyeOpened from "../../../assets/image/eye_opned.svg";
import iconEyeClosed from "../../../assets/image/eye_closed.svg";
import infoIcon from "../../../assets/image/info-empty.svg";
import loadingCircle from "../../../assets/image/Loading_indicator_circlesTable.svg";

const DocumentTable = (props) => {
  const utils = new Utils();
  const [documents, setDocuments] = useState([]);
  const [documentGrid, setDocumentGrid] = useState(null);
  const [isDocumentRemoved, setIsDocumentRemoved] = useState(false);
  const [isAllDocumentRemoved, setIsAllDocumentRemoved] = useState(false);
  const [isDocumentReplaced, setIsDocumentReplaced] = useState(false);

  useEffect(() => {
    setDocuments(props.documents);
    if (documentGrid) {
      documentGrid.api.setRowData(props.documents);
    }
    //  eslint-disable-next-line
  }, [props.documents]);

  /**
   * Use effect to remove all the special character documents
   */
  useEffect(() => {
    if (
      props.removeSpecialCharsDocuments ||
      props.removeUnsupportedDocuments ||
      props.removeDuplicateDocuments
    ) {
      setDocuments([]);
      setIsAllDocumentRemoved(true);
      setTimeout(() => {
        setIsAllDocumentRemoved(false);
        props.onAllDocumentsRemoved();
      }, 3000);
    }
    if (props.removeCopyReplaceDuplicateDocuments) {
      if (props.removeCopyReplaceDuplicateDocuments === "all") {
        setIsDocumentReplaced(true);
        setTimeout(() => {
          setIsDocumentReplaced(false);
        }, 3000);
      } else {
        setIsDocumentReplaced(true);
      }
    }
  }, [
    props.removeSpecialCharsDocuments,
    props.removeUnsupportedDocuments,
    props.removeDuplicateDocuments,
    props.removeCopyReplaceDuplicateDocuments,
  ]);

  useEffect(() => {
    if (documentGrid) {
      documentGrid.columnApi.setColumnsVisible(["0"], props.allowSelection);
    }
  }, [props.allowSelection]);
  /**
   * Grid options
   */
  const gridOptions = {
    ...((props.documentType === "specialCharsDocuments" ||
      props.documentType === "unSupportedDocuments" ||
      props.documentType === "duplicateDocuments") && {
      noRowsOverlayComponent: "customNoRowsOverlay",
    }),
  };

  /**
   * Description
   * -----------
   * defined columns to show the selected local documents
   */
  const [colDefs] = useState([
    {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 80,
      hide: true && props.documentType !== "duplicateDocuments",
    },
    {
      field: "name",
      width: 500,
      wrapText: props.documentType !== "passprotecteddocuments",
      autoHeight: true,
      displayName: "Documents",
      cellRenderer: (params) =>
        props.documentType === "passprotecteddocuments" ? (
          <div className="document-uploader-doc-name-wrapper-password">
            <span className="iconDoc">
              <img src={iconDoc} alt={params.data.file.name} />
            </span>
            <MotifTooltip
              placement="bottom"
              trigger={
                <span className="doc-name-label">{params.data.file.name}</span>
              }
            >
              {params.data.file.name}
            </MotifTooltip>
          </div>
        ) : (
          <div className="document-uploader-doc-name-wrapper">
            <div className="document-uploader-doc-icon">
              <img src={iconDoc} alt={params.data.file.name} />
            </div>
            <div>
              {
                <>
                  {!params.data.password && (
                    <MotifLabel className="document-file-name">
                      {params.data.file.name}
                    </MotifLabel>
                  )}
                  {params.data.password && (
                    <div className="password-file-container">
                      <MotifLabel className="document-file-name">
                        {params.data.file.name}
                      </MotifLabel>
                      <span className="password-file-message">
                        Password entered
                      </span>
                    </div>
                  )}
                </>
              }
            </div>
          </div>
        ),
      flex: 1,
    },
    {
      field: "size",
      displayName: "Size",
      cellRenderer: (params) => (
        <div className="document-file-size-label">
          {utils.getBytesInMB(params.data.file.size)}MB
        </div>
      ),
      hide: props.documentType === "passprotecteddocuments",
    },
    {
      field: "password",
      headerName: "Password",
      width: 600,
      cellRenderer: (params) => {
        return (
          <PasswordInputSubmitRenderer
            value={params.data}
            onSubmitValidPassword={onValidPasswordSubmit}
            uploadType={props.uploadType}
            uploadDetails={props.uploadDetails}
            passwordKeySecret={props.passwordKeySecret}
          ></PasswordInputSubmitRenderer>
        );
      },
      hide: props.documentType !== "passprotecteddocuments",
    },
  ]);

  /**
   * Custom components for Dropdown and Avatar in motif table
   */
  const frameworkComponents = {
    passwordInputSubmitRenderer: PasswordInputSubmitRenderer,
    customNoRowsOverlay: CustomNoRowsOverlay,
  };

  /**
   * Method calls on grid row selection
   * @param {object} event - ag-grid selection object
   */
  const onDocumentSelection = (event) => {
    let selectedFiles = event.api.getSelectedRows();
    props.onDocumentsSelection(selectedFiles);
  };

  /**
   * This mehtod calls from the password child component on password submit
   * @param {object} file -
   */
  const onValidPasswordSubmit = (file) => {
    props.onPasswordSubmit(file);
  };

  /**
   * Method calls when the motif table/ag-grid ready after renders
   * @param {object} events - ag-grid events
   */
  const onGridReady = (events) => {
    setDocumentGrid(events);
  };

  return (
    <div className="document-table-container">
      <MotifTable
        rowData={documents}
        columnDefs={colDefs}
        frameworkComponents={frameworkComponents}
        gridOptions={gridOptions}
        suppressCellFocus
        suppressRowClickSelection
        debounceVerticalScrollbar={true}
        rowSelection="multiple"
        onGridReady={(event) => onGridReady(event)}
        onRowSelected={(event) => onDocumentSelection(event)}
        getRowHeight={(params) => {
          if (props.documentType === "passprotecteddocuments") {
            return 80;
          }
          return 50;
        }}
      />

      {isDocumentRemoved && (
        <MotifToast
          variant="info"
          actionName=""
          position="bottom"
          onClose={() => {
            setIsDocumentRemoved(false);
          }}
        >
          <div>{documentRemovedMsg}</div>
        </MotifToast>
      )}
      {props.isDocumentDelete && (
        <MotifToast
          variant="info"
          actionName=""
          position="bottom"
          className="all-documents-removed-uploader"
          icons={{ toast: <img src={infoIcon} alt="" /> }}
          onClose={() => {
            props.onDocumentDelete(false);
          }}
        >
          <div className="document-toast-message">
            <span className="document-toast-message-header">
              Documents removed
            </span>
            <span className="document-toast-message-content">
              Selected documents have been removed successfully
            </span>
          </div>
        </MotifToast>
      )}

      {isAllDocumentRemoved && (
        <MotifToast
          variant="info"
          actionName=""
          position="bottom"
          className="all-documents-removed-uploader"
          icons={{ toast: <img src={infoIcon} alt="" /> }}
          closeButtonProps={{ style: { display: "none" } }}
        >
          <div className="document-toast-message">
            <span className="document-toast-message-header">
              Documents removed
            </span>
            <span className="document-toast-message-content">
              {allDocumentsRemovedMsg}
            </span>
          </div>
        </MotifToast>
      )}
      {isDocumentReplaced && (
        <MotifToast
          variant="info"
          actionName=""
          position="bottom"
          className="all-documents-removed-uploader"
          icons={{ toast: <img src={infoIcon} alt="" /> }}
          onClose={() => {
            setIsDocumentReplaced(false);
            props.closeReplaceMessage();
          }}
          closeButtonProps={{
            style: {
              display: `${
                props.removeCopyReplaceDuplicateDocuments === "all" ? "none" : "block"
              }`,
            },
          }}
        >
          <div className="document-toast-message">
            <span className="document-toast-message-header">
              {props.copyReplaceDocumentType==="copy"?"Copy confirmed":"Replace confirmed"}
            </span>
            <span className="document-toast-message-content">
              {documentsReplacedMsg}
            </span>
          </div>
        </MotifToast>
      )}
    </div>
  );
};

DocumentTable.propTypes = {
  documents: PropTypes.array,
  documentType: PropTypes.string,
  onDocumentsSelection: PropTypes.func,
  onPasswordSubmit: PropTypes.func,
  uploadType: PropTypes.string,
  uploadDetails: PropTypes.object,
  passwordKeySecret: PropTypes.string,
  removeSpecialCharsDocuments: PropTypes.bool,
  onAllDocumentsRemoved: PropTypes.func,
  removeUnsupportedDocuments: PropTypes.bool,
  removeDuplicateDocuments: PropTypes.bool,
  isDocumentDelete: PropTypes.bool,
  onDocumentDelete: PropTypes.func,
  closeReplaceMessage: PropTypes.func,
  removeCopyReplaceDuplicateDocuments: PropTypes.string,
};

const PasswordInputSubmitRenderer = (props) => {
  const utils = new Utils();
  const graphService = new GraphService();
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);

  /**
   * Method to check the protected file password is correct or not
   */
  const onPasswordSubmit = async () => {
    let isValidPassword = false;
    if (props.uploadType === "Local") {
      isValidPassword = await checkFilePassword();
    } else {
      const fileURL =
        props?.uploadDetails?.siteId +
        "/drive/items/" +
        props.value.file.id +
        "/content";
      isValidPassword = await fetchFileAndCheckPassword(fileURL);
    }
    if (isValidPassword) {
      props.onSubmitValidPassword({
        ...props.value,
        password: utils.encryptText(password, props.passwordKeySecret),
      });
    } else {
      setIsPasswordIncorrect(true);
    }
  };

  /**
   * Method to check the protected file password is correct or not
   */
  const checkFilePassword = async () => {
    return new Promise((resolve, reject) => {
      const vReader = new FileReader();
      vReader.onload = async () => {
        return resolve(verifyPdfPassword(vReader.result));
      };
      vReader.onerror = () => reject(vReader.error);
      vReader.readAsArrayBuffer(props.value.file);
    });
  };

  /**
   * Method to fetch sharepoint file to verify the password
   * @param {string} url - file url
   * @returns
   */
  const fetchFileAndCheckPassword = async (url) => {
    const response = await graphService.fetchFile(url);
    const pdfBytes = await response.arrayBuffer();
    return verifyPdfPassword(pdfBytes);
  };

  /**
   * Method to get the file bytes to verify the password
   * @param {bytes} pdfBytes - file buffer
   * @returns
   */
  const verifyPdfPassword = async (pdfBytes) => {
    try {
      const loadingTask = getDocument({
        data: pdfBytes,
        password: password,
      });
      const pdfDoc = await loadingTask.promise;
      await pdfDoc.destroy();
      return true;
    } catch (error) {
      if (
        error.name === "PasswordException" &&
        error.message === "Incorrect Password"
      ) {
        return false;
      }
      throw error;
    }
  };

  const changePasswordView = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  return (
    <div className="doc-password-input-container">
      <img src={iconKey} className="doc-password-input-key-icon" alt="" />

      <MotifFormField className="password-input-wrapper">
        <MotifInput
          onChange={(e) => {
            setPassword(e.target.value);
            setIsPasswordIncorrect(false);
          }}
          type={passwordType}
          value={password}
          placeholder="Enter Password"
          className="doc-password-input"
        />

        {isPasswordIncorrect && (
          <MotifErrorMessage className="incorrect-pswd-message">Password is incorrect</MotifErrorMessage>
        )}
      </MotifFormField>
      {
          passwordType==="password" && (<img src={iconEyeClosed} className={`doc-password-input-eye-icon ${password !== ""?"doc-password-input-eye-icon-after":""}`} alt="" onClick={changePasswordView} />) 
        }
        {
          passwordType==="text" && (<img src={iconEyeOpened} className={`doc-password-input-eye-icon ${password !== ""?"doc-password-input-eye-icon-after":""}`} alt="" onClick={changePasswordView}/>) 
        }
      <MotifButton
        className="password-submit-button"
        size="large"
        type="password"
        onClick={() => {
          onPasswordSubmit();
        }}
        disabled={password === ""}
      >
        Submit
      </MotifButton>
    </div>
  );
};

PasswordInputSubmitRenderer.propTypes = {
  onSubmitValidPassword: PropTypes.func,
  value: PropTypes.object,
  uploadType: PropTypes.string,
  uploadDetails: PropTypes.object,
  passwordKeySecret: PropTypes.string,
};

const CustomNoRowsOverlay = () => {
  return (
    <div className="custom-no-rows-overlay">
      <img
        className="document-removed-rotate-icon"
        src={loadingCircle}
        alt="loading_icon"
      />
      <span className="documents-removed-message">Documents removed</span>
    </div>
  );
};
export default DocumentTable;
