import React from "react";

//  Motif imports
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
} from "@ey-xd/motif-react";

//  Internal imports
import Utils from "../../utils/Utils";

// css import
import "./NotificationBar.css";

// icon imports
import icon_warning from "../../assets/image/warning-circle.svg";
import editIcon from "../../assets/image/edit.svg";
import deleteCircle from "../../assets/image/delete-circle.svg";
import infoEmpty from "../../assets/image/info-empty.svg";
import ButtonWithAddon from "../common/ButtonWithAddOn";
import warning_triangle from "../../assets/image/warning-triangle_red.svg";
import pageFlip from "../../assets/image/page-flip.svg";
import { useSelector } from "react-redux";
import { tabIds } from "../../data/constant";

function NotificationBar({ notificationData, onEdit, onRemove }) {
  const util = new Utils();
  const {
    notificationTypeId,
    createTS,
    description,
    typeName,
    isActive,
    isDraft,
    isHistory,
  } = notificationData;

  const iconTypeMapping = {
    1: icon_warning, // Known Issues
    2: warning_triangle, // Planned Maintenance
    3: pageFlip, // News
    4: infoEmpty, // General
  };

  const activeTab = useSelector((state) => state.notification.activeTab);
  const buttonText = activeTab === tabIds.History ? "Delete" : "Remove";

  return (
    <div className="accordiandiv-notification">
      <MotifAccordion
        alignIconRight
        useChevronIcon
        className="accordion-notification"
      >
        <MotifAccordionTrigger className="accordian-trigger-notification">
          <div className="notification-trigger-content">
            {iconTypeMapping[notificationTypeId] && (
              <img
                className="notification-icon-image"
                src={iconTypeMapping[notificationTypeId]}
                alt={typeName}
              />
            )}

            <p className="notification-type-name">{typeName}</p>

            <span className="notification-created-date">
              {util.formatDate(createTS)}
            </span>
          </div>
        </MotifAccordionTrigger>

        <MotifAccordionContent className="notification-content">
          <p
            key="editor-content"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          <div className="notification-button-add-on">
            <ButtonWithAddon
              startAddon={<img src={editIcon} alt="Edit" />}
              variant="secondary"
              size="small"
              onClick={onEdit}
            >
              Edit
            </ButtonWithAddon>
            {!isDraft && (
              <ButtonWithAddon
                startAddon={<img src={deleteCircle} alt="Remove" />}
                variant="secondary"
                size="small"
                onClick={onRemove}
              >
                {buttonText}
              </ButtonWithAddon>
            )}
          </div>
        </MotifAccordionContent>
      </MotifAccordion>
    </div>
  );
}

export default NotificationBar;
