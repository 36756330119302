import React, { useEffect, useState } from "react";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import PromptAccordion from "./PromptAccordion";
import PlatformService from "../../../../../services/platformService";

import noProject from "../../../../../assets/image/no-project-image.png";
import NoPromptFound from "./NoPromptFound";
import Utils from "../../../../../utils/Utils";
import PromptAction from "./PromptAction";
import EditTestPrompts from "./EditTestPrompts";
import { MotifToast } from "@ey-xd/motif-react";
import checkCircle from "../../../../../assets/image/check-circle.svg";

const PromptDetail = (props) => {
  const platformService = new PlatformService();
  const utils = new Utils();
  const [node, setNode] = useState();
  const [isSubsectionSelected, setIsSubsectionSelected] = useState(false);
  const [selectedSystemCategory, setSelectedSystemCategory] = useState("");
  const [activePromptList, setActivePromptList] = useState([]);
  const [testPromptList, setTestPromptList] = useState([]);
  const tabIds = { activePrompt: "activePrompt", testPrompt: "testPrompt" };
  const [currentTab, setCurrentTab] = useState(tabIds.activePrompt[0]);
  const [showEditPromptPopover, setShowEditPromptPopover] = useState(false);
  const [showEditSuccessfullToast, setShowEditSuccessfullToast] = useState(false);
  const [currentTestPrompt, setCurrentTestPrompt] = useState(null);
  const [editedPromptsSelected, setEditedPromptsSelected] = useState(false);
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [showConfirmPublishTestPrompt, setShowConfirmPublishTestPrompt] = useState()
  const [hideToast, setHideToast] = useState(false)


  useEffect(() => {
    setNode(props.selectedNode);
  }, [props.selectedNode]);

  useEffect(() => {
    if (node?.api) {
      setIsSubsectionSelected(true)
      getActivePrompts()
      getTestPrompts()
      setSelectedSystemCategory(node.api);
    } else {
      setIsSubsectionSelected(false);
    }
  }, [node]);
  useEffect(() => {
    if (selectedSystemCategory && currentTab) {
      getSystemPrompts();
    }
  }, [selectedSystemCategory, currentTab]);

  const handleTabClick = async (tab) => {
    setCurrentTab(tab[0]);
    switch (tab) {
      case tabIds.activePrompt:
        document.getElementById("promptIIActivePromptDetail").style.display = "block";
        document.getElementById("promptIITestPromptDetail").style.display = "none";
        getActivePrompts()
        break;
      case tabIds.testPrompt:
        document.getElementById("promptIIActivePromptDetail").style.display = "none";
        document.getElementById("promptIITestPromptDetail").style.display = "block";
        break;
      default:
    }
  };
  const getActivePrompts = async () => {
    let apiData = await platformService.getSystemPrompts(
      node.api,
      "A",
      ""
    );
    setActivePromptList(apiData);
  }
  const getTestPrompts = async () => {
    let apiData = await platformService.getSystemPrompts(
      node.api,
      "T",
      ""
    );
    setTestPromptList(apiData);
  }

  const onSelectedActivePrompt = (id, checked) => {
    let activePrompts = activePromptList.map((prompt) =>
      prompt.platformPromptId === id
        ? { ...prompt, isChecked: checked }
        : prompt
    );
    setActivePromptList(activePrompts);
  };
  useEffect(() => {
    if (selectedSystemCategory && currentTab) {
      getSystemPrompts();
    }
  }, [selectedSystemCategory, currentTab]);
  const getSystemPrompts = async () => {
    const prompts = await platformService.getSystemPrompts(
      selectedSystemCategory,
      currentTab,
      "",
    );
    setIsSubsectionSelected(true);
    if (prompts) {
      let promptList = [];
      prompts.map((p) => {
        promptList.push({ ...p, isChecked: false });
      });
      if (currentTab === tabIds.activePrompt[0]) {
        setActivePromptList(promptList);
      } else {
        setTestPromptList(promptList);
      }
    }
  };
  const onPromptEditIconClick = (e, testPrompt) => {
    setShowEditPromptPopover(true);
    setCurrentTestPrompt(testPrompt);
    e.stopPropagation();
    setHideToast(true)
  }
  const onEditPopoverCloseClick = (e) => {
    setShowEditPromptPopover(false);
  }
  const updateEditStatus = (status) => {
    getSystemPrompts();
    setShowEditSuccessfullToast(status);
  }
  const onCloseEditSuccessfullToast = () => {
    setShowEditSuccessfullToast(false);
  }
  const onSelectedTestPrompt = (id, checked) => {
    let testPrompts = testPromptList.map((prompt) =>
      prompt.platformPromptId === id
        ? { ...prompt, isChecked: checked }
        : prompt
    );
    setTestPromptList(testPrompts);
    setEditedPromptsSelected(testPrompts.some(
      (prompt) => prompt.isChecked === true))
  };
  /**
   * This function is handle clicks on the accordion
   * @param {integer} accordionId
   */
  const handleAccordionClick = (accordionId) => {
    setOpenAccordionId((prevId) => {
      return prevId === accordionId ? null : accordionId;
    });
  };
  const PublishButtonClick = () => {
    setShowEditSuccessfullToast(false)
    setShowConfirmPublishTestPrompt(true)
    setHideToast(false)
  }
  const closePopup = (status) => {
    setShowConfirmPublishTestPrompt(status)
  }

  return (
    <div className="ii-prompt-response-detail">
      {(() => {
        if (isSubsectionSelected) {
          return (
            <div className="ii-detail-container">
              {props.tab === "PPSystemPrompts" && (
                <div className="ii-detail-subsection-header">{node?.api}</div>
              )}
              {props.tab === "PPCentralPrompts" && (
                <div className="ii-detail-subsection-header">{node?.api}</div>
              )}
              <MotifTabNavigation id="tabPromptResponse" defaultActiveKey={0} style={{marginBottom: '20px'}}>
                <MotifTabControl
                  onClick={() => handleTabClick(tabIds.activePrompt)}
                >
                  Active Prompts
                </MotifTabControl>
                <MotifTabControl
                  onClick={() => handleTabClick(tabIds.testPrompt)}
                >
                  Test Prompts
                </MotifTabControl>
              </MotifTabNavigation>
              <div id="promptIIActivePromptDetail" class="right-prompts-pane">
                <div className="ii-prompt-response-container powerpointPrompts">
                  {activePromptList.length == 0 && (
                    <NoPromptFound></NoPromptFound>
                  )}
                  <div className="ii-detail-prompt-accordion">
                    {activePromptList.length > 0 &&
                      activePromptList?.sort((a, b) => a.platformPromptTitle.localeCompare(b.platformPromptTitle))
                        .map((activePrompt, index) => {
                          return (
                            <PromptAccordion
                              key={selectedSystemCategory + currentTab + activePrompt?.platformPromptId + index.toString()}
                              prompt={activePrompt}
                              onCheckPrompt={onSelectedActivePrompt}
                              tab={props.tab}
                              type={tabIds.activePrompt}
                              isOpen={openAccordionId === activePrompt?.platformPromptId}
                              onAccordionClick={handleAccordionClick}
                            />
                          );
                        })}
                  </div>
                </div>
              </div>
              <div id="promptIITestPromptDetail" class="right-prompts-pane">
                <div className="ii-prompt-response-container powerpointPrompts">
                  {testPromptList.length === 0 && (
                    <NoPromptFound></NoPromptFound>
                  )}
                  {testPromptList.length > 0 && (
                    <PromptAction
                      tab={props.tab}
                      innerTab={"test"}
                      selectedScopeId={props.selectedScopeId}
                      checkedPrompts={testPromptList.filter(prompt => prompt.isChecked)}
                      node={node}
                      testPromptsList={testPromptList}
                      getSystemPrompts={getSystemPrompts}
                      PublishButtonClick={PublishButtonClick}
                      showConfirmPublishTestPrompt={showConfirmPublishTestPrompt}
                      closePopup={closePopup}
                      hideToast={hideToast}
                    />
                  )}
                  <div className="ii-detail-prompt-accordion">
                    {testPromptList.length > 0 &&
                      testPromptList.sort((a, b) => a.platformPromptTitle.localeCompare(b.platformPromptTitle))
                        .map((testPrompt, index) => {
                          return (
                            <PromptAccordion
                              key={selectedSystemCategory + currentTab + testPrompt?.platformPromptId + index.toString()}
                              prompt={testPrompt}
                              onCheckPrompt={onSelectedTestPrompt}
                              tab={props.tab}
                              type={tabIds.testPrompt}
                              onEditIconClick={(e) => onPromptEditIconClick(e, testPrompt)}
                              isOpen={openAccordionId === testPrompt?.platformPromptId}
                              onAccordionClick={handleAccordionClick}
                            />
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          );
        }
        else {
          return (
            <div className="ii-prompt-detail-no-selection powerpointPrompts">
              <img src={noProject} alt="no selection made" />
              <div className="ii-prompt-no-selection-div">
                <span className="ii-prompt-no-selection-text">
                  No selection made
                </span>
                <span className="ii-prompt-no-selection-desc">
                  Please make a selection from the left pane
                </span>
              </div>
            </div>
          );
        }
      })()}
      {
        showEditPromptPopover &&
        (
          <EditTestPrompts
            onEditModalCloseClick={onEditPopoverCloseClick}
            prompt={currentTestPrompt}
            updateEditStatus={updateEditStatus}
          />
        )
      }
      {
        showEditSuccessfullToast &&
        (
          <MotifToast
            icons={{ toast: <img src={checkCircle} alt="" /> }}
            variant="success"
            actionName=""
            position="bottom"
            className="prompt-edited-toast"
            onClose={() => {
              onCloseEditSuccessfullToast();
            }}
          >
            <div>{currentTestPrompt.platformPromptTitle} edited successfully</div>
          </MotifToast>
        )
      }
    </div>
  );
};

export default PromptDetail;