import React, { useContext, useEffect, useState } from "react";
import { MotifButton, MotifFooter } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import DocumentService from "../../../services/documentService";
import GraphService from "../../../services/graphService";
import ConfigurationService from "../../../services/configurationService";
import "./DocumentUploader.css";
import DocumentTable from "./DocumentTable";
import { ReportAuthorConfig } from "../../../config/groupConfig";
import { DashboardContext } from "../../../context/dashboardContext";
import iconProgressLoaderCircle from "../../../assets/image/Loading_indicator_circles.svg";
import { getDocument } from "pdfjs-dist";
import warningCircle from "../../../assets/image/warning-upload.svg";
import { documentLibraryTabStates } from "../../../data/constant";
import DocumentUploaderFooter from "./DocumentUploaderFooter";
import DocumentUploaderHeader from "./DocumentUploaderHeader";
import DiscardSelectionDocumentModal from "./DiscardSelectionDocumentModal";
import PropTypes from "prop-types";
import CopyOrReplaceDocumentModal from "./CopyOrReplaceDocumentModal";

const DocumentUploader = (props) => {
  const utils = new Utils();
  const documentservice = new DocumentService();
  const graphService = new GraphService();
  const configService = new ConfigurationService();
  const supportedDocumentTypeList= ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt"];

  const { setIsDocumentUploadProcessInitiated } = useContext(DashboardContext);

  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [allowRowSelection, setAllowRowSelection] = useState(false);
  const [activeTabStates, setActiveTabStates] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [showDocumentRemovedMessage, setShowDocumentRemovedMessage] =
    useState(false);
  const [tableHeight, setTableHeight] = useState(100);
  const [showLoader, setShowLoader] = useState(false);
  const [unprotectedFiles, setUnprotectedFiles] = useState([]);
  const [isProtectedFiles, setIsProtectedFiles] = useState(false);
  const [isUnProtectedFiles, setIsUnProtectedFiles] = useState(false);
  const [specialCharsDocuments, setSpecialCharsDocuments] = useState([]);
  const [unSupportedsDocuments, setUnSupportedsDocuments] = useState([]);
  const [duplicateDocuments, setDuplicateDocuments] = useState([]);
  const [protectedFiles, setProtectedFiles] = useState([]);
  const [showPassTab, setShowPassTab] = useState(false);
  const [passwordKeySecret, setPasswordKeySecret] = useState("");
  const [clearSpecialCharsDocuments, setClearSpecialCharsDocuments] =
    useState(false);
  const [clearUnsupportedDocuments, setClearUnsupportedDocuments] =
    useState(false);
  const [clearDuplicateDocuments, setClearDuplicateDocuments] = useState(false);
  const [isDocumentCountExceeds, setIsDocumentCountExceeds] = useState(false);
  const [isDocumentSizeExceeds, setIsDocumentSizeExceeds] = useState(false);
  const [isAllDocumentsValid, setIsAllDocumentsValid] = useState(false);
  const [showCopyReplaceDocumentModal, setShowCopyReplaceDocumentModal] =
    useState(false);
  const [showDuplicateDocMessage, setShowDuplicateDocMessage] = useState("");
  const [copyReplaceDocument, setCopyReplaceDocument] = useState("");
  const [isDocumentDelete, setIsDocumentDelete] = useState(false);
  const [
    showDiscardSelectionDocumentModal,
    setShowDiscardSelectionDocumentModal,
  ] = useState(false);
  const [motifActiveState, setMotifActiveState] = useState("");
  const [isUploadClicked, setIsUploadClicked] = useState(false);

  useEffect(() => {
    setActiveTabStates(documentLibraryTabStates.ShowDocuments);
    /**
     * get protected files
     */
    handleUploadedFiles(props.documentList);
    setDocuments(props.documentList);
    getSecretFromKeyVault();
    updatePageHeight();
    setShowLoader(true);
  }, [props.documentList]);

  useEffect(() => {
    if (documents.length > 0 && protectedFiles.length === 0) {
      handleDocumentTabClick(1);
    }
  }, [protectedFiles]);

  useEffect(() => {
   if (duplicateDocuments.length > 0) {
      handleDocumentTabClick(2);
    } else if (specialCharsDocuments.length > 0) {
      handleDocumentTabClick(3);
    } else if (unSupportedsDocuments.length > 0) {
      handleDocumentTabClick(4);
    } 
    else if (protectedFiles.length > 0) {
      handleDocumentTabClick(5);}
    else if (
      documents.length === 0 &&
      protectedFiles.length === 0 &&
      duplicateDocuments.length === 0 &&
      specialCharsDocuments.length === 0 &&
      unSupportedsDocuments.length === 0
    ) {
      handleDocumentTabClick(1);
    } else if (
      documents.length > 0 &&
      protectedFiles.length === 0 &&
      duplicateDocuments.length === 0 &&
      specialCharsDocuments.length === 0 &&
      unSupportedsDocuments.length === 0
    ) {
      setTimeout(() => {
        handleDocumentTabClick(1);
      }, [3000]);
    } else if (documents.length > 0) {
      handleDocumentTabClick(1);
    }
  }, [
    showLoader,
    protectedFiles,
    duplicateDocuments,
    specialCharsDocuments,
    unSupportedsDocuments,
  ]);

  useEffect(() => {
    if (duplicateDocuments.length === 0) {
      if (specialCharsDocuments.length > 0) {
        setTimeout(() => {
          handleDocumentTabClick(3);
        }, [3000]);
      }
    }
  }, [duplicateDocuments]);

  useEffect(() => {
    setIsAllDocumentsValid(
      protectedFiles.length === 0 &&
        duplicateDocuments.length === 0 &&
        specialCharsDocuments.length === 0 &&
        unSupportedsDocuments.length === 0
    );
  }, [
    protectedFiles,
    duplicateDocuments,
    specialCharsDocuments,
    unSupportedsDocuments,
  ]);

  /**
   * Show the ‘Loader’ message for 3 seconds, and then make it disappear
   */
  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 4000);
  }, []);

  /**
   * This method is to fetch the secret kay from the key vault.
   */
  const getSecretFromKeyVault = async () => {
    let vaultValue = await configService.getKeyVaultValue(
      ReportAuthorConfig.secretKey
    );
    if (vaultValue) {
      setPasswordKeySecret(vaultValue);
    }
  };

  /**
   * Identify selected file is protected or unprotected
   * @param {*} chosenFiles
   */

  const handleUploadedFiles = async (chosenFiles) => {
    //check duplicate documents
    let existingDocumentList = props.uploadedDocuments;
    const duplicateDocuments = _.remove(chosenFiles, (selectedDoc) =>
      _.some(
        existingDocumentList,
        (existingDoc) => existingDoc.documentName === selectedDoc.file.name
      )
    );
    setDuplicateDocuments(duplicateDocuments);

    //handle unsupported  documents
    const unSupportedsDocuments = _.remove(chosenFiles, function (doc) {
      let fileExtension = utils.getFileExtension(doc.file?.name);

      return !utils.isSupportedDocumentType(fileExtension);
    });
    setUnSupportedsDocuments(unSupportedsDocuments);

    //handle special character documents
    const specialCharsFiles = _.remove(chosenFiles, function (doc) {
      let fileExtension = doc.file?.name.lastIndexOf(
        utils.getFileExtension(doc.file?.name)
      );
      let docName = doc.file?.name.toString().substring(0, fileExtension - 1);

      return utils.isContainSpecialCharacters(docName);
    });
    setSpecialCharsDocuments(specialCharsFiles);

    /**
     * get protected files
     */
    let vProtectedFiles = [];
    if (props?.uploadType === "Local") {
      vProtectedFiles = await getProtectedFiles(chosenFiles);
    } else {
      vProtectedFiles = await getProtectedSharepointFiles(chosenFiles);
    }
    if (vProtectedFiles && vProtectedFiles.length > 0) {
      setProtectedFiles(vProtectedFiles);
      setIsProtectedFiles(true);
      setActiveTabStates(documentLibraryTabStates.PasswordProtectedDocuments);
    } else {
      setIsUnProtectedFiles(true);
    }
  };

  /**
   * Identify selected file is protected or unprotected
   * @param {*} vSelectedFiles
   * @returns the protected files
   */
  const getProtectedFiles = async (vSelectedFiles) => {
    let vProtectedFiles = [];
    let vUnprotectedFiles = [];
    if (vSelectedFiles) {
      for (const vFile of vSelectedFiles) {
        if (vFile.file.type === "application/pdf") {
          const isProtected = await isFileProtected(vFile.file);
          if (isProtected) {
            vProtectedFiles.push(vFile);
          } else {
            vUnprotectedFiles.push(vFile);
          }
        } else {
          vUnprotectedFiles.push(vFile);
        }
      }
    }
    setProtectedFiles(vProtectedFiles);
    setUnprotectedFiles(vUnprotectedFiles);
    setDocuments(vUnprotectedFiles);
    return vProtectedFiles;
  };

  const getProtectedSharepointFiles = async () => {
    const pdfFiles = props.documentList;
    let unProtectedPDFFiles = [];
    let protectedPDFFiles = [];
    await Promise.all(
      pdfFiles.map(async (doc) => {
        const fileType =
          doc.file.name.slice(doc.file.name.lastIndexOf(".") + 1) || "";
        if (fileType === "pdf") {
          const fileURL =
            props?.uploadDetails?.siteId +
            "/drive/items/" +
            doc.file.id +
            "/content";
          const passwordProtected = await fetchFileAndCheckPassword(fileURL);
          if (passwordProtected) {
            protectedPDFFiles.push(doc);
          } else {
            unProtectedPDFFiles.push(doc);
          }
        } else {
          unProtectedPDFFiles.push(doc);
        }
      })
    );
    setProtectedFiles(protectedPDFFiles);
    setDocuments(unProtectedPDFFiles);
    return protectedPDFFiles;
  };

  const fetchFileAndCheckPassword = async (url) => {
    const response = await graphService.fetchFile(url);
    const pdfBytes = await response.arrayBuffer();
    return isPdfPasswordProtected(pdfBytes);
  };

  /**
   * Check if file is protected or not
   * @param {*} vFile
   * @returns
   */
  const isFileProtected = (vFile) => {
    return new Promise((resolve, reject) => {
      const vReader = new FileReader();
      vReader.onload = async () => {
        return resolve(isPdfPasswordProtected(vReader.result));
      };
      vReader.onerror = () => reject(vReader.error);
      vReader.readAsArrayBuffer(vFile);
    });
  };

  const isPdfPasswordProtected = async (pdfBytes) => {
    try {
      await getDocument(pdfBytes).promise;
      return false;
    } catch (error) {
      if (error.name === "PasswordException") {
        return true;
      }
      throw error;
    }
  };

  /**
   * This method will provides the dynamic width to the document and refrence based on that parent div
   */
  const updatePageHeight = () => {
    setTableHeight(Math.round(window.innerHeight / 4));
  };

  const onSubmitValidDocuments = async () => {
    setIsUploadClicked(true);
    if (documents.length > 0) {
      if (props.uploadType === "Local") {
        const result = await documentservice.uploadLocalDocumentsV2(documents);
        if (result.status === 200) {
          setIsDocumentUploadProcessInitiated(true);
          props.localFileUploadSuccess(true);
        } else {
          props.localFileUploadSuccess(false);
        }
        props.onCloseUploadModal(false);
      } else {
        let uploadFiles = _.map(documents, function (doc) {
          return { ...doc.file, password: doc.password ?? "", flag: doc.flag };
        });
        let requestObject = {
          P_SharePointSite_ID: props?.uploadDetails?.siteId,
          P_UserAccessToken: props?.uploadDetails?.accessToken,
          P_Blob_Container_Name: sessionStorage.getItem("DashboardProjectId"),
          P_Sharepoint_FileList: uploadFiles,
          P_UploadUserEmail: sessionStorage.getItem("Email"),
          P_UploadUserName: sessionStorage.getItem("CurrentUserName"),
        };
        await documentservice.UploadDocumentToSharePoint(requestObject);
        setIsDocumentUploadProcessInitiated(true);
        props.sharePointFileUploadSuccess(true);
        props.onCloseUploadModal(false);
      }
    }
    setIsUploadClicked(false);
  };

  /**
   * This method to switch the tabs of the document types
   * @param {number} tabId: tab unique number
   */
  const handleDocumentTabClick = (tabId) => {
    setShowDuplicateDocMessage("");
    setMotifActiveState("");
    switch (tabId) {
      case 1:
        setActiveTabStates(documentLibraryTabStates.ShowDocuments);
        setMotifActiveState(documentLibraryTabStates.ShowDocuments);
        break;
      case 2:
        if (document.getElementById("tabDuplicateDocuments"))
          document.getElementById("tabDuplicateDocuments").style.display =
            "block";
        setActiveTabStates(documentLibraryTabStates.DuplicateDocuments);
        setMotifActiveState(documentLibraryTabStates.DuplicateDocuments);
        break;
      case 3:
        if (document.getElementById("tabSpecialCharsDocuments"))
          document.getElementById("tabSpecialCharsDocuments").style.display =
            "block";
        setActiveTabStates(documentLibraryTabStates.SpecialCharsDocuments);
        setMotifActiveState(documentLibraryTabStates.SpecialCharsDocuments);
        break;
      case 4:
        if (document.getElementById("tabUnsupportedDocuments"))
          document.getElementById("tabUnsupportedDocuments").style.display =
            "block";
        setActiveTabStates(documentLibraryTabStates.UnsupportedDocuments);
        setMotifActiveState(documentLibraryTabStates.UnsupportedDocuments);
        break;
      case 5:
        if (document.getElementById("tabPasswordProtectedDocuments"))
          document.getElementById(
            "tabPasswordProtectedDocuments"
          ).style.display = "block";
        setActiveTabStates(documentLibraryTabStates.PasswordProtectedDocuments);
        setMotifActiveState(
          documentLibraryTabStates.PasswordProtectedDocuments
        );
        break;
      default:
        document.getElementById("tabDocuments").style.display = "block";
        setActiveTabStates(documentLibraryTabStates.ShowDocuments);
        break;
    }
  };

  const allowDocumentTableSelection = () => {
    setActiveTabStates(documentLibraryTabStates.ModifyDocuments);
    setAllowRowSelection(true);
  };

  /**
   * Method to change the state from modify documents to documents
   */
  const removeDocumentSelection = () => {
    setActiveTabStates(documentLibraryTabStates.ShowDocuments);
    setAllowRowSelection(false);
  };

  /**
   * Callback method returns the selected documents from the document table component
   * @param {array} documents - document array
   */
  const onSelectDocuments = (documents) => {
    setShowDocumentRemovedMessage(false);
    setSelectedDocuments(documents);
  };

  /**
   * method to move protected file to valid documents tab
   * @param {object} file - file object
   */
  const moveProtectedFileWithPassword = (file) => {
    setDocuments((documents) => _.concat(documents, file));
    if (props?.uploadType === "Local") {
      setProtectedFiles((protectedFiles) =>
        _.filter(protectedFiles, (doc) => doc.file.name !== file.file.name)
      );
    } else {
      setProtectedFiles((protectedFiles) =>
        _.filter(protectedFiles, (doc) => doc.file.id !== file.file.id)
      );
    }
  };

  /**
   * Method to replace duplicate file with existing file
   */
  const replaceDocuments = () => {
    if (selectedDocuments.length > 0) {
      let replacedDocuments = _.map(selectedDocuments, function (doc) {
        return { ...doc, flag: "replace" };
      });
      setDocuments((documents) => _.concat(documents, replacedDocuments));
      let existingDuplicateDocs = _.filter(
        duplicateDocuments,
        function (duplicateDoc) {
          return !_.some(replacedDocuments, function (docs) {
            return docs.file.name === duplicateDoc.file.name;
          });
        }
      );
      setSelectedDocuments([]);
      setShowCopyReplaceDocumentModal(false);
      setTimeout(()=>{
        setDuplicateDocuments(existingDuplicateDocs);
      },[3000])
      if (existingDuplicateDocs.length > 0) {
        setShowDuplicateDocMessage("few");
      } else {
        setShowDuplicateDocMessage("all");
      }
    }
  };

  /**
   * Method to remove the selected documents
   */
  const removeSelectedDocuments = () => {
    if (selectedDocuments.length > 0) {
      if (activeTabStates === documentLibraryTabStates.ModifyDocuments) {
        let filterDocuments = documents;
        _.remove(filterDocuments, (item) =>
          _.some(selectedDocuments, function (doc) {
            return doc.file.name === item.file.name;
          })
        );
        setDocuments([...filterDocuments]);
        removeDocumentSelection();
        setSelectedDocuments([]);
      } else {
        let filterDocuments = duplicateDocuments;
        _.remove(filterDocuments, (item) =>
          _.some(selectedDocuments, function (doc) {
            return doc.file.name === item.file.name;
          })
        );
        setDuplicateDocuments([...filterDocuments]);
        setSelectedDocuments([]);
        setIsDocumentDelete(true);
      }
    }
  };

  /**
   * This method calls on closing toast message
   */
  const onCloseReplaceMessage = () => {
    setShowDuplicateDocMessage("");
  };

  /**
   * Method to remove all the documents from the unsupported and special characters tab
   */
  const removeAllDocuments = () => {
    if (activeTabStates === documentLibraryTabStates.SpecialCharsDocuments) {
      setSpecialCharsDocuments([]);
    }
    if (activeTabStates === documentLibraryTabStates.UnsupportedDocuments) {
      setUnSupportedsDocuments([]);
    }
    if (activeTabStates === documentLibraryTabStates.DuplicateDocuments) {
      setDuplicateDocuments([]);
    }
  };

  /**
   * Method to keep a copy of duplicate file with new file name
   */
  const keepACopyDocuments = () => {
    let existingDocumentList = props.uploadedDocuments;
    let existingDocumentsNameList = existingDocumentList.map(
      (eachDocument) => eachDocument.documentName
    );

    if (selectedDocuments.length > 0) {
      let updatedDocuments = _.map(selectedDocuments, function (doc) {
        const newName = utils.renameDocumentIfDuplicate(
          doc.file.name,
          existingDocumentsNameList
        );
        if (props?.uploadType === "Local") {
          const fileCopy = new File([doc.file], newName, {
            type: doc.file.type,
          });
          return { file: fileCopy, flag: "copy" };
        } else {
          return { file: { ...doc.file, name: newName }, flag: "copy" };
        }
      });
      setDocuments((documents) => [...documents, ...updatedDocuments]);

      let existingDuplicateDocs = _.filter(
        duplicateDocuments,
        function (duplicateDoc) {
          return !_.some(selectedDocuments, function (docs) {
            return docs.file.name === duplicateDoc.file.name;
          });
        }
      );
      setSelectedDocuments([]);
      setShowCopyReplaceDocumentModal(false);
      setTimeout(()=>{
        setDuplicateDocuments(existingDuplicateDocs);
      },[3000])
      if (existingDuplicateDocs.length > 0) {
        setShowDuplicateDocMessage("few");
      } else {
        setShowDuplicateDocMessage("all");
      }
    }
  };

  return (
    <>
      <MotifModal
        id="documentUploadModal"
        show={true}
        size="xl"
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          setShowDiscardSelectionDocumentModal(true);
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Close",
            title: "Close",
          }}
        >
          Uploading From{" "}
          {`${
            props?.uploadType === "Local"
              ? "Local Drive - Make selection"
              : `Sharepoint - ${props?.uploadDetails?.sitePath}`
          }`}
        </MotifModalHeader>
        <MotifModalBody>
          {showLoader ? (
            <div className="loaderDiv">
              <div>
                <img
                  src={iconProgressLoaderCircle}
                  alt="Progress"
                  className="refreshIcon "
                />
              </div>
              <div className="refreshListText">Loading</div>
            </div>
          ) : (
            <>
              <div className="divider-container">
                <div className="upload-doc-divider"></div>
              </div>
              <div className="modal-uploader-body">
                <div className="document-uploader-tabs">
                  <MotifTabNavigation
                    id="documentUploaderTabs"
                  >
                    <MotifTabControl
                      id="uploadDocuments"
                      onClick={() => handleDocumentTabClick(1)}
                      className={
                        motifActiveState ===
                        documentLibraryTabStates.ShowDocuments
                          ? "motif-active"
                          : ""
                      }
                      disabled={
                        duplicateDocuments.length > 0 ||
                        specialCharsDocuments.length > 0 ||
                        unSupportedsDocuments.length > 0
                      }
                    >
                      Documents
                    </MotifTabControl>
                    <MotifTabControl
                      id="uploadPasswordProtectedDocuments"
                      onClick={() => handleDocumentTabClick(5)}
                      disabled={showPassTab}
                      className={
                        motifActiveState ===
                        documentLibraryTabStates.PasswordProtectedDocuments
                          ? "motif-active"
                          : protectedFiles.length === 0
                          ? "hidden-doc-tab"
                          : ""
                      }
                    >
                      <span>Password protected</span>
                      <img alt="password protected icon" src={warningCircle} />
                    </MotifTabControl>
                    <MotifTabControl
                      id="uploadDuplicateDocuments"
                      onClick={() => handleDocumentTabClick(2)}
                      className={
                        motifActiveState ===
                        documentLibraryTabStates.DuplicateDocuments
                          ? "motif-active"
                          : duplicateDocuments.length === 0
                          ? "hidden-doc-tab"
                          : ""
                      }
                    >
                      <span>Duplicates</span>
                      <img alt="duplicate icon" src={warningCircle} />
                    </MotifTabControl>
                    <MotifTabControl
                      id="uploadSpecialCharsDocuments"
                      onClick={() => handleDocumentTabClick(3)}
                      className={
                        motifActiveState ===
                        documentLibraryTabStates.SpecialCharsDocuments
                          ? "motif-active"
                          : specialCharsDocuments.length === 0
                          ? "hidden-doc-tab"
                          : ""
                      }
                    >
                      <span>Special characters</span>
                      <img alt="special chars icon" src={warningCircle} />
                    </MotifTabControl>
                    <MotifTabControl
                      id="uploadUnsupportedDocuments"
                      onClick={() => handleDocumentTabClick(4)}
                      className={
                        motifActiveState ===
                        documentLibraryTabStates.UnsupportedDocuments
                          ? "motif-active"
                          : unSupportedsDocuments.length === 0
                          ? "hidden-doc-tab"
                          : ""
                      }
                    >
                      <span>Unsupported</span>
                      <img alt="unsupported icon" src={warningCircle} />
                    </MotifTabControl>
                  </MotifTabNavigation>
                </div>
                <div className="uploader-modal-body-wrapper">
                  <DocumentUploaderHeader
                    documents={documents}
                    activeTabStates={activeTabStates}
                    documentStates={props?.documentStates}
                    onFilterDocuments={(docs) => setFilteredDocuments(docs)}
                    onShowDocumentDetails={(state) => {
                      setIsDocumentSizeExceeds(state.documentSize);
                      setIsDocumentCountExceeds(state.documentCount);
                    }}
                  />
                  <div className="document-uploader-body">
                    {(activeTabStates ===
                      documentLibraryTabStates.ShowDocuments ||
                      activeTabStates ===
                        documentLibraryTabStates.ModifyDocuments) && (
                      <div id="tabDocuments" className="document-tabs">
                        <div
                          className="document-uploader-document-table"
                          style={{ height: `${tableHeight}px` }}
                        >
                          <DocumentTable
                            documentType={"documents"}
                            uploadType={props.uploadType}
                            documents={filteredDocuments}
                            allowSelection={allowRowSelection}
                            onDocumentsSelection={onSelectDocuments}
                            showMessage={showDocumentRemovedMessage}
                          />
                        </div>
                      </div>
                    )}

                    <div
                      id="tabPasswordProtectedDocuments"
                      className="document-tabs"
                    >
                      {activeTabStates ===
                        documentLibraryTabStates.PasswordProtectedDocuments && (
                        <div
                          className="document-uploader-document-table"
                          style={{ height: `${tableHeight}px` }}
                        >
                          <DocumentTable
                            documentType={"passprotecteddocuments"}
                            documents={protectedFiles}
                            passwordKeySecret={passwordKeySecret}
                            uploadType={props.uploadType}
                            uploadDetails={props.uploadDetails}
                            allowSelection={allowRowSelection}
                            onDocumentsSelection={onSelectDocuments}
                            showMessage={showDocumentRemovedMessage}
                            onPasswordSubmit={moveProtectedFileWithPassword}
                          />
                        </div>
                      )}
                    </div>
                    <div id="tabDuplicateDocuments" className="document-tabs">
                      {activeTabStates ===
                        documentLibraryTabStates.DuplicateDocuments && (
                        <div
                          className="document-uploader-document-table"
                          style={{ height: `${tableHeight}px` }}
                        >
                          <DocumentTable
                            documentType={"duplicateDocuments"}
                            uploadType={props.uploadType}
                            documents={duplicateDocuments}
                            allowSelection={true}
                            onDocumentsSelection={onSelectDocuments}
                            closeReplaceMessage={onCloseReplaceMessage}
                            copyReplaceDocumentType={copyReplaceDocument}
                            removeDuplicateDocuments={clearDuplicateDocuments}
                            removeCopyReplaceDuplicateDocuments={
                              showDuplicateDocMessage
                            }
                            onAllDocumentsRemoved={removeAllDocuments}
                            isDocumentDelete={isDocumentDelete}
                            onDocumentDelete={setIsDocumentDelete}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      id="tabSpecialCharsDocuments"
                      className="document-tabs hiddenTabContent"
                    >
                      {activeTabStates ===
                        documentLibraryTabStates.SpecialCharsDocuments && (
                        <div
                          className="document-uploader-document-table"
                          style={{ height: `${tableHeight}px` }}
                        >
                          <DocumentTable
                            documentType={"specialCharsDocuments"}
                            uploadType={props.uploadType}
                            documents={specialCharsDocuments}
                            allowSelection={false}
                            removeSpecialCharsDocuments={
                              clearSpecialCharsDocuments
                            }
                            onAllDocumentsRemoved={removeAllDocuments}
                            showMessage={showDocumentRemovedMessage}
                          />
                        </div>
                      )}
                    </div>

                    <div
                      id="tabUnsupportedDocuments"
                      className="document-tabs hiddenTabContent"
                    >
                      {activeTabStates ===
                        documentLibraryTabStates.UnsupportedDocuments && (
                        <div
                          className="document-uploader-document-table"
                          style={{ height: `${tableHeight}px` }}
                        >
                          <DocumentTable
                            documentType={"unSupportedDocuments"}
                            uploadType={props.uploadType}
                            documents={unSupportedsDocuments}
                            allowSelection={false}
                            removeUnsupportedDocuments={
                              clearUnsupportedDocuments
                            }
                            onAllDocumentsRemoved={removeAllDocuments}
                            showMessage={showDocumentRemovedMessage}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </MotifModalBody>
        <MotifFooter>
          {
            !showLoader && (
              <DocumentUploaderFooter
              activeTabStates={activeTabStates}
              documents={documents}
              duplicateDocuments={duplicateDocuments}
              selectedDocuments={selectedDocuments}
              isAllDocumentsValid={isAllDocumentsValid}
              isDocumentSizeExceeds={isDocumentSizeExceeds}
              isDocumentCountExceeds={isDocumentCountExceeds}
              isUploadClicked = {isUploadClicked}
              onSubmitValidDocuments={onSubmitValidDocuments}
              allowDocumentTableSelection={allowDocumentTableSelection}
              removeSelectedDocuments={removeSelectedDocuments}
              removeDocumentSelection={removeDocumentSelection}
              setClearSpecialCharsDocuments={setClearSpecialCharsDocuments}
              setClearUnsupportedDocuments={setClearUnsupportedDocuments}
              onCloseUploadModal={props.onCloseUploadModal}
              setClearDuplicateDocuments={setClearDuplicateDocuments}
              onReplaceDocumentClick={() => {
                setCopyReplaceDocument("replace");
                setShowCopyReplaceDocumentModal(true);
              }}
              onKeepACopyDocumentClick={() => {
                setCopyReplaceDocument("copy");
                setShowCopyReplaceDocumentModal(true);
              }}
              onCancelDocumentClick={() => {
                setShowDiscardSelectionDocumentModal(true);
              }}
            />
            )
          }
          {
            showLoader && (
              <div className="document-upload-primary-buttons">
              <MotifButton
                label="btnModify"
                size="medium"
                type="button"
                variant="secondary"
                onClick={() => {
                  setShowDiscardSelectionDocumentModal(false);
                  props.onCloseUploadModal(false);
                }}
              >
                Cancel
              </MotifButton>
              </div>
            )
          }
        </MotifFooter>
      </MotifModal>

      {showCopyReplaceDocumentModal && (
        <CopyOrReplaceDocumentModal
          copyReplaceDocumentType={copyReplaceDocument}
          selectedDocuments={selectedDocuments}
          closeCopyReplaceModal={() => {
            setShowCopyReplaceDocumentModal(false);
          }}
          uploadedDocuments={props.uploadedDocuments}
          onReplaceConfirm={replaceDocuments}
          onKeepCopyConfirm={keepACopyDocuments}
        />
      )}

      {showDiscardSelectionDocumentModal && (
        <DiscardSelectionDocumentModal
          closeDiscardSelectionModal={() => {
            setShowDiscardSelectionDocumentModal(false);
          }}
          confirmDiscardSelectionModal={() => {
            setShowDiscardSelectionDocumentModal(false);
            props.onCloseUploadModal(false);
          }}
        />
      )}
    </>
  );
};
export default DocumentUploader;

DocumentUploader.propTypes = {
  uploadType: PropTypes.string,
  documentList: PropTypes.array,
  documentStates: PropTypes.object,
  uploadedDocuments: PropTypes.array,
  onCloseUploadModal: PropTypes.func,
  localFileUploadSuccess: PropTypes.func,
  sharePointFileUploadSuccess: PropTypes.func,
  uploadDetails: PropTypes.object,
};
