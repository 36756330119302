import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MotifButton, MotifToast } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import _ from "lodash";
import icon_warning from "../../../assets/image/warning-triangle_red.svg";
import Utils from "../../../utils/Utils";

const CopyOrReplaceDocumentModal = (props) => {
  const utils = new Utils();
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    setDocuments(props.selectedDocuments);
  }, props.selectedDocuments);

  /**
   * This method will be closed the modal
   */
  const btnModalCloseClick = () => {
    props.closeCopyReplaceModal();
  };

  const onConfirmButtonClick = () => {
    if(props.copyReplaceDocumentType ==="copy"){
      props.onKeepCopyConfirm();
    } else {
      props.onReplaceConfirm();
    }

  };

   /**
   * Renaming the document name
   */
  const formatDocumentName =(fileName)=>{
      let existingDocumentList = props.uploadedDocuments;
      let existingDocumentsNameList = existingDocumentList.map(eachDocument => eachDocument.documentName)
      let newName = fileName

      if (documents.length > 0) {
          newName = utils.renameDocumentIfDuplicate(fileName, existingDocumentsNameList)
      }

      return newName
  }


  return (
    <div>
      <MotifModal
        id="motifModalConfirmMovePrompt"
        className="genericModal"
        size="md"
        show={true}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={btnModalCloseClick}
      >
        <div>
          <MotifModalHeader>
            <div className="warning-image-div">
              <img
                className="icon-refresh"
                src={icon_warning}
                alt="warniing_image"
              ></img>
            </div>
          </MotifModalHeader>
          <div className="confirm-move-prompt-modal-header">
            {props.copyReplaceDocumentType === "copy" ? "Keep a copy of documents" : "Replace documents"}
          </div>
        </div>
        <MotifModalBody>
          <div className="document-replace-copy-description">
            {props.copyReplaceDocumentType === "copy" ? "A copy of the document will be added to the Document Library with the title shown below." : " The replace option removes the current document. The responses impacted by this will be highlighted within Explore your data and Instant Insights with a broken link."}
            <br />
            {documents?.map((document) => (
              <div
                className="document-name-replace-modal"
                key={document?.file?.lastModified}
              >
               {props.copyReplaceDocumentType==="copy"? formatDocumentName(document?.file?.name) : document?.file?.name} 
              </div>
            ))}
          </div>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="share-alert-btns-div">
            <div>
              <MotifButton
                variant="primary-alt"
                onClick={() => {
                  btnModalCloseClick();
                }}
                className="share-alert-cancel-button"
              >
                Cancel
              </MotifButton>
            </div>
            <div>
              <MotifButton
                className="share-alert-go-to-tw-button"
                onClick={onConfirmButtonClick}
              >
                Confirm
              </MotifButton>
            </div>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
};

CopyOrReplaceDocumentModal.propTypes = {
  documents: PropTypes.array,
  copyReplaceDocumentType:PropTypes.string,
  selectedDocuments:PropTypes.array,
  closeCopyReplaceModal:PropTypes.func,
  uploadedDocuments:PropTypes.array,
  onReplaceConfirm:PropTypes.func,
  onKeepCopyConfirm:PropTypes.func,
  
};

export default CopyOrReplaceDocumentModal;


