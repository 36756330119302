import { useEffect, useState, useContext, useRef } from "react";
import explore from "../../../assets/image/exploredesign.svg";
import senddiagonal from "../../../assets/image/senddiagonal.svg";
import expandprompt from "../../../assets/image/expand.svg";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { setUserRole } from "../../../reducers/activeUserRoleSlice";
import _ from "lodash";
import {
  MotifFormField,
  MotifButton,
  MotifTooltip,
  MotifSelect,
  MotifOption,
  MotifLabel,
} from "@ey-xd/motif-react";
import { MotifIconButton } from '@ey-xd/motif-react/Button';
import "../ExploreData/ExploreData.css";
import "../ProjectDashboard.css";
import ExloreRightPanal from "./ExploreDataRightPanal";
import ExplorePhase from "./ExplorePhase";
import { DashboardContext } from "../../../context/dashboardContext";
import { useLocation, useOutletContext } from "react-router-dom";
import { dashboardDataConstants } from "../../dashboard/constants";
import MemberService from '../../../services/memberService';
import ExploreService from "../../../services/exploreService.js";
import React, { useCallback } from 'react';
import BreadCrumb from "../../common/BreadCrumb";
import { MotifRichTextEditor } from '@ey-xd/motif-react'
import ExpandPromptModal from "./ExpandPromptModal";
import Utils from "../../../utils/Utils";

const ExploretData = () => {
  const utils = new Utils();
  const location = useLocation();
  const dispatch = useDispatch();
  const [askQuestion, setAskQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [askQuestionDisable, setaskQuestionDisable] = useState(true);
  const [confPublic, SetConfPublic] = useState("C");
  const [publicClick, setPublicClick] = useState(false);
  const [confClick, setconfClick] = useState(true);
  const [isSubmitpromt, setIsSubmitPrompt] = useState(false);
  const [showPromptHistory, setShowPromptHistory] = useState(false);
  const [isSrcDocActive, setIsSrcDocActive] = useState(false);
  const projectName = sessionStorage.getItem("DashboardProjectName");
  const {
    viewPrompt,
    isNewPromptCoversation,
    setIsNewPromptCoversation,
    hidePromptSearchInput,
    viewInstantPrompt
  } = useContext(DashboardContext);
  const [selectedSourceDocs, setSelectedSourceDocs] = useState("");
  const outletContext = useOutletContext();
  const memberService = new MemberService();
  const exploreService = new ExploreService();
  const email = sessionStorage.getItem("Email");
  let { setIsPromptSubmitted } = useContext(DashboardContext);
  const [showInstantModal, setShowInstantModal] = useState(false);
  const [selectedDocDeleted, setSelectedDocDeleted] = useState(false);
  const [eydScopeId, setEydScopeId] = useState();
  const [questionBar, setQuestionBar] = useState("");
  const [scopeList, setScopeList] = useState([]);
  const [expandPromptModal, setExpandPromptModal] = useState(false);
  const promptRef = useRef(null);
  const constantData = require("../../../data/constant.js");
  const [sendToolMsg1, setSendToolMsg1] = useState("");
  const [sendToolMsg2, setSendToolMsg2] = useState("");
  const [isSendBtnDisable, setIsSendBtnDisable] = useState(false);
  const [updatePersonalPrompt, setUpdatePersonalPrompt] = useState(false);

  useEffect(() => {
    setAskQuestion(questionBar);
  }, [questionBar]);

  useEffect(() => {
    setQuestionBar(askQuestion);
  }, [askQuestion]);

  useEffect(() => {
    sendButtonValidations();
  }, [eydScopeId, askQuestionDisable, askQuestion])

  const handlePromptSubmit = (event) => {
    // On Enter press (with no shift key) submit the prompt
    if (event.code === 'Enter' && !event.shiftKey) {
      if (confClick) {
        if (selectedSourceDocs != '' && eydScopeId != 0) {
          onSendPrompt();
          setAskQuestion("")
        }
        else {
          setAskQuestion(askQuestion.substring(0, askQuestion.length - 1));
          event.preventDefault();
        }
      }
      else if (publicClick) {
        if (eydScopeId != 0) {
          onSendPrompt();
          setAskQuestion("")
        }
      }
    }
  };

  useEffect(() => {
    const promptEditor = promptRef.current;
    const numberedBtn = promptEditor.querySelector(`button[value="ordered"]`);
    const bulletBtn = promptEditor.querySelector(`button[value="bullet"]`);
    if (selectedSourceDocs.length === 0) {
      numberedBtn.disabled = true;
      bulletBtn.disabled = true;
    } else {
      numberedBtn.disabled = false;
      bulletBtn.disabled = false;
    }
    if (publicClick) {
      numberedBtn.disabled = false;
      bulletBtn.disabled = false;
    }
    utils.addCustomTooltip(numberedBtn, 'Numbered list', 'NumberedList', 'top');
    utils.addCustomTooltip(bulletBtn, 'Bulleted list', 'BulletedList', 'top');

    return () => {
      let tooltipNumberedList = document.getElementById('tooltipNumberedList');
      if (tooltipNumberedList) {
        document.body.removeChild(tooltipNumberedList);
      }
      let tooltipBulletedList = document.getElementById('tooltipBulletedList');
      if (tooltipBulletedList) {
        document.body.removeChild(tooltipBulletedList);
      }
    };
  }, [selectedSourceDocs, publicClick]);


  const handlePromptChange = (prompt) => {
    setQuestionBar(prompt);
    setAskQuestion(prompt);
  }

  const motifReactEditorModules = {
    toolbar: [[{
      list: 'ordered'
    }, {
      list: 'bullet'
    },
    ]],
    clipboard: {
      matchVisual: false
    }
  };

  const viewExpandPrompt = () => {
    setExpandPromptModal(true);
  };


  useEffect(() => {
    return () => {
      if (location.state?.promptText !== undefined) {
        location.state.promptText = "";
      }
    };
  }, []);

  useEffect(() => {
    if (
      location.state?.promptText !== "" &&
      location.state?.promptText !== undefined
    ) {
      setAskQuestion(location.state?.promptText);
      document.getElementById("question")?.focus();
      outletContext.highlightExploreDataNavigationOption();
    }
    if (isSubmitpromt === true) {
      setAskQuestion("");
    }
    setSelectedDocDeleted(false);
  }, [questions]);

  useEffect(() => {
    if (viewInstantPrompt && viewInstantPrompt?.instantInsightFavoritePromptId) {
      setShowInstantModal(true);
    }
  }, [viewInstantPrompt]);

  useEffect(() => {
    if (viewPrompt && viewPrompt.length > 0) {
      let quests = [];
      quests.push(viewPrompt[0].requestContent);
      setQuestions(quests);
      setAskQuestion("");
      setShowPromptHistory(true);
      setEydScopeId(viewPrompt[0]?.scopeId);

      if (viewPrompt[0]?.isPublicConfidential === "C") {
        SetConfPublic("C");
        setPublicClick(false);
        setconfClick(true);
      } else {
        SetConfPublic("P");
        setPublicClick(true);
        setconfClick(false);
        setaskQuestionDisable(false);
      }
    }
  }, [viewPrompt]);

  useEffect(() => {
    if (isNewPromptCoversation) {
      setQuestions([]);
      setIsNewPromptCoversation(false);
    }
  }, [isNewPromptCoversation]);

  useEffect(() => {
    if (selectedDocDeleted) {
      setQuestions([]);
      window.location.reload();
    }
  }, [selectedDocDeleted]);

  useEffect(() => {
    setUserProjectRole();
    getScopeList();
    getEYDScopeId();
  }, []);

  /**
     * Description
     * -----------
     * This method is to get existing project detail filtering the projectid
     */
  const getScopeList = async () => {
    const response = await exploreService.GetEYDScopeDetails(localStorage.getItem('ProjectType'));
    setScopeList(response?.data);
  }
  /**
     * Description
     * -----------
     * This method is to get existing project detail filtering the projectid
     */
  const getEYDScopeId = async () => {
    const response = await exploreService.GetEYDScope(sessionStorage.getItem('DashboardProjectId'));
    setEydScopeId(response?.data);
  }

  useEffect(() => {
    if (scopeList.length > 0) {
      const matchedItem = scopeList.find(item => item.scopeId === Number(eydScopeId));
      setEydScopeId(matchedItem?.scopeId?.toString());
    }
  }, [scopeList, eydScopeId]);

  const setUserProjectRole = async () => {
    let memberList = await memberService.GetProjectDetailById(sessionStorage.getItem('DashboardProjectId'));
    let memberDetail = memberList.filter(item => item.email.includes(email));
    localStorage.setItem('userRole', memberDetail[0].role);
    dispatch(
      setUserRole({
        userRole: memberDetail[0].role,
      })
    );
  }

  /**
      * Description
      * -----------
      * This method is to call when user selects any Scope
      * Parameters:
      * data: this one is onChange event parameter to get the selected text
      */
  const onScopeChange = (e) => {
    setEydScopeId(e)
  }
  const OnChangeQuestion = (event) => {
    event.preventDefault();
    console.log("event", event);
    setAskQuestion(event.target.value);
    setIsSubmitPrompt(false);
  };

  /**
   * Description
   * -----------
   * This function is set the Source document tab as active. When user focus on ask a question input field.
   */
  const handleQuestionFocus = (isSrcDocActive) => {
    setIsSrcDocActive(isSrcDocActive);
  }

  const onSendPrompt = () => {
    if (askQuestion !== "") {
      if (questions.length === 0) {
        if (sessionStorage.getItem("PromptConversationId")) {
          sessionStorage.removeItem("PromptConversationId");
        }
        sessionStorage.setItem("PromptConversationId", uuidv4());
      }
      if (showPromptHistory) {
        setShowPromptHistory(false);
      }
      setQuestions([...questions, askQuestion]);
      setQuestionBar("");
      setAskQuestion("");
      setIsSubmitPrompt(true);
      setIsPromptSubmitted(true);
    }
  };
  const AddPromptToQuestionBar = (EditPrompt = String) => {
    setAskQuestion(EditPrompt, uuidv4());
  };

  const selctedSourceDocs = useCallback((selectdocs) => {
    const selectedDocs = selectdocs.filter(item => item.selected === true).length;
    setaskQuestionDisable(selectedDocs === 0);
    const resultitems = selectdocs?.filter((item) => item?.selected);
    const docnames = resultitems?.map((item) => item.documentName).join(', ');
    setSelectedSourceDocs(docnames);
  }, []);

  const sendButtonValidations = () => {
    if (!eydScopeId) {
      setSendToolMsg1(constantData.sendTooltipNoScope1);
      setSendToolMsg2(constantData.sendTooltipNoScope2);
      setIsSendBtnDisable(true);
    }
    else if (askQuestionDisable) {
      setSendToolMsg1(constantData.sendTooltipNoDoc1);
      setSendToolMsg2(constantData.sendTooltipNoDoc2);
      setIsSendBtnDisable(true);
    }
    else {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = askQuestion;
      const textContent = tempDiv.textContent || tempDiv.innerText || '';
      if (textContent.trim() === "") {
        setSendToolMsg1(constantData.sendTooltipNoQues1);
        setSendToolMsg2(constantData.sendTooltipNoQues2);
        setIsSendBtnDisable(true);
      }
      else {
        setIsSendBtnDisable(false);
      }
    }
  }

  return (
    <div>
      {/* <ExploreContextProvider> */}
      <div className="HeaderLayout">
        <BreadCrumb
          projectName={projectName}
          pageName="Explore Your Data"
        />
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Explore Your Data</span>
          </div>
          <div className="HeaderAction">

          </div>
        </div>
      </div>
      <div className="explore-data-left-right-panel">
        {!showInstantModal && (
          <div className="explore-data-container" id="id-scroll-explore-data-container">
            <div className="explore-data-container-child">
              {questions.length === 0 && (
                <div>
                  <div className="explore-data-container-image-container">
                    <img src={explore} alt="explore your data" />
                    <div className="explore-data-container-image-container-child">
                      <div className="explore-data-container-image-container-text">

                        Please select documents you'd like to start searching with
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {questions.length > 0 && !selectedDocDeleted &&
                questions.map((question, index) => {
                  return (
                    <div className="explore-prompt-phase-parent" key={index}>
                      <ExplorePhase
                        question={question}
                        publicConf={confPublic}
                        addprompt={AddPromptToQuestionBar}
                        isPromptSubmit={isSubmitpromt}
                        showPromotHistory={showPromptHistory}
                        selectedDocNames={selectedSourceDocs}
                        showInstantModal={showInstantModal}
                        setShowInstantModal={setShowInstantModal}
                        setSelectedDocDeleted={setSelectedDocDeleted}
                        setIsSrcDocActive={setIsSrcDocActive}
                        scopeId={eydScopeId}
                        setUpdatePersonalPrompt={setUpdatePersonalPrompt}
                      />
                    </div>
                  );
                })}
              {
                !hidePromptSearchInput && (
                  <div className="explore-data-container-promptarea-container">
                    <div className="explore-data-container-prompt-question-container">
                      <div className="explore-data-container-promptarea-buttons">
                        <div className="eyd-select-div">
                          <MotifFormField >

                            {eydScopeId === 0 || eydScopeId === undefined ? (
                              <MotifLabel id="selectscope">Select Scope</MotifLabel>
                            ) : null}
                            <MotifSelect
                              id="scopeSelect"
                              className="Input-eyd-select"
                              ariaLabelledBy="select-label"
                              onChange={onScopeChange}
                              value={eydScopeId}
                            >
                              {scopeList &&
                                [...scopeList.map((item) => {
                                  return (
                                    <MotifOption
                                      className={item.scopeName}
                                      value={item.scopeId.toString()}
                                      key={item.scopeId} >
                                      {item.scopeName}
                                    </MotifOption>
                                  );
                                }),

                                ]
                              }
                            </MotifSelect>
                          </MotifFormField>
                        </div>
                        <div className="eyd-buttons-div">
                          <MotifButton
                            className={
                              confClick ? "confpublic ActivePublic " : "ActiveConf"
                            }
                            onClick={(e) => {
                              SetConfPublic("C");
                              setPublicClick(false);
                              setconfClick(true);
                              (selectedSourceDocs === '') ? setaskQuestionDisable(true) : setaskQuestionDisable(false);
                            }}
                          >
                            Confidential
                          </MotifButton>

                          <MotifButton
                            className={
                              publicClick
                                ? "confpublic ActivePublic  marginpublic"
                                : "ActiveConf marginpublic"
                            }
                            onClick={(e) => {
                              SetConfPublic("P");
                              setPublicClick(true);
                              setconfClick(false);
                              setaskQuestionDisable(false);
                            }}
                          >
                            Public
                          </MotifButton>
                        </div>
                      </div>

                      <MotifFormField
                        style={{
                          width: "94%",
                          marginLeft: "20px",
                          marginBottom: "0px",
                          cursor: askQuestionDisable ? "not-allowed" : null,
                        }}
                      >
                        <div ref={promptRef}>
                          <MotifRichTextEditor
                            id="explore-prompt-box-editor"
                            placeholder="Ask a question..."
                            value={askQuestion}
                            modules={motifReactEditorModules}
                            onKeyDown={handlePromptSubmit}
                            onChange={handlePromptChange}
                            onKeyPress={(e) => { if (e.key === 'Enter') { onSendPrompt() } }}
                            onFocus={(e) => { handleQuestionFocus(true) }}
                          />
                        </div>

                        <MotifIconButton
                          className="btnExpandPrompt"
                          onClick={() => viewExpandPrompt()}
                          disabled={askQuestionDisable}
                        >
                          <MotifTooltip
                            placement="top"
                            contentClassName="tooltipWithoutCloseCenterText tooltipExpandBtn"
                            hideCloseButton={true}
                            trigger={<img src={expandprompt} alt="" />}
                          >
                            Expand
                          </MotifTooltip>
                        </MotifIconButton>
                        <MotifButton
                          onClick={() => onSendPrompt()}
                          disabled={isSendBtnDisable}
                          className="btnSendPrompt"
                        >
                          <MotifTooltip
                            hide={!isSendBtnDisable}
                            placement="top"
                            hideCloseButton={true}
                            contentClassName="tooltipWithoutCloseCenterText sendBtnTooltip"
                            trigger={
                              <img src={senddiagonal} alt="" className="iconSendPrompt" />
                            }>
                            <span className="sendTooltipContent1" >{sendToolMsg1}</span>
                            <br /><span className="sendTooltipContent2" >{sendToolMsg2}</span>
                          </MotifTooltip>
                        </MotifButton>
                      </MotifFormField>

                      <div className="explore-data-container-response-note">
                        Please always be mindful of your obligations as an EY Professional and use Digital Diligence Assistant
                        responsibly in line with the&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.eyAiPrinciples.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.eyAiPrinciples.name}
                        </a>,&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.msAcceptableUsePolicy.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.msAcceptableUsePolicy.name}
                        </a>,&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.msCodeOfConductOpenAIServices.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.msCodeOfConductOpenAIServices.name}
                        </a>
                        &nbsp;and the&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.enagagementManagement.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.enagagementManagement.name}
                        </a>
                        . Always make sure that you (a) have the required rights and permissions
                        to use data with Digital Diligence Assistant and (b) validate any outputs prior to its use. Use of
                        Digital Diligence Assistant must be in line with SaT Service Line requirements as outlined in the&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.enagagementManagement.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.enagagementManagement.name}
                        </a>
                        &nbsp;and any EY work product using such output must be reviewed for accuracy and validity and follow all
                        country and/or SaT Service Line specific quality processes and guidelines.
                      </div>

                    </div>
                  </div>
                )
              }


            </div>
          </div>)}
        {showInstantModal && (
          <div className="explore-instant-container">
            <ExplorePhase
              showInstantModal={showInstantModal}
              setShowInstantModal={setShowInstantModal}
            />
          </div>
        )}
        <div className="explore-data-right-panel">
          <ExloreRightPanal
            setShowInstantModal={setShowInstantModal}
            publicOrConfidential={confPublic}
            selectdocs={selctedSourceDocs}
            isSrcDocActive={isSrcDocActive}
            handleQuestionFocus={handleQuestionFocus}
            handlePromptToQuestionBar={AddPromptToQuestionBar}
            setEydScopeId={setEydScopeId}
            updatePersonalPrompt={updatePersonalPrompt}
            setUpdatePersonalPrompt={setUpdatePersonalPrompt}
          />
        </div>
        {expandPromptModal && (
          <div>
            <ExpandPromptModal
              expandPromptModal={expandPromptModal}
              setExpandPromptModal={setExpandPromptModal}
              questionBar={questionBar}
              setQuestionBar={setQuestionBar}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExploretData;
