import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/image/logo.svg";
import logOut from "../../assets/image/log-out.svg";
import avatarImage from "../../assets/image/default-user-icon.jpg";
import userGuide from "../../assets/image/user-guide.svg"
import { MotifDropdownItem, MotifAvatar, MotifDropdownPortal } from "@ey-xd/motif-react";
import EngagementLocationService from "../../services/engagementLocationService";
import { DashboardContext } from "../../context/dashboardContext";
import { useMsal } from '@azure/msal-react';
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import { dashboardDataConstants } from "../dashboard/constants";
import NotificationSlider from "./NotificationSlider";

export function Header(props) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState("");
  const engagementLocationService = new EngagementLocationService();
  const constantData = require("../../data/constant.js");
  const routeLocation = useLocation();
  const [isVisible, setIsVisible] = useState(null);
  let { getProjects } = useContext(DashboardContext);
  const [showNotificationPopover, setShowNotificationPopover] = useState(false);
  const [notificationDetail, setNotificationDetail] = useState([]);

  /**
  * Description
  * -----------
  * This useffect is checking if  EngagementLocation available in localstorage and then set in variable
  */
  useEffect(() => {
    engagementLocationData();
  }, []);

  /**
  * Description
  * -----------
  * This method is getting engagementlocation from config and setting them in list
  */
  const engagementLocationData = async () => {
    let result = await engagementLocationService.getEngagementLocationData();
    if (result) {
      setLocationList(result);
      if (localStorage.getItem("EngagementLocation")) {
        setLocation(JSON.parse(localStorage.getItem("EngagementLocation")).content);
      }
    }
  }

  /**
   * Description
   * -----------
   * This useffect is to check if the page loaded is dashboard to show the engagementlocation div 
   */

  useEffect(() => {
    setIsVisible(routeLocation.pathname.toUpperCase().match(constantData.dashboardLocation) ? true : false);
  }, [constantData.dashboardLocation, routeLocation.pathname])


  /**
   * Description
   * -----------
   * This method shows the username on avatar image
   */
  const showLoggedInUserMenu = () => {
    setShowUserMenu(true);
  };

  /**
   * Description
   * -----------
   * This method hides the username on avatar image
   */
  const hideLoggedInUserMenu = () => {
    setShowUserMenu(false);
  };

  /*
   * Description
   * -----------
   * This method is to navigate to dashboard page.
   */
  const navigateToDashboard = () => {
    if (window.location.pathname === '/Dashboard') {
      navigate("/Dashboard");
    } else {
      window.location.href = "/Dashboard";
    }
  };

  const handleDataselected = (newData) => {
    props.setNotificationData(newData);
    props.setShowNotificationPopup(true);
  };
  /*
   * Description
   * -----------
   * This method is to navigate to user guide page.
   */

  const navigateToUserGuide = () => {
    window.open(dashboardDataConstants.userGuide.url, "_blank");
  };

  /**
   * Description
   * -----------
   * This method logout from Azure Ad and navigate to logout page
   */
  const handleLogOut = (e) => {
    localStorage.clear();
    sessionStorage.clear();
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: "/LogOut",
    };
    instance.logout(logoutRequest);
  };

  /**
         * Description
         * -----------
         * This method is to call when user selects any country
         * Parameters:
         * data: this one is onChange event parameter to get the selected text
         */
  const onEngagementLocationChange = (e) => {
    saveEngagementLocation(e);
  }
  /**
    * Description
    * -----------
    * This method is to  save the location details into localstorage
    */
  const saveEngagementLocation = (selectedLocation) => {
    if (selectedLocation !== "") {
      localStorage.setItem('EngagementLocation',
        JSON.stringify(locationList.filter(item => item.content === selectedLocation)[0]));
    }
    //Rebind the dashboard based on the engagement location selected
    getProjects(sessionStorage.getItem("Email"));
  }
  return (
    <>
      <div className="header">
        <div className="logomenu" style={{ display: "flex" }}>
          <div className="logoDiv">
            <div className="logo">
              <img src={logo} alt="" onClick={navigateToDashboard}></img>
            </div>
          </div>
          <div className="projectTitle">Digital Diligence Assistant</div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
          <NotificationSlider sendNotificationData={handleDataselected} />
          <div className="profileImageContainer">
            <MotifDropdownPortal
              aria-labelledby="dropdown-trigger-3"
              id="ProfileDropdown"
              open={showUserMenu}
              handleClickOutside={() => hideLoggedInUserMenu()}
              className="dropdownContainer"
              placement="bottom"
              hideArrow={true}
              trigger={
                <MotifIconButton
                  aria-label="Profile"
                  onClick={() => showLoggedInUserMenu()}
                  id="divUserAvatar"
                  className="Avatar"
                >
                  <MotifAvatar
                    description="Profile Icon"
                    src={avatarImage}
                    className="userAvatar"
                  //size="medium"
                  />
                </MotifIconButton>
              }
            >
              <MotifDropdownItem
                onClick={function noRefCheck() { }}
                className="userAvatarUserMenuItem"
              >
                <div className="displaymenu">
                  <div className="displaymenuDiv">
                    {
                      <img
                        src={avatarImage}
                        alt="Avatar"
                        className="AvatarDiv"
                      ></img>
                    }
                    {
                      <div className="CurrentUserNameDiv">
                        {" "}
                        <b>{sessionStorage.getItem("CurrentUserName")}</b>
                        <div className="EmailDiv">
                          {" "}
                          {sessionStorage.getItem("Email")}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </MotifDropdownItem>
              <div className="Divider1 divider" />
              <MotifDropdownItem
                onClick={() => navigateToUserGuide()}
                className="userguidecontainer"
              >
                <img className="userguideimg" alt="icon" src={userGuide} />
                <span className="userguide">User Guide</span>
              </MotifDropdownItem>
              <div className="Divider1 divider" />
              <MotifDropdownItem
                onClick={(e) => handleLogOut(e)}
                className="logoutcontainer"
              >
                <img className="logoutimg" alt="icon" src={logOut} />
                <span className="logout">Log out</span>
              </MotifDropdownItem>
              <div className="Divider1 divider" />
            </MotifDropdownPortal>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
