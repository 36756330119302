import React, { useState, useEffect } from "react";
import { MotifCheckbox, MotifTree, MotifTreeNode } from "@ey-xd/motif-react";

import arrowDown from "../../../../assets/image/Arrow_down.svg";
import arrowUp from "../../../../assets/image/Arrow_up.svg";

import "./TreeView.css";

export default function TreeView(props) {

    const { treeData, nodeExpansionGraph, updateAreNodesSelected, bubbleBackData } = props;
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [scopeHasStructure, setScopeHasStructure] = useState(true);
    const [selectedScope, setSelectedScope] = useState(null);
    const [message, setMessage] = useState("");

    useEffect(() => {
        setSelectedNodes([]);
    }, [props.refresh])

    useEffect(()=>{
        // Add required references and properties to tree data
        addReferences(treeData, null);
        
    },[treeData])

    useEffect(()=>{
        if(selectedNodes.length > 0){
            updateAreNodesSelected(true);
        }
        else {
            updateAreNodesSelected(false);
        }
        bubbleBackData(selectedNodes);
    }, [selectedNodes])

    useEffect(() => {
        setScopeHasStructure(false);
        if (scopeHasStructure) {
            setMessage("Please create Category, Section and Subsections")
        }
        else if (!selectedScope) {
            setMessage("Select a Project Scope to view the tree hierarchy")
        }

    }, [scopeHasStructure, selectedScope])

    const addReferences = (node, parent) => {
        if (treeData) {
            // Check if parent reference is present in data else add parent reference
            if (!treeData.parent) {
                node.parent = parent;
            }

            // Check if isSelected property is present in data else add isSelected property
            if (!treeData.isSelected) {
                node.isSelected = false;
            }

            if (node.children && node.children.length != 0) {
                node.children.forEach((child) => addReferences(child, node));
            }
        }
    }

    const getCheckboxIfApplicable = (node) => {
        if (props.showCheckbox) {
            return (
                <MotifCheckbox
                    key={node.value}
                    checked={node.isSelected}
                    disabled={node.isPublished}
                    onChange={(e) => {
                        
                    }}
                />
            );
        }
        return null;
    };

    const getNodeMarkup = (node) => {
        return (
            <div className={props.showCheckbox ? "div-checkbox" : "icon-only"} onClick={() => { treeNodeClick(node) }}
            >
                {getCheckboxIfApplicable(node)}
                <button className="plain-button" title={node.value && (node.value.length > 45 ? node.value : '')}
                disabled={node.disabled}
                >
                    {node.value}
                </button>
            </div>
        );
    };

    const checkIfNodeShouldBeExpanded = (node) => {
        if (nodeExpansionGraph.find((expandedNode) => {
            return expandedNode.value === node.value;
        })) {
            return true;
        }
        else {
            return false;
        }
    }

    const renderTree = (node) => {
        if (!node) return null;

        return (
            <MotifTreeNode 
                value={getNodeMarkup(node)}
                open={checkIfNodeShouldBeExpanded(node)}
                key={node.value}
                icons={{
                    close: <img src={arrowUp}></img>,
                    open: <img src={arrowDown}></img>,
                }}
                >
                {node.children && node.children.map((child) => renderTree(child))}
            </MotifTreeNode>
        );
    };
      
    const createMotifTree = (treeData) => {
        return <MotifTree showLines={true}>{treeData.map((rootNode) => renderTree(rootNode))}</MotifTree>;
    };

    const updateSelection = (node) => {
        if (node?.children && node.children.length > 0) {
            // If any child is unselected, unselect the parent
            if (node.children.some((child) => !child.isSelected)) {
                node.isSelected = false;
            }
            else {
                // If all children are selected, select the parent
                node.isSelected = true;
            }

            // Recursively update parent nodes
            if (node.parent) {
                updateSelection(node.parent);
            }
        }
    }

    const addRemoveNode = (node, action) => {
        setSelectedNodes((selectedNodes) => {
            if (action === "add" && !node.disabled) {
                // Remove redundant nodes (child or parent) based on node type
                if (node.nodeType === 3) {
                    // Subsection: Add directly if no children
                    return [...selectedNodes, node];
                } else if (node.nodeType === 2) {
                    // Section: Remove subsections under this section and add the section
                    const filteredNodes = selectedNodes.filter(
                        (selectedNode) => !node.children.some((child) => child.id === selectedNode.id)
                    );
                    return [...filteredNodes, node];
                } else if (node.nodeType === 1) {
                    // Category: Remove sections and subsections under this category
                    const filteredNodes = selectedNodes.filter(
                        (selectedNode) =>
                            !traverseNodeAndCollectIds(node).includes(selectedNode.id)
                    );
                    return [...filteredNodes, node];
                }
            } else if (action === "remove") {
                // Remove the node and its children from selectedNodes
                return selectedNodes.filter(
                    (selectedNode) => selectedNode.id !== node.id
                );
            }
            return selectedNodes;
        });
    
        // Update the node's selection state
        node.isSelected = action === "add";
    };
    
    const traverseAndUpdateTree = (node, action) => {
        if (!node.children || node.children.length === 0) {
            // Subsection or leaf node: add/remove directly
            addRemoveNode(node, action);
        } else {
            // Section or category node
            addRemoveNode(node, action);
    
            // Recursively update children
            node.children.forEach((child) => {
                traverseAndUpdateTree(child, action);
            });
        }
    };
    
    const treeNodeClick = (node) => {
        if (node.isSelected) {
            // Node is already selected, remove it and its descendants
            traverseAndUpdateTree(node, "remove");
        } else {
            // Node is not selected, add it and handle special cases
            traverseAndUpdateTree(node, "add");
        }
    };
    
    // Helper function to traverse the node and collect all child IDs
    const traverseNodeAndCollectIds = (node) => {
        const ids = [];
        const stack = [node];
        while (stack.length > 0) {
            const current = stack.pop();
            ids.push(current.id);
            if (current.children) {
                stack.push(...current.children);
            }
        }
        return ids;
    };
    

    return (
        <div className="tree-div">
            {treeData && createMotifTree(treeData)}
        </div>
    )
}