import React, { useEffect, useState } from "react";
import {
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifOption,
  MotifSelect,
} from "@ey-xd/motif-react";
import _ from "lodash";
import PlatformService from "../../../../../services/platformService";

const PromptProjectScope = (props) => {
  const platformService = new PlatformService();
  const [treeData, setTreeData] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");

  /**
   * This method selects the node from the project scope tree
   * @param {*} node
   */
  const getData = async () => {
    let apiData = await platformService.getPowerPointSystemPromptAPIList(
      '',
      "A",
      "PPT_AddIn"
    );
    setTreeData(apiData);
    setSelectedItem("");
  }
  useEffect(() => {
    getData();
  }, [])

  const handleTreeNodeClick = (node) => {
    setSelectedItem(node.api);
    props.setSelectedNode(node, {});
  };

  return (
    <div className="ii-prompt-left-section">
      <div
        id="ii-prompt-project-scope"
        className="ii-prompt-project-scope-container"
      >
        <span className="ii-prompt-select-scope-label">
          Select Project Scope
        </span>
        <MotifFormField>
          <MotifSelect
            id="scopeSelectPP"
            label="selectscope"
            data-testid="scopeSelect"
            className="selectPowerpointScope"
            ariaLabelledBy="select-label"
            placeholder="Select Project Scope"
            required
            value="General"
            disabled
          >
            <MotifOption
              value="General"
            >
              General
            </MotifOption>
          </MotifSelect>
        </MotifFormField>
      </div>
      <div className="ii-tree-container">
        {props.tab === "PPSystemPrompts" && (
          <div className="system-prompts-category-container">
            {treeData?.length > 0 &&
              treeData?.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className={`system-prompts-category ${item.api === selectedItem ? "active" : ""
                      }`}
                    onClick={() => {
                      handleTreeNodeClick({ api: item.api });
                    }}
                  >
                    <div className="system-prompts-category-name">
                      {item.api}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {props.tab === "PPCentralPrompts" && (
          <div className="system-prompts-category-container">
            {treeData?.length > 0 &&
              treeData?.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className={`system-prompts-category ${item.api === selectedItem ? "active" : ""
                      }`}
                    onClick={() => {
                      handleTreeNodeClick({ api: item.api });
                    }}
                  >
                    <div className="system-prompts-category-name">
                      {item.api}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default PromptProjectScope;
