import axiosBaseQuery from "../services/axiosBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const notificationApi = createApi({
  reducerPath: "notificationApi",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_PLATFORM_API_URL,
  }),
  tagTypes: ["NotificationTypes", "Notifications"],
  endpoints: (builder) => ({
    getNotificationType: builder.query({
      keepUnusedDataFor: 10 * 60 * 300,
      query: () => ({
        url: "Platform/GetNotificationTypes",
        method: "GET",
      }),
      providesTags: ["NotificationTypes"],
    }),

    getNotifications: builder.query({
      query: ({ Type, notificationid }) => ({
        url: "Platform/GetNotifications",
        method: "GET",
        params: { Type, notificationid },
      }),
      transformResponse: (response) => response.data,
      providesTags: ["Notifications"],
    }),

    publishNotification: builder.mutation({
      query: (payload) => ({
        url: "Platform/CreateNotification",
        method: "POST",
        data: payload,
        headers: {
          "Content-type": "application/json;charset=UTF-8",
        },
      }),
      transformResponse: (response) => response.status,
      invalidatesTags: ["Notifications"],
    }),
    removeNotification: builder.mutation({
      query: (payload) => ({
        url: "Platform/RemoveDeleteNotification",
        method: "POST",
        data: payload,
        headers: {
          "Content-type": "application/json;charset=UTF-8",
        },
      }),
      transformResponse: (response) => response.status,
      invalidatesTags: ["Notifications"],
    }),
  }),
});

export const {
  useGetNotificationTypeQuery,
  useGetNotificationsQuery,
  usePublishNotificationMutation,
  useRemoveNotificationMutation,
} = notificationApi;
