import React, { useState } from 'react';
import {
    MotifTooltip
} from "@ey-xd/motif-react";
import infoMember from "../../../assets/image/info-member.svg";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import "./DocumentPopUp.css"

const DocumentPopUp = ({tooltipMessage}) => {
    const [showTooltip, setshowTooltip] = useState(false);
    return (
        <div>
            <MotifTooltip
                hideCloseButton={true}
                allowHover={true}
                allowClick={false}
                hide={showTooltip}
                placement="bottom"
                contentClassName="tooltipWithoutClose"
                trigger={
                    <MotifIconButton onMouseEnter={() => setshowTooltip(false)}
                    >
                        <img src={infoMember} alt='' />
                    </MotifIconButton>
                }
            >
                <div >
                    <div className='toolip-text'><span>{tooltipMessage}</span></div>

                </div>
            </MotifTooltip>
        </div>
    );

}

export default DocumentPopUp;