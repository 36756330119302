import { createSlice } from "@reduxjs/toolkit";
import { tabIds } from "../data/constant";

const initialState = {
  activeTab: tabIds.Active,
  notifications: [],
};

const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },

    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const { setActiveTab, setNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
