import React, { useEffect, useState } from "react";
import "./../ProjectWorkspace.css";
import {
  MotifFormField,
  MotifOption,
  MotifSearch,
  MotifSelect,
  MotifLabel,
} from "@ey-xd/motif-react";
import FavoriteList from "./FavoriteList";
import search from "../../../../assets/image/search.svg";
import _ from "lodash";
import WorkSpaceService from "../../../../services/workspaceService";

const FavoriteResponses = (props) => {
  const workspaceService = new WorkSpaceService();
  const [favOrigin, setFavOrigin] = useState("ALL");
  const [favAction, setFavAction] = useState("All");
  const [actionValue, setActionValue] = useState("All");
  const [favoriteList, setFavoriteList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isOrginSelected, setIsOrginSelected] = useState(false);
  let [filteredFavoriteList, setFilteredFavoriteList] = useState(null);

  useEffect(() => {
    if (favOrigin && favAction) {
      setFilteredFavoriteList("");
      fetchFavoriteResponse();
    }
  }, [favOrigin, favAction]);

  /**
   * Description
   * -----------
   * This method is on change of Origin select control .
   * Parameters:
   * value: this one is onChange event parameter to get the Select Control
   */
  const orginChange = (value) => {
    setFavOrigin(value);
    setFavAction("All");
    setActionValue("All");
    props.onFavoriteResponseFilterChange();
  };

  /**
   * Description
   * -----------
   * This method is on change of Action select control .
   * Parameters:
   * value: this one is onChange event parameter to get the Select Control
   */
  const actionChange = (value) => {
    setFavAction(value);
    setActionValue(value);
  };

  /**
   * This method fetches the favorite prompts/instant insights based on the user selection
   */
  const fetchFavoriteResponse = async () => {
    let favoriteResponseResult =
      await workspaceService.getPersonalWorkspaceFavoritePrompts(
        sessionStorage.getItem("DashboardProjectId"),
        favOrigin,
        favAction
      );
    setFavoriteList(favoriteResponseResult);
    setIsOrginSelected(true);
  };

  /**
   * Description
   * -----------
   * This method is to filter the favorites from the search input.
   * Parameters:
   * e: this one is onChange event parameter to get the input text
   */
  const filterFavorites = (e) => {
    setSearchValue(e.target.value);
    let filterProjectsData = _.filter(favoriteList, function (item) {
      return (
        item.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.tags.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setFilteredFavoriteList(filterProjectsData);
  };

  /**
   * Description
   * -----------
   * This method is to refersh the favorites when any prompt is deleted.
   */
  const refreshFavoriteResponses = () => {
    fetchFavoriteResponse();
  };

  return (
    <div className="favorite-responses-container">
      <div className="favorite-responses-header-container">
        <div className="favorite-responses-header">
          <div className="favorite-responses-header-text">Favorites</div>
        </div>
      </div>
      <div className="favorite-filter-inner-box">
        <div className="favorite-filter-box">
          <div className="pw-filter-lables">Filter by origin</div>
          <MotifFormField>
          {/* {favOrigin?.length === 0 ? <MotifLabel id="selectscope">No selection made</MotifLabel> : null} */}
            <MotifSelect
              className="filter-by-origin-input"
              value={favOrigin}
              onChange={orginChange}
              id="pwFilterByOrigin"
            >
              <MotifOption value="ALL">All</MotifOption>
              <MotifOption value="EYD_C">
                Explore your data (Confidential)
              </MotifOption>
              <MotifOption value="EYD_P">
                Explore your data (Public)
              </MotifOption>
              <MotifOption value="II">Instant Insights</MotifOption>
            </MotifSelect>
          </MotifFormField>
        </div>

        {isOrginSelected && (
          <>
            {/* <div className="favorite-filter-box">
              <div className="pw-filter-lables">Filter by action</div>
              <MotifFormField>
                <MotifSelect
                  className="filter-by-origin-input"
                  value={actionValue}
                  onChange={actionChange}
                  id="pwFilterbyaction"
                >
                  <MotifOption value="All">All</MotifOption>
                  <MotifOption value="NoAction">No Action</MotifOption>
                  <MotifOption value="Transferred">
                    Transferred (only)
                  </MotifOption>
                  <MotifOption value="Shared">Shared (only)</MotifOption>
                  <MotifOption value="SharedTransferred">
                    Shared + Transferred
                  </MotifOption>
                </MotifSelect>
              </MotifFormField>
            </div> */}
            <div
              className="favorite-filter-box favorite-filter-search-box"
              id="pwSearch"
            >
              <MotifFormField>
                <img className="searchFavorite" src={search} alt="" />
                <MotifSearch
                  className="searchTxtBoxFavorite"
                  aria-label="Search"
                  hideClearButton
                  id="states-search"
                  placeholder="Search"
                  value={searchValue}
                  onChange={filterFavorites}
                />
              </MotifFormField>
            </div>
            </>
        )}
            {favoriteList && favoriteList.length > 0 && (
            <FavoriteList
              favoriteList={
                filteredFavoriteList ? filteredFavoriteList : favoriteList
              }
              refreshFavoriteResponses={refreshFavoriteResponses}
            />
          )}
      </div>
    </div>
  );
};
export default FavoriteResponses;
