import React from "react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import warningTriangle from '../../assets/image/warning-triangle_red.svg';
import info from '../../assets/image/info-circle.svg'
import warningcircle from '../../assets/image/warning-circle.svg'
import pageFlip from '../../assets/image/page-flip-New.svg';
import "../AdminDashboard/PromptManagement/InstantInsightPrompts/DeletePromptPopup.css";



export  function notificationPopup({setShowNotificationPopup,notificationData}) { 
/**
   * Description
   * -----------
   * This method is to close the pop up
   */
  const btnModalCloseClick = () => {
    setShowNotificationPopup(false);
  };

  return (
    <MotifModal
    id="discard-modal"
    show={true}
    size="xlg"
    focusTrapOptions={{
      tabbableOptions: {
        displayCheck: "none",
      },
    }}
    onClose={btnModalCloseClick}
    className="dialog-modal"
  >
    <MotifModalHeader  closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Close",
          }} className="delete-modal-header">
      <div className="title-container">
        <div className="ii-notification-header-icon-div">
        <img src={
                            notificationData.notificationTypeId === 1 ? warningcircle
                                : notificationData.notificationTypeId === 2 ? warningTriangle
                                    : notificationData.notificationTypeId === 3 ? pageFlip : info
                        } alt=''  />


        </div>
        <div className="modalDelete-test-instant-title">{notificationData.title}</div>
      </div>
    </MotifModalHeader>
    <MotifModalBody>
      <div className="delete-instant-delete-prompt-container">
      <p
            key="editor-content"
            dangerouslySetInnerHTML={{ __html: notificationData.description }}
          />
        
        
   
      </div>

    </MotifModalBody>
  <MotifModalFooter>
        <div className="share-alert-btns-div">
          
         
        </div>
      </MotifModalFooter>
  </MotifModal>
  );
}

export default notificationPopup;
