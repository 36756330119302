import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from "@ey-xd/motif-react/Button";
import React, { useState } from "react";
import warningCircle from "../../assets/image/warning-circle.svg";
import "./RemoveNotificationPopup.css";
import DeleteIcon from "../../assets/image/warning-circle.svg";


const RemovePopup = ({
  selectedNotification,
  showRemovePopUp,
  setShowRemovePopup,
  onConfirmClick,

  onCancelClick,
}) => {
  const text = !selectedNotification?.isActive && !selectedNotification.isDraft;
  return (
    <div>
      <MotifModal
        id="closepopupmodal"
        show={showRemovePopUp}
        size="md"
        focusTrapOptions={{
          tabbableOptions: { displayCheck: "none" },
        }}
        onClose={onCancelClick}
        className="removeNotificationPopup"
      >
        <MotifModalHeader className="RemoveNotification-header">
          <img className="RemoveNotification-icon" src={DeleteIcon} alt="" />
          <div className="RemoveNotification-title">
            {text ? "Delete" : "Remove"} notification
          </div>
        </MotifModalHeader>
        <MotifModalBody className="RemoveNotification-body">
          <div className="RemoveNotificationPopup-innerbody">
            <span>Are you sure you want to {text ? "Delete" : "Remove"} the notification?</span>
          </div>
        </MotifModalBody>
        <MotifModalFooter className="RemoveNotification-footer">
          <MotifButton
            onClick={onConfirmClick}
            size="medium"
            type="button"
            variant="primary"
          >
            Confirm
          </MotifButton>
          <MotifButton
            onClick={onCancelClick}
            size="medium"
            type="button"
            variant="secondary"
          >
            Cancel
          </MotifButton>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
};

export default RemovePopup;
