import axiosInstance from "./axiosWithMsal";
import CommonService from "./commonService";

let commonService = new CommonService();

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async (
    { url, method, data, params, headers, component = "" },
    extraOptions
  ) => {
    try {
      const response = await axiosInstance({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });

      return {
        data: {
          status: response.status,
          data: response.data,
        },
      };
    } catch (axiosError) {
      let err = axiosError;
      commonService.logException(err, component, url);
      return {
        error: {
          status: err.response?.status || 500,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
