/**
 * Component for Notifications
 */

//  Generic imports
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//  Motif imports
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import { MotifButton, MotifToast } from "@ey-xd/motif-react";

import { setActiveTab } from "../../reducers/notificationSlice";

import {
  useGetNotificationsQuery,
  useRemoveNotificationMutation,
} from "../../reducers/notificationApi";

import RemovePopup from "../CreateNotification/RemoveNotificationPopup";
import infoEmpty from "../../assets/image/info-empty.svg";

// css imports
import "../ProjectDashboard/ProjectDashboard.css";
import "./Notification.css";

// icons imports
import noNotifiaction from "../../assets/image/no-notification-image.png";
import createnobell from "../../assets/image/create-no-bell.svg";
import CreateNotification from "../CreateNotification/CreateNotification";
import NotificationBar from "../CreateNotification/NotificationBar";

import {
  NOTIFICATION_REMOVED_MESSAGE,
  NOTIFICATION_DELETED_MESSAGE,
  tabIds,
} from "../../data/constant";

const Notification = () => {
  const tabs = [
    { id: tabIds.Active, label: "Active" },
    { id: tabIds.Draft, label: "Draft" },
    { id: tabIds.History, label: "History" },
  ];



  const [isCreatingOrEditNotification, setIsCreatingOrEditNotification] =
    useState(false);
  const dispatch = useDispatch();
  const tabRef = useRef(null);
  const activeTab = useSelector((state) => state.notification.activeTab);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [showRemovePopup, setShowRemovePopup] = useState(false);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(false)
  }, [activeTab])

  const { data: notifications } = useGetNotificationsQuery({
    Type: activeTab,
  });

  const [removeNotification] = useRemoveNotificationMutation();
  const isHistory = activeTab === tabIds.History;



  const handleCancelNotification = () => {
    setIsCreatingOrEditNotification(false);
    setSelectedNotification(null);
  };

  const handleCreateNew = () => {
    setIsCreatingOrEditNotification(true);
    setSelectedNotification(null);
  };

  const handleEdit = (notification) => {
    setIsCreatingOrEditNotification(true);
    setSelectedNotification(notification);
  };

  const handleRemove = (notification) => {
    setSelectedNotification(notification);
    setShowRemovePopup(true);
  };

  const confirmNotificationClick = async () => {
    try {
      if (!selectedNotification) return;
      // R represents moving active notification to history tab.
      const payload = {
        notificationId: selectedNotification?.notificationId,
        ...(selectedNotification?.isActive ? { type: "R" } : {}),
      };

      const status = await removeNotification(payload).unwrap();

      if (status === 204) {
        setShowToast(true);
      }
    } catch (error) {
      console.error("Error publishing notification:", error);
    } finally {
      setShowRemovePopup(false);
    }
  };

  const renderTabContent = () => {
    return (
      <div>
        {notifications && notifications?.length > 0 ? (
          notifications?.map((notification) => (
            <NotificationBar
              key={notification.notificationId}
              setIsCreatingNotification={setIsCreatingOrEditNotification}
              notificationData={notification}
              onEdit={() => handleEdit(notification)}
              onRemove={() => handleRemove(notification)}
            />
          ))
        ) : (
          <div className="no-notification-container">
            <div className="notification-container">
              <img
                className="icon-no-notification"
                src={noNotifiaction}
                alt="no no-notification icon"
              />
              <div className="no-notification">
                <span className="text-notification-found">
                  No notifications found
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleNotificationManagementTabClick = (tab) => {
    tabRef.current = tab;
    dispatch(setActiveTab(tab));
  };

  return (
    <div className="ProjectDashboardPageContainer">
      <div className="HeaderLayout">
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Notifications</span>
          </div>

          {!isCreatingOrEditNotification && (
            <MotifButton
              onClick={handleCreateNew}
              className="motif-Create-notification-button"
            >
              {<img alt="icon" src={createnobell} className="bell" />}

              <span className="text-button">Create Notification</span>
            </MotifButton>
          )}
        </div>
      </div>
      {isCreatingOrEditNotification ? (
        <div className="create-notification-section">
          <CreateNotification
            onCancel={handleCancelNotification}
            notificationData={selectedNotification}
          />
        </div>
      ) : (
        <div className="notification-managment-container">
          <div className="nevigation-notification-tab">
            <MotifTabNavigation controlled>
              {tabs.map(({ id, label }) => (
                <MotifTabControl
                  key={id}
                  className={activeTab === id ? "motif-active" : ""}
                  onClick={() => handleNotificationManagementTabClick(id)}
                >
                  {label}
                </MotifTabControl>
              ))}
            </MotifTabNavigation>
          </div>
          {activeTab && <div className="tab-content">{renderTabContent()}</div>}
        </div>
      )}
      {showRemovePopup && (
        <RemovePopup
          selectedNotification={selectedNotification}
          showRemovePopUp={showRemovePopup}
          setShowRemovePopup={setShowRemovePopup}
          onConfirmClick={confirmNotificationClick}
          onCancelClick={() => {
            setShowRemovePopup(false);
            setSelectedNotification(null);
          }}
        />
      )}

      {showToast && (
        <MotifToast
          id="toast-notification"
          variant={isHistory ? "error" : "info"}
          className="notification-remove-toast"
          actionName=""
          position="bottom"
          onClose={() => setShowToast(false)}
          icons={{ toast: <img src={infoEmpty} alt=""></img> }}
        >
          <div className="notification-toast-content">
            {isHistory
              ? NOTIFICATION_DELETED_MESSAGE
              : NOTIFICATION_REMOVED_MESSAGE}
          </div>
        </MotifToast>
      )}
    </div>
  );
};

export default Notification;
