export const CreateAction = "N";
export const EditAction = "Y";
export const EditSuccessMesage = "PROJECT UPDATED SUCCESSFULLY";
export const CreateProjectHeading = "Project details";
export const EditProjectHeading = "Details";
export const StyleType = "relative";
export const ProjectCreatedMessage = "Y";
export const PromptType = {
  Personal: "Personal",
  SatBestPractice: "SaT Best Practice",
};
export const ProjectApprovalTextPart1 = "Engagement teams must read the";
export const ProjectApprovalTextPart2 =
  "to confirm permissibility to use the platform on their engagement and understand other quality and risk management considerations prior to creating a project in Digital Diligence Assistant.";
export const ProjectAIDisclaimerTextPart1 =
  "Please always be mindful of your obligations as an EY Professional and use Digital Diligence Assistant responsibly in line with the";
export const ProjectAIDisclaimerTextPart2 =
  "Always make sure that you (a) have the required rights and permissions to use data with Digital Diligence Assistant and (b) validate any outputs prior to its use. Use of Digital Diligence Assistant must be in line with the Service Line requirements as outlined in the Engagement Management Guidance and any EY work product using such output must be reviewed for accuracy and validity and follow all country and/or Service Line specific quality processes and guidelines.";
export const restrictedLocation = ["China", "Taiwan", "Kazakhstan"];
export const dashboardLocation = "/DASHBOARD";
export const restrictedTooltipData =
  "Please check the Engagement Management Guidance for engagement location permissibility";
export const ProjectEngagement =
  "Please confirm that the selected engagement location is correct. If a change is required please return to the Dashboard to select the appropriate engagement location.";
export const ProjectEngagementNote =
  "Note: The selected engagement location should reflect the location of the EY member firm that holds the engagement agreement with the client - it is not the location of the Target / Client company itself.";
export const PromptHeader = "Prompt:";
export const ResponseHeader = "Response:";
export const InstantInsightTopics = [
  { id: 1, name: "Report Sections" },
  { id: -1, name: "Red-Flag Items" },
  { id: -2, name: "Management Follow-up questions" },
];
export const rolePopheader = "Roles";
export const roleNonSatCaption = "User (Non-SaT)";
export const rolePopUpNonSatPart1 =
  " - able to use the functionality, view documents and ";
export const rolePopUpNonSatPart2 = "refresh Instant Insights.";
export const roleSatCaption = "User (SaT)";
export const rolePopUpSatPart1 =
  " - has all the capabilities of a User (Non-SaT), plus is able";
export const rolePopUpSatPart2 =
  "to add documents, download documents and run Instant Insights.";
export const roleAdminCaption = "Admin";
export const rolePopUpAdminPart1 =
  " - has all the capabilities of a User (SaT), plus is able to edit";
export const rolePopUpAdminPart2 =
  "project details, add/remove members in a project, change a";
export const rolePopUpAdminPart3 = "member's role and close a project.";
export const sendTooltipNoDoc1 = "No files selected";
export const sendTooltipNoDoc2 =
  "Please select a file from the 'Source documents' tab";
export const sendTooltipNoScope1 = "No scope selected";
export const sendTooltipNoScope2 = "Please select a scope from the dropdown";
export const sendTooltipNoQues1 = "No question entered";
export const sendTooltipNoQues2 = "Please enter a question in the chat bar";
export const manageCategory = "category";
export const manageSection = "section";
export const manageSubSection = "subsection";
export const renameCategoryTitle = "Rename Category";
export const renameCategoryHeader =
  "Changes made to the category name will be automatically updated throughout the Instant Insights Prompts. Click ‘Confirm’ to apply.";
export const renameSectionTitle = "Rename Section";
export const renameSectionHeader =
  "Changes made to the section name will be automatically updated throughout the Instant Insights Prompts. Click ‘Confirm’ to apply.";
export const renameSubSectionTitle = "Rename SubSection";
export const renameSubSectionHeader =
  "Changes made to the subsection name will be automatically updated throughout the Instant Insights Prompts. Click ‘Confirm’ to apply.";
export const addCategoryTitle = "Add New Category";
export const addSectionTitle = "Add New Section";
export const addSubSectionTitle = "Add New SubSection";
export const satPromptAddedToastMsg = "The prompt has been added successfully";
export const PersonalPromptAddedToastMsg = "The prompt has been added successfully";
export const satPromptEditedToastMsg = "The prompt has been edited successfully";
export const duplicateErrorMsg = "Title already in use, choose another title";
export const TitleExist = "Title already exists";
export const tagExist = "Tag already exists";
export const discardIncompleteStructure = {
  messageNavigation:
    "The addition to the structure is incomplete, leaving the page at this stage will discard the changes.",
  messageSelectionChange:
    "The addition to the structure is incomplete, modifying the selection at this stage will discard the changes.",
};
export const conversationDeleteMsg =
  "Conversation has been deleted successfully.";
export const promptDeleteMsg = "Prompt has been removed successfully.";
export const allDocumentsRemovedMsg =
  "Documents have been removed successfully";
export const documentRemovedMsg =
  "Documents removed. Selected documents have been removed successfully.";
export const uploadDocumentConfirmMsg = "Click ‘Confirm’ to upload";
export const uploadDocumentCountExceedMsg =
  "Number of documents exceeds the limit. Click ‘Modify selection’ and remove documents to continue";
export const uploadDocumentSizeExceedMsg =
  "Combined file size exceeds the limit. Click ‘Modify selection’ and remove documents to continue";
export const uploadDocumentPasswordProtectedMsg =
  "Password protected files identified, enter a password to continue";
export const uploadDocumentDuplicateMsg =
  "Duplicate documents identified. Select documents and click 'Replace' 'Keep a copy' or 'Remove documents' to continue";
export const uploadDocumentSpecialCharsMsg =
  "Special characters identified in file names. Click ‘Remove documents’ to continue";
export const uploadDocumentUnSupportedMsg =
  "Unsupported file types identified. Click 'Remove documents' to continue";
export const documentFileTypeFilter =
  "Select to filter the Document Library by file type.";
export const documentsReplacedMsg =
  "Selected documents have been added to the Documents tab.";
export const permissionRestrictionTooltip = {
  permissionRestriction:
    "The file has permission restrictions and therefore information",
  extractionMessage: "will not be extracted from tables, charts and images.",
};
export const documentLibraryTabStates = {
  ShowDocuments: "ShowDocuments",
  ModifyDocuments: "ModifyDocuments",
  DuplicateDocuments: "DuplicateDocuments",
  SpecialCharsDocuments: "SpecialCharsDocuments",
  UnsupportedDocuments: "UnsupportedDocuments",
  PasswordProtectedDocuments: "PasswordProtectedDocuments",
};
export const INVALID_LINK_ERROR = "Invalid link, enter a valid link";
export const ADD_VALID_LINK_ERROR =
  "Add SharePoint, Discover or MS Forms survey link";
export const maxDocument = (<>Maximum document <br/> upload limit reached</>)
export const maxDocumentSize = (<>Maximum document size <br/> upload limit reached</>)
export const NOTIFICATION_REMOVED_MESSAGE = `Notification removed, see 'History' tab for details`;
export const NOTIFICATION_DELETED_MESSAGE = "Notification successfully deleted";
export const tabIds = {
  Active: "A",
  Draft: "D",
  History: "H",
};
export const duplicateProject = "DUPLICATE-PROJECT"