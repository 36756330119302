import React, { useState, useEffect, useContext } from "react";
import { MotifButton, MotifTooltip, MotifDropdown } from "@ey-xd/motif-react";
import { MotifDropdownItem } from "@ey-xd/motif-react/Dropdown";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import { DashboardContext } from "../../context/dashboardContext";
import styles from "./dashboard.module.scss";
import zapIcon from "../../assets/image/zap.svg";
import arrowRight from "../../assets/image/arrow-right.svg";
import community from "../../assets/image/community.svg";
import user1 from "../../assets/image/user.svg";
import documentImg from "../../assets/image/stackoverflow.svg";
import noDocumentImg from "../../assets/image/stackoverflowred.svg";
import noProject from "../../assets/image/no-project-image.png";
import { useNavigate } from "react-router-dom";
import sessiomanager from "../../utils/sessionmanager.js";
import { ClosePopup } from "./ClosePopup.js";
import moreHoriz from "../../assets/image/more-horiz.svg";
import deleteCircle from "../../assets/image/delete-circle.svg";
import ClosePopUpService from "../../services/closePopUpService.js";
import plus from "../../assets/image/plus.svg";
import LoaderComponent from "../common/LoaderComponent";

export default function ProjectList(props) {
  const projectList = props.projects;
  const isSatUser = props.isSATUser;
  const isProjectSearch = props.isProjectSearch;
  const onCreateNewProject = props.onCreateNewProject;
  const setSearchValue = props.setSearchValue;
  const { setProjectCountry, getAllprojects, getProjects } = useContext(DashboardContext);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const constantData = require("../../data/constant.js");
  const projectMasterData = require("../../data/projectMasterData.js");
  const [position, setPosition] = useState(constantData.StyleType);
  let projectId = "";
  const closePopUpService = new ClosePopUpService();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    document.body.style.overflow = position;
  }, [position]);

  /**
   * Description
   * -----------
   * This method is to open the edit menu of the project
   * Parameters:
   * index: This parameter is to access the dropdown based on the project index
   */
  const openMenuClick = (index) => {
    let menuItem = document.getElementById("dropdown" + index);
    if (menuItem) {
      menuItem.style.display = "block";
    }
  };

  /**
   * Description
   * -----------
   * This method is to close the edit menu of the project
   * Parameters:
   * index: This parameter is to access the dropdown based on the project index
   */
  const closeMenuClick = (index) => {
    let menuItem = document.getElementById("dropdown" + index);
    if (menuItem) {
      menuItem.style.display = "none";
    }
  };

  /**
   * Description
   * -----------
   * This method is show popup to user for closing project
   * Parameters:
   * isvisible: This parameter is to show close popup
   * closeprojectId: This parameter is to access the projectid that user want to close
   */
  const showPopUp = (isvisible, closeprojectId, dataHostingLocationId) => {
    setShow(isvisible);
    if (!isvisible) {
      setPosition("");
      sessiomanager.setBlankCloseProjectId();
    } else {
      sessiomanager.setCloseProjectId(closeprojectId);
      localStorage.setItem('currentDataHostingLocation', dataHostingLocationId);
      setPosition(constantData.StyleType);
    }
  };

  /**
   * Description
   * -----------
   * This method is get confirmation from user for closing project
   */
  const confirmMenuClick = async () => {
    setShowLoader(true);
    let projectId = sessionStorage.getItem("CloseProjectId");
    let dataHostingLocationId = localStorage.getItem('currentDataHostingLocation');
    if (projectId !== "") {
      let response = await closePopUpService.CloseProject(projectId, dataHostingLocationId);
      if (response.status === 200) {
        // Post closure of project load the projects again to refresh the list of projects
        await getAllprojects();
        await getProjects(sessionStorage.getItem("Email"));
      }
    }
    setShow(false);
    setPosition("");
    setShowLoader(false);
    setSearchValue("");
  };

  /**
   * Description
   * -----------
   * This method redirects to project dashboard
   * Parameters:
   * projectId: contains the project id
   * projectName: contains the project name
   */
  const onClickExplore = (project) => {
    sessionStorage.setItem("DashboardProjectId", project?.project_UUID);
    sessionStorage.setItem("DashboardProjectName", project?.projectName);
    if(project && project.country){
      setProjectCountry(project.country);
      sessionStorage.setItem("ProjectCountry", project.country);
      localStorage.setItem('currentDataHostingLocation', project?.dataHostingLocation);
      localStorage.setItem('ProjectType', project?.category);
      navigate("/ProjectDashboard/ExploreData");
    }
  };

  

  const getCountry = (project) => {
    if (project && project.country) {
      const country = projectMasterData.CountryConfig.find(country => country.id === project.country);
    if(country && country.content) {
        return country?.content;
      }else{
        return "NA";
      }     
    }
    return "NA";
  }

  return (
    <>
      {showLoader && <LoaderComponent />}
      <div className={styles.dashboard}>
        <div className={styles.mainContent}>
          <div className={styles.projectTile}>
            {projectList &&
              projectList.length > 0 &&
              projectList.map((project, index) => (
                <div className={styles.boxItem} key={index}>
                  <div className={styles.boxContent}>
                    <div className={styles.boxHeaderSubHeader}>
                      <div className={styles.boxHeader}>
                        <div className={styles.featureIcon}>
                          <img src={zapIcon} alt="projectIcon"></img>
                        </div>
                        <div className={styles.dropdownMenu}>
                          {(project.role == "Owner" ||
                            project.role == "Admin") && (
                            <MotifDropdown
                              aria-labelledby={`dropdownCount${project.projectName}`}
                              handleClickOutside={() => closeMenuClick(index)}
                              id={`dropdown` + index}
                              placement="bottom-left"
                              trigger={
                                <MotifIconButton
                                  data-testid="optionMenu"
                                  id={`dropdownButton${project.projectName}`}
                                  onClick={() => openMenuClick(index)}
                                >
                                  <img src={moreHoriz} alt="" />
                                </MotifIconButton>
                              }
                            >
                              <MotifDropdownItem
                                label="btnClose"
                                onClick={(e) => {
                                  showPopUp(
                                    true,
                                    project?.project_UUID,
                                    project?.dataHostingLocation
                                  );
                                }}
                              >
                                <img src={deleteCircle} alt="" />
                                <span className={styles.closeProjectDrpDwn}>
                                  Close Project
                                </span>
                              </MotifDropdownItem>
                            </MotifDropdown>
                          )}
                        </div>
                      </div>
                      <div className={styles.boxSubHeader}>
                        <div className={styles.boxHeaderName}>
                          <div
                            className={`${styles.boxHeaderTitle} projName${project.projectName}`}
                          >
                            {project.projectName}
                          </div>
                        </div>
                        <div
                          className={`${styles.boxDescription} desc${project.projectName}`}
                        >
                          Engagement location: {getCountry(project)}
                        </div>
                      </div>
                    </div>
                    <div className={styles.boxFooter}>
                      <div>
                        <MotifButton
                          className={`btn${project.projectName} ${styles.exploreButton}`}
                          data-testid="exploreButton"
                          onClick={() => onClickExplore(project)}
                        >
                          Explore
                          <img src={arrowRight} alt=""></img>
                        </MotifButton>
                      </div>
                      <div className={styles.boxFotterTail}>
                        {project.teamCount > 1 && (
                          <MotifTooltip
                            className={`tooltipTeamCount${project.projectName}`}
                            trigger={
                              <img
                                src={community}
                                alt={`${project.teamCount} members`}
                                className={`imgTeamCount${project.projectName}`}
                              ></img>
                            }
                          >
                            {project.teamCount} members
                          </MotifTooltip>
                        )}
                        {project.teamCount <= 1 && (
                          <MotifTooltip
                            className={`tooltipTeamCount${project.projectName}`}
                            trigger={
                              <img
                                src={user1}
                                alt="1 member"
                                className={`imgTeamCount${project.projectName}`}
                              ></img>
                            }
                          >
                            1 member
                          </MotifTooltip>
                        )}

                        {project.documentCount === 0 && (
                          <MotifTooltip
                            className={`tooltipDocCount${project.projectName}`}
                            trigger={
                              <img
                                src={noDocumentImg}
                                alt="No documents found"
                                className={`imgDocCount${project.projectName}`}
                              ></img>
                            }
                          >
                            No documents found
                          </MotifTooltip>
                        )}
                        {project.documentCount > 0 && (
                          <MotifTooltip
                            className={`tooltipDocCount${project.projectName}`}
                            trigger={
                              <img
                                src={documentImg}
                                className={`imgDocCount${project.projectName}`}
                                alt={`${project.documentCount} documents`}
                              ></img>
                            }
                          >
                            {project.documentCount} documents
                          </MotifTooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {projectList &&
            projectList.length === 0 &&
            isSatUser &&
            !isProjectSearch && (
              <div className={styles.dashboard}>
                <div className={styles.noProject}>
                  <div className={styles.noProjectDiv}>
                    <div className={styles.noProjectImage}>
                      <img src={noProject} className="no project" alt=""></img>
                    </div>
                    <div className={styles.noProjectMsgCont}>
                      <div className={styles.noProjectMsgHeader}>
                        Let’s get started
                      </div>
                      <div className={styles.noProjectMsg}>
                        Create a new project with the Digital Diligence
                        Assistant and unlock the power of reporting with AI.
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.createProjectBtn}>
                  {isSatUser && (
                    <MotifButton
                      label="btnCreate"
                      className={styles.createProject}
                      onClick={onCreateNewProject}
                    >
                      <img src={plus} alt=""></img>
                      Create Project
                    </MotifButton>
                  )}
                </div>
              </div>
            )}
          {projectList &&
            projectList.length === 0 &&

            !isSatUser &&
            !isProjectSearch && (
              <div className={styles.dashboard}>
                <div className={styles.noProject}>
                  <h2>No available project(s).</h2>
                </div>
              </div>
            )}
          {projectList &&
            projectList.length === 0 &&
            isProjectSearch && (
              <div className={styles.dashboard}>
                <div className={styles.noProject}>
                  <h2>No Projects Found!</h2>
                </div>
              </div>
            )}
        </div>
        <div>
          {show && (
            <ClosePopup
              setOpenModal={showPopUp}
              setconfirm={confirmMenuClick}
              closeprojectid={projectId}
            ></ClosePopup>
          )}
        </div>
      </div>
    </>
  );
}
