import React, { useEffect, useState } from "react";
import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import _ from "lodash";
import "./ConfirmPublishPromptModal.css";

const ConfirmPublishPromptModal = (props) => {

  const btnModalCloseClick = () => {
    props.close(false);
  };

  return (
    <MotifModal
      id="motifModalPublishPrompt"

      size="lg"
      show={true}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={btnModalCloseClick}
      className="publish-genericModal modalNoHeaderBorder modalMotifFooterModal"

    >
      <div className="ii-acknowledgement-header-div">
        <MotifModalHeader
          className="motif-modal-header"
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Close popup",
          }}
        >

          <div className="motif-modal-header-text">
            Publish to Active Prompts
          </div>

        </MotifModalHeader>

      </div>

      <MotifModalBody className="motif-modal-body">
        < >
          <span>
            Publish selected prompts to Active Prompts tab. Click on confirm to apply.
          </span>
          {props.checkedPrompts.length > 0 && (
            <ul className="modalContent"  >
              {props.checkedPrompts.map((prompt, index) => (
                <li key={index} >{prompt.platformPromptTitle}</li>
              ))}
            </ul>
          )}
        </>
      </MotifModalBody>

      <MotifModalFooter className="motif-modal-footer">
        <div className="btns-div">
          <div>
            <MotifButton
              id="btnCancel"
              variant="primary-alt"
              onClick={btnModalCloseClick}
            >
              Cancel
            </MotifButton>
          </div>
          <div>
            <MotifButton
              id="btnAcknowledge"
              onClick={props.handleConfirmPublish}
            >
              Confirm
            </MotifButton>
          </div>
        </div>
      </MotifModalFooter>
    </MotifModal>
  );
};
export default ConfirmPublishPromptModal;
