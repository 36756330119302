import React, { useState } from "react";
import PromptProjectScope from "./PromptProjectScope";
import PromptDetail from "./PromptDetail";
import "./PromptTabDetail.css";

const PromptTabDetail = (props) => {
  const [selectedNode, setSelectedNode] = useState({});
  const [treeData, setTreeData] = useState(null);
  const [selectedScopeId, setSelectedScopeId] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);

  /**
   * This method is to select the subsection item
   * @param {*} prompt
   * @param {*} tree
   */
  const selectedItem = (prompt, tree) => {
    setSelectedNode(prompt);
    setTreeData(tree);
  };

  return (
    <div className="ii-prompt-management-tab-container" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
      <PromptProjectScope updateAddScopeSectionVisibility={props.updateAddScopeSectionVisibility} setSelectedNode={selectedItem} tab={props.tab} setSelectedScopeId={setSelectedScopeId} setSelectedScope={setSelectedScope} />
      <div className="rightPortion">
        <PromptDetail
          selectedNode={selectedNode}
          scopeTreeData={treeData}
          tab={props.tab}
          selectedScopeId={selectedScopeId}
          selectedScope={selectedScope}
        />
      </div>
    </div>
  );
};

export default PromptTabDetail;
