import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    MotifBreadcrumb,
    MotifBreadcrumbItem,
    MotifSelect,
    MotifOption,
    MotifFormField,
    MotifButton,
    MotifToast,
    MotifLabel
} from "@ey-xd/motif-react";
import MotifTabNavigation, { MotifTabControl }  from "@ey-xd/motif-react/TabNavigation";
import PromptResponseAccordion from "./promptResponseAccordion";
import ProjectScopeTree from "./ProjectScopeTree";
import InProgressBar from "./InProgressBar";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import InstantInsightsService from "../../../services/instantInsightService";
import DocumentService from "../../../services/documentService";
import MarkFavResponseModel from "./MarkFavResponseModel";
import UnFavoriteConfirmationModal from "./UnFavoriteConfirmationModal";
import RefreshInsightsModal from "./RefreshInsightsModal";
import ExportInsightsModal from "./ExportInsightsModal";
import ViewSourceDocsModal from "../ExploreData/ViewSourceDocsModal";
import AcknowledgeRefreshPopup from "./AcknowledgeRefresh";
import noProject from "../../../assets/image/no-project-image.png";
import noDocumentImg from "../../../assets/image/noDocument.png";
import plus from "../../../assets/image/plus.svg";
import dashboardnav from "../../../assets/image/dashboardnav.svg";
import viewSourceDocument from "../../../assets/image/viewSourceDocument.svg";
import markAsFavourite from "../../../assets/image/markFavourite.svg";
import editFavourite from "../../../assets/image/editFavorite.svg";
import downloadInsight from "../../../assets/image/downloadinsight.svg";
import refreshInsight from "../../../assets/image/refresh-insight.svg";
import infoEmpty from "../../../assets/image/infoempty.svg";
import "./InstantInsights.css";
import "../PromptLibraries/PromptLibraries.css";
import Utils from "../../../utils/Utils";
import NoInstantGenerated from "./noInstantGenerated"
import { DashboardContext } from "../../../context/dashboardContext";
import progressBar from "../../../assets/image/ProgressBar.svg";
import NoResponseAvailable from "./ResponseNotAvailable.js";
import warningcircle from "../../../assets/image/warning-circle-new.svg";
import eye_alt from "../../../assets/image/eye_alt.svg";
import checkCircle from "../../../assets/image/check-circle.svg";
import { element } from "prop-types";

export default function InstantInsights(props) {
    const projectName = sessionStorage.getItem("DashboardProjectName");
    const hasMounted = useRef(false);
    const [hasMadeProgress, setHasMadeProgress] = useState(false);
    let [scopeData, setScopeData] = useState([]);
    const [selectedTreeNode, setSelectedTreeNode] = useState(null);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [stackOfSelectedPrompts, setStackOfSelectedPrompts] = useState([]);
    const [sourceDocumentsIsVisible, setSourceDocumentsIsVisible] = useState(false);
    const navigate = useNavigate();
    const [treeData, setTreeData] = useState(null);
    const [promptnResponsesData, setPromptsnResponses] = useState(null);
    const [sourceDocuments, setSourceDocuments] = useState(null);
    const [chunks, setChunks] = useState([]);
    const instantInsightsService = new InstantInsightsService();
    const [isPromptSelected, setIsPromptSelected] = useState(false);
    const [showRefreshInsightsModal, setShowRefreshInsightsModal] = useState(false);
    const [showExportInsightsModal, setShowExportInsightsModal] = useState(false);
    const [enableMarkSelection, setEnableMarkSelection] = useState(false);
    const [textSelections, setTextSelections] = useState([]);
    const [isPromotSelectedValidation, setIsPromotSelectedValidation] = useState(false);
    const [isTreeDataNotAvailable, setIsTreeDataNotAvailable] = useState(false);
    const [isPromotHighlightedValidation, setIsPromotHighlightedValidation] = useState(false);
    const documentService = new DocumentService();
    const [isScopeSelected, setIsScopeSelected] = useState(false);
    const [projectScopeId, setProjectScopeId] = useState();
    const [projectScopeName, setProjectScopeName] = useState();
    const [showUnFavPopup, setShowUnFavPopup] = useState(false);
    const [deleteFavoritePromptId, setDeleteFavoritePromptId] = useState(0);
    const [deletePromptHistoryId, setDeletePromptHistoryId] = useState(0);
    const [isSectionSelected, setIsSectionSelected] = useState(false);
    const [showAddFavoriteOption, setShowAddFavoriteOption] = useState(true);
    const [showEditFavoriteOption, setShowEditFavoriteOption] = useState(false);
    const [isAddMarkFavAction, setIsAddMarkFavAction] = useState(true);
    const [showAcknowledgeRefreshPopup, setShowAcknowledgeRefreshPopup] = useState(false);
    const [refreshPrompts, setRefreshPrompts] = useState(false);
    const [prompt, setPromptForAcknowledgement] = useState(null);
    const projectRole = localStorage.getItem("userRole");
    const [currentSelectedNode, setCurrentSelectedNode] = useState(0);
    const [launchedBy, setLaunchedBy] = useState("");
    const [selectedTreeNodeText, setSelectedTreeNodeText] = useState("");
    const [isRefreshInstantInsight, setIsRefreshInstantInsight] = useState(false);
    const [hasAllResponsesGenerated, setHasAllResponsesGenerated] = useState(false);
    const [scopeId, setScopeId] = useState();
    const [isFirstTimeRefreshInstantInsight, setIsFirstTimeRefreshInstantInsight] = useState(false);
    const [isSubSectionClick, setIsSubSectionClick] = useState(false);
    const [isAllResponseGenerated, setIsAllResponseGenerated] = useState(false);
    const {
        isRefreshInitiated,
        setIsRefreshInitiated,       
      } = useContext(DashboardContext);
    const utils = new Utils();
    const responseTabIds = {
        Available: "Available",
        NotAvailable: "NotAvailable",
      };
    const [isAvailableResponseSelected, setAvailableResponseSelected] = useState(true);
    const [isNotAvailableResponseSelected, setNotAvailableResponseSelected] = useState(false);
    const [selectedPromptDisplayPackage, setSelectedPromptDisplayPackage] = useState(null);

    useEffect(() => {
        LoadScopes();
        getUploadedDocuments();
    }, [props.showInsightsPage]);

    useEffect(() => {
        if (refreshPrompts) {
            handleTreeNodeClick(selectedTreeNode);
        }
    }, [refreshPrompts]);
    
    /**
   * Description
   * -----------
   * This useEffect will use to initiate the process to get the latest prompy responses.
   */
  useEffect(() => {  
    if (isRefreshInitiated) {
      let interval = setInterval(async () => {   
        let data = await instantInsightsService.getInsightTreeData(scopeId, sessionStorage.getItem("DashboardProjectId"), projectScopeId);
         setTreeData(data.scopeTreeResponse);
         if(data.refreshInstantInsightFlag.hasAllResponsesGenerated)
         {
            setHasAllResponsesGenerated(data.refreshInstantInsightFlag.hasAllResponsesGenerated);            
            setIsAllResponseGenerated(true);
           
         }  
      }, 1000 * process.env.REACT_APP_POLLING_TIME_IN_SECOND);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isRefreshInitiated]);

    // For adding beforeunload even listener and passing back method to remove it to parent
    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);
        if (props.setMethodToRemoveUnloadEventListener) {
            props.setMethodToRemoveUnloadEventListener(() => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            });
        }
    }, [hasMadeProgress])
    
    // For detecting state change and capturing progress status
    useEffect(() => {
        if (hasMounted.current) {
            updateProgressStatus(true);
        }
        else {
            hasMounted.current = true;
        }
    }, [isScopeSelected]);

    const refreshTreeData = async () => {
        let data = await instantInsightsService.getInsightTreeData(scopeId, sessionStorage.getItem("DashboardProjectId"), projectScopeId);
        setTreeData(data.scopeTreeResponse);
    }

    const handleBeforeUnload = (event) => {
        if (hasMadeProgress) {
            event.preventDefault();
            event.returnValue = ''; // Necessary for most browsers to show the confirmation dialog.
        }
    };

    const updateProgressStatus = (status) => {
        setHasMadeProgress(status);
    }

    /**
     * This method gets uploaded documets for the project and updates state.
     */
    const getUploadedDocuments = async () => {
        const uploadedDocuments = await documentService.getDocuments(
            sessionStorage.getItem("DashboardProjectId")
        );
        setUploadedDocuments(uploadedDocuments);
    }

    /**
     * Get all the scopes associated to the project
     * @returns scopes associated to the project
     */
    const LoadScopes = async () => {
        let data = await instantInsightsService.GetScopeDetailById(
            sessionStorage.getItem("DashboardProjectId")
        );
        setScopeData(data);
    };

    /**
     * Edit and No scope button redirect
     */
    const projectDetail = () => {
        props.highlightProjectDataNavigationOption();
        navigate("/ProjectDashboard/Projectdetail", { state: { showProjectDetail: true } });
    };

    const handleTreeNodeClick = async (node) => {
        // Fetch the prompts and responses only when sub-section (nodeType = 3) clicked     
        if (node && node.nodeType === 3) {
            if(node.isAllResponseGenerated)
            {
                setCurrentSelectedNode(node.parent.parent.id);
                setPromptsnResponses(null);    
                setIsSubSectionClick(true); 
                setAvailableResponseSelected(true);
                setNotAvailableResponseSelected(false);           
                instantInsightsService.getPromptsnResponsesById(node.id, sessionStorage.getItem("DashboardProjectId"),
                    node.parent.parent.id).then((data) => {
                        setSelectedTreeNode(node);
                        data.platformPromptHistoryResponse = data.platformPromptHistoryResponse.map(resp => ({
                            ...resp,
                            enableMarkSelection: false,
                            selectAllText: false,
                            sectionName: node.parent.parent.value
                        }));
                        setSelectedTreeNodeText(node.value)
                        setPromptsnResponses(data);
                        setIsPromptSelected(false);
                        setIsSectionSelected(true);
                        setRefreshPrompts(false);
                        setShowAddFavoriteOption(false);
                        setShowEditFavoriteOption(false);
                        setStackOfSelectedPrompts([]);
                        setIsRefreshInstantInsight(true);
                    });
            }
            else{
                //setSelectedTreeNode(null);
                setPromptsnResponses(null);
                setIsSectionSelected(false);
                setIsSubSectionClick(false);
            }
            
        } else {
            //setSelectedTreeNode(null);
            setPromptsnResponses(null);
            setIsSectionSelected(false);
            setIsSubSectionClick(false);
            setSelectedTreeNodeText(null)
            //alert("Hu");
        }
    }

    /**
     * On Scope selected change to fetch the Tree structure data
     */
    const onScopeChange = async (item) => {
        if (item.projectScopeId !== 0) {
            setIsScopeSelected(true);
            setProjectScopeId(item.projectScopeId);
            setProjectScopeName(item.scopeName);
            setScopeId(item.scopeId);
        }
        else {
            setIsScopeSelected(false)
        }
        let data = await instantInsightsService.getInsightTreeData(item.scopeId, sessionStorage.getItem("DashboardProjectId"), item.projectScopeId);
        setTreeData(data.scopeTreeResponse);
        //let responesFlag = await instantInsightsService.GetRefreshInstantInsightFlag(item.projectScopeId);
        setIsRefreshInstantInsight(data.refreshInstantInsightFlag.isRefreshInstantInsight);
        setHasAllResponsesGenerated(data.refreshInstantInsightFlag.hasAllResponsesGenerated)
        setIsFirstTimeRefreshInstantInsight(data.refreshInstantInsightFlag.isRefreshFirstTimeRun);
   

    }

    const convertTimestampToAMPM = (timestamp) => {
      return utils.formatDateTimeCustom(timestamp)       
    }

    const pushToStack = (prompt) => {
        setStackOfSelectedPrompts([prompt, ...stackOfSelectedPrompts])
    }

    const popOutOfStack = () => {
        const updatedStackOfSelectedPrompts = stackOfSelectedPrompts.slice(1);
        setStackOfSelectedPrompts(updatedStackOfSelectedPrompts);
    }

    /**
     * This useEffect to hide/show add/edit prompt button at top based on selected prompt
     */
    useEffect(() => {
        if (stackOfSelectedPrompts.length) {
            if (stackOfSelectedPrompts[0].favoriteContent) {
                setShowEditFavoriteOption(true);
                setShowAddFavoriteOption(false);
            } else {
                setShowEditFavoriteOption(false);
                setShowAddFavoriteOption(true);
            }
        } else {
            setShowEditFavoriteOption(false);
            setShowAddFavoriteOption(false);
        }
    }, [stackOfSelectedPrompts])


    /**
     * This method calls when any prompt accordion expands
     */
    const handlePromptSelect = async (prompt) => {
        resetFavoriteResponse();
        pushToStack(prompt);
        setSourceDocuments(prompt.sourceDocuments);
        if (prompt.sourceDocuments) {
            let vChunks = [];
            for (const element of prompt.sourceDocuments) {
                if (element.chunkData) {
                    vChunks.push(element.chunkData);
                }
            }
            setChunks(vChunks);
        }
        setSelectedPromptDisplayPackage(prompt.displayPackage);
    }

    /**
     * This method calls run Refersh instant insights 
     */
    const runInstantInsightsManually = () => {
        instantInsightsService.refreshInsightsManually(scopeId, sessionStorage.getItem("DashboardProjectId"),projectScopeId);
        setIsRefreshInstantInsight(true);
        setIsFirstTimeRefreshInstantInsight(true);
        setIsRefreshInitiated(true);
    }

    /**
    * This method calls when any prompt accordion collapse
    */
    const handlePromptUnSelect = () => {
        resetFavoriteResponse();
        popOutOfStack();
    }

    /**
    * This method calls on click of mark text as fav button
    */
    const handleSourceButtonClick = () => {
        if (stackOfSelectedPrompts.length !== 0) {
            setSourceDocumentsIsVisible(true)
        }
        else {
            alert("Please select a prompt first !")
        }
    }

    /**
     * This method calls when mark button click
     */
    const handleMarkFavButtonClick = () => {
        if (stackOfSelectedPrompts.length) {
            setIsPromotSelectedValidation(false);
            setIsAddMarkFavAction(true);
            setIsPromptSelected(true);
        }
        else {
            setIsPromotSelectedValidation(true);
        }
    }

    /**
     * This method calls when edit favorite button click
     */
    const handleEditMarkFavButtonClick = () => {
        if (stackOfSelectedPrompts.length) {
            setIsPromotSelectedValidation(false);
            setIsAddMarkFavAction(false);
            setIsPromptSelected(true);
        }
    }

    const handleDocumentButtonClick = () => {
        navigate("/ProjectDashboard/DocumentLibrary", { state: { showDocLibrary: true } });
    }

    /**
     * This method checks if current accordion is selected or not
     */
    const getHighlightStatus = (promptnResponse) => {
        if (promptnResponse && stackOfSelectedPrompts[0] && promptnResponse.platformPromptId === stackOfSelectedPrompts[0].platformPromptId) {
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * This method calls when marks for partial selection
     */
    const markPartialFavoriteResponse = () => {
        setTextSelections([]);
        updateSelectedResponse(true, false);
    }

    /**
     * This method calls when marks for entire selection
     */
    const markEntireFavoriteResponse = () => {
        setTextSelections([]);
        updateSelectedResponse(true, true);
    }


    /**
     * This method calls when marks for entire selection
     */
    const modifyFavoriteResponse = () => {
        setTextSelections([]);
        deleteSelectedFavoriteInResponse(0, null);
        setDeleteFavoritePromptId(0);
        setDeletePromptHistoryId(0);
    }

    /**
     * This method calls when marks for entire selection
     */
    const removeFavoriteResponse = () => {
        setTextSelections([]);
        deleteSelectedFavoriteInResponse(0, null);
        setDeleteFavoritePromptId(stackOfSelectedPrompts[0]?.instantInsightFavoritePromptId);
        setDeletePromptHistoryId(stackOfSelectedPrompts[0]?.instantInsightPromptHistoryId);
    }

    /**
     * This method to reset the selection
     */
    const resetFavoriteResponse = () => {
        setTextSelections([]);
        updateSelectedResponse(false, false);
        setIsPromptSelected(false);
    }


    /**
     * This method calls when cancel selection button
     */
    const cancelMarkResponse = () => {
        resetFavoriteResponse();
        deleteSelectedFavoriteInResponse(stackOfSelectedPrompts[0]?.instantInsightPromptHistoryId, stackOfSelectedPrompts[0]?.favoriteContent);
    }

    /**
     * This method to set the favorite content
     */
    const onSelectionResponseText = (selections) => {
        setTextSelections(selections);
    }

    /**
     * This method calls on click on confirm button
     */
    const onConfirmButtonClick = (instantInsightPromptHistoryId, instantInsightFavoritePromptId) => {
        if (deletePromptHistoryId && deleteFavoritePromptId) {
            markUnFavoritePromptResponse(deletePromptHistoryId, deleteFavoritePromptId);
        } else {
            updateInstantInsightFavoriteContent();
        }
    }

    /**
     * This method to save the favorite content
     */
    const updateInstantInsightFavoriteContent = async () => {
        if (textSelections.length) {
            setIsPromotHighlightedValidation(false);
            let result = await instantInsightsService.UpsertFavoritePromptContent(
                stackOfSelectedPrompts[0].instantInsightPromptHistoryId, 
                stackOfSelectedPrompts[0].instantInsightFavoritePromptId, 
                JSON.stringify(textSelections), 
                currentSelectedNode,
                selectedPromptDisplayPackage
            );
            if (result) {
                promptnResponsesData.platformPromptHistoryResponse = promptnResponsesData.platformPromptHistoryResponse.map(
                    resp => resp.instantInsightPromptHistoryId === stackOfSelectedPrompts[0].instantInsightPromptHistoryId ? { ...resp, instantInsightFavoritePromptId: result, favoriteContent: JSON.stringify(textSelections), enableMarkSelection: false } : resp);
                setPromptsnResponses(promptnResponsesData => promptnResponsesData);
                let stackOfSelectedPromptList = stackOfSelectedPrompts.map(prompt => prompt.instantInsightPromptHistoryId === stackOfSelectedPrompts[0].instantInsightPromptHistoryId ? { ...prompt, instantInsightFavoritePromptId: result, favoriteContent: JSON.stringify(textSelections) } : prompt);
                setStackOfSelectedPrompts(stackOfSelectedPromptList);
                setEnableMarkSelection(!enableMarkSelection);
                setIsPromptSelected(false);
            }
        } else {
            setIsPromotHighlightedValidation(true);
        }
    }

    /**
     * This method calls to open confirmation modal to delete the prompt
     */
    const showUnFavoriteConfirmPopUp = (show, instantInsightPromptHistoryId, instantInsightFavoritePromptId) => {
        if (show) {
            setDeletePromptHistoryId(instantInsightPromptHistoryId);
            setDeleteFavoritePromptId(instantInsightFavoritePromptId);
        } else {
            setDeletePromptHistoryId(0);
            setDeleteFavoritePromptId(0);
        }
        setShowUnFavPopup(show);
    };

    /**
     * This method calls to delete the favorite prompt from backend through api
     */
    const markUnFavoritePromptResponse = async (instantInsightPromptHistoryId, instantInsightFavoritePromptId) => {
        if (instantInsightPromptHistoryId && instantInsightFavoritePromptId) {
            let result = await instantInsightsService.deleteFavoritePrompt(instantInsightFavoritePromptId);
            if (result) {
                promptnResponsesData.platformPromptHistoryResponse = promptnResponsesData.platformPromptHistoryResponse.map(
                    resp => resp.instantInsightPromptHistoryId === instantInsightPromptHistoryId ? { ...resp, instantInsightFavoritePromptId: 0, favoriteContent: null, enableMarkSelection: false, selectAllText: false } : resp);
                setPromptsnResponses(promptnResponsesData => promptnResponsesData);
                let stackOfSelectedPromptList = stackOfSelectedPrompts.map(prompt => prompt.instantInsightPromptHistoryId === instantInsightPromptHistoryId ? { ...prompt, instantInsightFavoritePromptId: 0, favoriteContent: null } : prompt);
                setStackOfSelectedPrompts(stackOfSelectedPromptList);
                setEnableMarkSelection(!enableMarkSelection);
                setShowUnFavPopup(false);
                setIsPromptSelected(false);
                setDeleteFavoritePromptId(0);
                setDeletePromptHistoryId(0);
            }
        }
    };

    /**
    * This method to update the response array
    */
    const updateSelectedResponse = (enableSelection, enableAllSelection) => {
        promptnResponsesData.platformPromptHistoryResponse = promptnResponsesData.platformPromptHistoryResponse.map(
            resp => resp.instantInsightPromptHistoryId === stackOfSelectedPrompts[0]?.instantInsightPromptHistoryId ? { ...resp, enableMarkSelection: enableSelection, selectAllText: enableAllSelection } : resp);
        setPromptsnResponses(promptnResponsesData => promptnResponsesData);
        setEnableMarkSelection(!enableMarkSelection);
    }

    /**
    * This method to delete favorite item in response array
    */
    const deleteSelectedFavoriteInResponse = (instantInsightFavoritePromptId, favoriteContent) => {
        promptnResponsesData.platformPromptHistoryResponse = promptnResponsesData.platformPromptHistoryResponse.map(
            resp => resp.instantInsightPromptHistoryId === stackOfSelectedPrompts[0]?.instantInsightPromptHistoryId ? { ...resp, instantInsightFavoritePromptId: instantInsightFavoritePromptId, favoriteContent: favoriteContent, enableMarkSelection: false, selectAllText: false } : resp);
        setPromptsnResponses(promptnResponsesData => promptnResponsesData);
        setEnableMarkSelection(!enableMarkSelection);
    }
   /**
   * This method is to switch tha tab from Available to Not Available vice a versa.
   * @param {string} tab 
   */
    const handleResponseTabClick = (tab) => {
        switch (tab) {
           case responseTabIds.Available:
             setAvailableResponseSelected(true);
             setNotAvailableResponseSelected(false); 
                       
             break;
           case responseTabIds.NotAvailable: 
           setAvailableResponseSelected(false);
           setNotAvailableResponseSelected(true);  
           setShowAddFavoriteOption(false);         
             break;
    }
  };
 
    /**
     * This method returns jsx for right section.
     * @returns JSX Markup
     */
    const getMarkupForRightSection = () => {
        let markup = [];
            markup.push(<h3 className="heading">{selectedTreeNodeText}</h3>);
        if (promptnResponsesData) {
           
            markup.push(
                <div class="container">
                    {/* <div className={promptnResponsesData.refreshInProgress ? "left width-left" : "left"}>
                        {
                            promptnResponsesData.refreshInProgress && (
                                <>
                                    <h5 className="sub-heading">Refreshing Insights...</h5>
                                    <div className="progress-bar-div">
                                        <InProgressBar />
                                    </div>
                                </>
                            )
                        }
                        {
                            promptnResponsesData.lastRefreshed && (
                                <h5 className="sub-heading">
                                    {"Insights last refreshed: " + convertTimestampToAMPM(promptnResponsesData.lastRefreshed)}
                                </h5>
                            )
                        }
                    </div> */}
                    { promptnResponsesData.refreshInProgress && (
                        // <div className={promptnResponsesData.refreshInProgress ? "left width-left" : "left"}>
                           
                            <NoInstantGenerated></NoInstantGenerated>
                            // </div>
                    )}
                     { !promptnResponsesData.refreshInProgress && (
                            promptnResponsesData.lastRefreshed && (
                                <h5 className="sub-heading">
                                    {"Insights last refreshed: " + convertTimestampToAMPM(promptnResponsesData.lastRefreshed)}
                                </h5>
                            )
                        )}
                    {
                        !promptnResponsesData.refreshInProgress && showAddFavoriteOption && selectedTreeNode.parent.parent.value !== "Management Follow-up questions"
                        && isAvailableResponseSelected &&(
                            <div className="rightFav">
                                <button className="plain-button" onClick={() => handleMarkFavButtonClick()}><img src={markAsFavourite} alt="mark as favourite"></img></button>
                            </div>
                        )
                    }
                    {
                        !promptnResponsesData.refreshInProgress && showEditFavoriteOption && selectedTreeNode.parent.parent.value !== "Management Follow-up questions" && (
                            <div className="right">
                                <button className="plain-button" onClick={() => handleEditMarkFavButtonClick()}><img src={editFavourite} alt="edit as favourite"></img></button>
                            </div>
                        )
                    }
                     { !promptnResponsesData.refreshInProgress && showAddFavoriteOption && isAvailableResponseSelected &&(
                    <div className="right">
                         <MotifIconButton variant="secondary" id="explore-view-source-doc-icon" onClick={() => handleSourceButtonClick()}>
                              <div>
                                <span>
                                  <img src={eye_alt} alt="View Source Documents" />
                                  View Source Documents ({stackOfSelectedPrompts[0]?.sourceDocuments?.length})
                                </span>
                              </div>
                            </MotifIconButton>

                        {/* <button className="plain-button" onClick={() => handleSourceButtonClick()}><img src={viewSourceDocument} alt="view source document"></img></button> */}
                    </div>
                     )}
                </div>
            );
            if( !promptnResponsesData.refreshInProgress )
            {
                markup.push(
                    <MotifTabNavigation defaultActiveKey={0} id="responseTab">
          <MotifTabControl  id ="ResponseTab"
            onClick={() => handleResponseTabClick(responseTabIds.Available)}
          >
            Response available
          </MotifTabControl>
          <MotifTabControl  id ="ResponsenotTab"
            onClick={() => handleResponseTabClick(responseTabIds.NotAvailable)}
          >
            Response not available
          </MotifTabControl>
        </MotifTabNavigation>
                );
                if( isAvailableResponseSelected )
                    {                       
                        markup.push(
                            <div className="instant-insights-response-ai-container">
                                <img src={infoEmpty} alt='icon' />
                                <span className="instant-insights-response-ai-text">AI generated output may contain inaccuracies or errors. Verify output before use.</span>
                            </div>
                        );                        
                        const promptResponsesAvailable =promptnResponsesData.platformPromptHistoryResponse.filter(
                            (response) => (response.sourceDocuments)  
                          );
                              
                        markup.push(
                        <div class="instant-insights-height"> 
                        {
                                 promptResponsesAvailable.map((promptnResponse) => {
                                    return (
                                       
                                        <PromptResponseAccordion isHighlighted={getHighlightStatus(promptnResponse)}
                                            handlePromptSelect={handlePromptSelect}
                                            handlePromptUnSelect={handlePromptUnSelect}
                                            promptnResponse={promptnResponse}
                                            allowMarkSelection={enableMarkSelection}
                                            onSelectionText={onSelectionResponseText}
                                            markResponseUnFavorite={showUnFavoriteConfirmPopUp}
                                            setShowAcknowledgeRefreshPopup={setShowAcknowledgeRefreshPopup}
                                            setPromptForAcknowledgement={setPromptForAcknowledgement} setLaunchedBy={setLaunchedBy}></PromptResponseAccordion>
                                    )
                                })
                    }  </div>
                            
                        );
                    }
                    else{
                        markup.push(
                            <div className="instant-insights-response-ai-container">
                                <img src={warningcircle} alt='icon' />
                                <span className="instant-insights-noresponse-ai-text">No information is available in the current document corpus relevant to the prompts below.</span>
                            </div>
                        );                       
                        const promptResponsesNotAvailable =promptnResponsesData.platformPromptHistoryResponse.filter(
                            (response) => (!response.sourceDocuments)  
                          );    
                                             
                        markup.push(
                            <div class="instant-insights-height"> 
                            { promptResponsesNotAvailable.map((promptnResponse) => {   
                                //   {console.log(promptnResponse) }                             
                                return (    
                                                                
                                    <NoResponseAvailable 
                                    promptnResponse={promptnResponse}
                                    setShowAcknowledgeRefreshPopup={setShowAcknowledgeRefreshPopup}
                                    setPromptForAcknowledgement={setPromptForAcknowledgement}>   
                                    </NoResponseAvailable>
                                )
                            })}
                            </div>
                           
                        );
                    }
           
        }
        }
        return markup;
    }

    /**
     * This method is to open export modal after validating the treedata values.
     */
    const exportInstantInsightResponse = () => {
        if (treeData) {
            setShowExportInsightsModal(true);
            setIsTreeDataNotAvailable(false);
        } else {
            setIsTreeDataNotAvailable(true);
        }
    }

    /**
    * This method is to open refresh insight modal after validating the treedata values.
    */
    const refreshInstantInsightResponse = () => {
        if (treeData) {
            setShowRefreshInsightsModal(true);
            setIsTreeDataNotAvailable(false);
        } else {
            setIsTreeDataNotAvailable(true);
        }
    }

    return (
        <div className="instant-insights">
            <div className="DashProjBreadcrumb">
                <div className="DashProjBreadcrumbBase">
                    <MotifBreadcrumb
                        triggerButtonProps={{
                            "aria-label": "Breadcrumb aria-label",
                        }}
                    >
                        <MotifBreadcrumbItem>
                            <a href="/Dashboard" className="dashboard-breadcrumb">
                                Dashboard
                            </a>
                        </MotifBreadcrumbItem>
                    </MotifBreadcrumb>

                    <div className="DashProjNavArrowRight">
                        <img src={dashboardnav} alt="dashboard nav"></img>
                    </div>
                    <div className="DashProjBreadcrumbItem">
                        <div className="breadcrum-project">{projectName}</div>
                    </div>
                    <div className="DashProjNavArrowRight">
                        <img src={dashboardnav} alt="dashboard nav"></img>
                    </div>
                    <div className="DashProjBreadcrumbItem">
                        <div className="DashProjText1">Instant Insights</div>
                    </div>
                </div>
            </div>

            <div className="EditProjectEditContent">
                <div className="EditProjectEditTitle">
                    <div className="EditProjectEditTextAndSupportingText">
                        <div className="EditProjectProjectHeaderText">
                            <div className="EditProjectProjectHeaderText1">
                                Instant Insights
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(uploadedDocuments.length > 0) && isScopeSelected && isRefreshInstantInsight && !hasAllResponsesGenerated && !isSubSectionClick && (

       <NoInstantGenerated></NoInstantGenerated>

)}
            {(uploadedDocuments.length > 0) && isScopeSelected && !isRefreshInstantInsight && (

                <div className="noScopeBoard">
                    <div className="noScopes">
                        <div className="noScopeDiv">
                            <div>
                                <img src={noProject} className="no scopes" alt="no projects"></img>
                            </div>
                            <div className="no-docs">No Insights generated</div>
                            <div className="noScopeMsgCont">
                                {projectRole && (projectRole === "Viewer" || projectRole === "User (Non-SaT)") ? (
                                    <div className="no-project-scope-detail">
                                        Only members with an Admin role are able to Initiate Instant Insights
                                    </div>
                                ) : (
                                    <div className="no-instant-generated">
                                        Documents are now available. <br>
                                        </br>
                                        Please initiate Instant Insights to view results.
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {(projectRole === "Owner" || projectRole === "Admin" || projectRole === "User (SaT)") && (
                        <div className="createScope-btn">
                            <MotifButton
                                onClick={() => {
                                    runInstantInsightsManually();
                                }}

                                className="buttondiv"
                                type="button">
                                Run Instant Insights
                            </MotifButton>

                        </div>)}
                </div>
            )}
            {scopeData && scopeData.length === 0 && (
                <div className="noScopeBoard">
                    <div className="noScopes">
                        <div className="noScopeDiv">
                            <div>
                                <img src={noProject} className="no scopes" alt="no projects"></img>
                            </div>
                            <div className="noScopeMsgCont">
                                <div className="no-project-scope-title">
                                    No Project Scope Selected
                                </div>
                                {projectRole && projectRole === "Viewer" ? (
                                    <div className="no-project-scope-detail">
                                        Only Members with an Admin role can select a project scope for Instant Insights
                                    </div>
                                ) : (
                                    <div className="no-project-scope-detail">
                                        Please select the scope to view more options.
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    {(projectRole === "Owner" || projectRole === "Admin") && (
                        <div className="createScopetBtn">
                            <MotifButton
                                label="btnCreate"
                                className="createScope"
                                onClick={projectDetail}
                            >
                                <img src={plus} alt="Select Project Scope"></img>
                                Add Project Scope
                            </MotifButton>
                        </div>)}
                </div>
            )}
            {!isScopeSelected &&
                scopeData && scopeData.length > 0 && (uploadedDocuments.length > 0) && (
                    <div className="noScopeBoard">
                        <div className="noScopes">
                            <div className="noScopeDiv">
                                <div>
                                    <img src={noProject} className="no scopes" alt="no projects"></img>
                                </div>
                                <div className="noScopeMsgCont">
                                    <div className="no-project-scope-title">
                                        No Project Scope Selected
                                    </div>
                                    <div className="no-project-scope-detail">
                                        Please select the scope to view more options.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {!isSectionSelected && isScopeSelected &&
                scopeData && scopeData.length > 0 && (uploadedDocuments.length > 0) && !isRefreshInstantInsight && hasAllResponsesGenerated && (
                    <div className="noScopeBoard">
                        <div className="noScopes">
                            <div className="noScopeDiv">
                                <div>
                                    <img src={noProject} className="no scopes" alt=""></img>
                                </div>
                                <div className="noScopeMsgCont">
                                    <div className="no-project-scope-title">
                                        No Selection Made
                                    </div>
                                    <div className="no-project-scope-detail">
                                        Please make a selection from the left pane
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {!isSectionSelected && isScopeSelected &&
                scopeData && scopeData.length > 0 && (uploadedDocuments.length > 0) && isRefreshInstantInsight && hasAllResponsesGenerated && (
                    <div className="noScopeBoard">
                        <div className="noScopes">
                            <div className="noScopeDiv">
                                <div>
                                    <img src={noProject} className="no scopes" alt=""></img>
                                </div>
                                <div className="noScopeMsgCont">
                                    <div className="no-project-scope-title">
                                    Your insights are generated
                                    </div>
                                    <div className="progress-bar-div">
                                    <img src={progressBar} alt="view source document"></img>
                                    </div>
                                    <div className="no-project-scope-detail">
                                    Please make a selection from the left pane to view your insights
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div>
                {scopeData?.length > 0 && (
                    <>
                        <div className="right-section">
                            {
                                (() => {
                                    if (uploadedDocuments && uploadedDocuments.length > 0) {
                                        return (
                                            getMarkupForRightSection()
                                        )
                                    }
                                    else {
                                        return (
                                            <div className="wrapper">
                                                <img src={noDocumentImg} alt="No document"></img>
                                                <div className="TextAndSupportingText">
                                                    <div className="no-docs">No documents uploaded</div>
                                                    <div className="no-doc-text">
                                                        There are no documents uploaded to the project,<br /> to initiate Instant Insights, please upload documents.
                                                    </div>
                                                    <div className="btn-div">
                                                        <MotifButton onClick={() => handleDocumentButtonClick()}>Document Library</MotifButton>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })()
                            }
                        </div>
                        {
                            (() => {
                                if (sourceDocumentsIsVisible)
                                    return <ViewSourceDocsModal
                                        openShow={sourceDocumentsIsVisible}
                                        setOpenModal={setSourceDocumentsIsVisible}
                                        references={sourceDocuments}
                                        chunks={chunks}
                                        source={"II"}
                                    ></ViewSourceDocsModal>
                            })()
                        }
                    </>
                )}
                <div className="left-section">
                    <div className="leftscope">
                        <div className="left-scope">
                            <span className="select-project-scope-dropdown-title">Select Project Scope</span>
                            <MotifFormField>
                                {scopeData?.length === 0 ? <MotifLabel id="selectscope">No Project Scope Found</MotifLabel> : null}
                                <MotifSelect
                                    id="scopeSelect"
                                    label="selectscope"
                                    className="InputInstantInsight"
                                    ariaLabelledBy="select-label"
                                    placeholder="Select Project Scope"
                                    onChange={onScopeChange}
                                    disabled={scopeData?.length === 0 ? true : false}
                                    required
                                >
                                    {scopeData &&
                                        [...scopeData.map((item) => {
                                            return (
                                                <MotifOption
                                                    className={item.scopeName}
                                                    value={item}
                                                >
                                                    {item.scopeName}
                                                </MotifOption>
                                            );
                                        }),

                                        <MotifOption disabled={true} className="coming-soon" key="coming-soon">
                                            More coming soon...
                                        </MotifOption>]
                                    }
                                </MotifSelect>
                            </MotifFormField>
                            {scopeData?.length > 0 && <MotifIconButton onClick={() => projectDetail()}>
                                <div className="project-detail-link"  >
                                    + Add/Remove via Project Details
                                </div>
                            </MotifIconButton>}
                        </div>
                    </div>
                    <div className="tree">
                        <div className="top-content">
                            {isRefreshInstantInsight && (() => {
                                if (treeData) {
                                    return (
                                        
                                        <ProjectScopeTree treeValue={treeData.value} showLines={true} treeData={treeData} treeNodeClick={handleTreeNodeClick} showCheckbox={false} showIcons={true} isFirstTimeRefreshInstantInsight={isFirstTimeRefreshInstantInsight} setShowAcknowledgeRefreshPopup={setShowAcknowledgeRefreshPopup} setSelectedTreeNode={setSelectedTreeNode} setLaunchedBy={setLaunchedBy}></ProjectScopeTree>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                    {scopeData?.length > 0 && <div className="instant-left-button-div">
                        <MotifButton disabled = {!isRefreshInstantInsight || !hasAllResponsesGenerated} id="btnExportInsight" onClick={() => { exportInstantInsightResponse() }} className="export-button-base" type="button">
                            <img src={downloadInsight} alt="export insight icon" />
                            Export
                        </MotifButton>
                        <MotifButton disabled = {!isRefreshInstantInsight || !hasAllResponsesGenerated} id="btnRefreshInsight" onClick={() => { refreshInstantInsightResponse() }} className="refresh-button-base" type="button">
                            <img src={refreshInsight} alt="refresh insight icon" />
                            Refresh Insights
                        </MotifButton>
                    </div>}
                </div>

            </div>

            {
                showRefreshInsightsModal && (
                    <RefreshInsightsModal setShowRefreshInsightsModal={setShowRefreshInsightsModal} projectScopeId={projectScopeId} projectScopeName={projectScopeName} data={{ value: treeData.value, treeData: treeData }} setHasAllResponsesGenerated={setHasAllResponsesGenerated}></RefreshInsightsModal>
                )
            }

            {
                showExportInsightsModal && (
                    <ExportInsightsModal setShowExportInsightsModal={setShowExportInsightsModal} projectScopeId={projectScopeId} projectScopeName={projectScopeName} data={{ value: treeData.value, treeData: treeData }} />
                )
            }

            {
                showAcknowledgeRefreshPopup && (
                    <AcknowledgeRefreshPopup source="InstantInsight" setShowAcknowledgeRefreshPopup={setShowAcknowledgeRefreshPopup} setRefreshPrompts={setRefreshPrompts} prompt={prompt} projectScopeId={projectScopeId} setSelectedTreeNode={setSelectedTreeNode} refreshTreeData={refreshTreeData} launchedBy={launchedBy} selectedTreeNode={selectedTreeNode}/>
                )
            }

            {isPromptSelected && (
                <MarkFavResponseModel
                    isAddMarkFavAction={isAddMarkFavAction}
                    onClickMarkPartialResponse={markPartialFavoriteResponse}
                    onClickMarkEntireResponse={markEntireFavoriteResponse}
                    onClickModifyFavResponse={modifyFavoriteResponse}
                    onClickRemoveFavResponse={removeFavoriteResponse}
                    onCancelMarkResponse={cancelMarkResponse}
                    onConfirmSelectionText={onConfirmButtonClick}
                />
            )}
            {showUnFavPopup && (
                <UnFavoriteConfirmationModal
                    setOpenModal={showUnFavoriteConfirmPopUp}
                    setConfirm={markUnFavoritePromptResponse}
                    instantInsightFavoritePromptId={deleteFavoritePromptId}
                    promptHistoryId={deletePromptHistoryId}
                />
            )
            }
             {isRefreshInitiated && isAllResponseGenerated &&(
                <MotifToast
                    variant="success"
                    icons={{ toast: <img src={checkCircle} alt=""></img> }}
                    className="instant-toast-msg iiToast"
                    actionName=""
                    position="bottom"
                    onClose={() => {
                        setIsAllResponseGenerated(false);
                        setIsRefreshInitiated(false);                        
                    }}
                >
                    <div>Instant Insights generation is now complete</div>
                </MotifToast>
            )}

            {isPromotSelectedValidation && (
                <MotifToast
                    variant="warning"
                    actionName=""
                    position="bottom"
                    onClose={() => {
                        setIsPromotSelectedValidation(false);
                    }}
                >
                    <div>Please select a prompt first.</div>
                </MotifToast>
            )}
            {isPromotHighlightedValidation && (
                <MotifToast
                    variant="warning"
                    actionName=""
                    position="bottom"
                    onClose={() => {
                        setIsPromotHighlightedValidation(false);
                    }}
                >
                    <div>Please make a selection to mark as favorite.</div>
                </MotifToast>
            )}
            {isTreeDataNotAvailable && (
                <MotifToast
                    variant="warning"
                    actionName=""
                    position="bottom"
                    onClose={() => {
                        setIsTreeDataNotAvailable(false);
                    }}
                >
                    <div>Please select a project scope first.</div>
                </MotifToast>
            )}
        </div>

    );
}
