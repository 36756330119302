import React, { useEffect, useReducer, useState, } from "react";
import {
  MotifInput,
  MotifOption,
  MotifSelect,
  MotifTimeInput,
  MotifButton,
  MotifDatePicker,
  MotifErrorMessage,
  MotifFormField,
} from "@ey-xd/motif-react";
import moment from "moment";
import RichTextEditor from "../AdminDashboard/RichTextEditor";
import PlatformService from "../../services/platformService";

import "./CreateNotification.css";
import {
  useGetNotificationTypeQuery,
  usePublishNotificationMutation,
} from "../../reducers/notificationApi";
import { setActiveTab } from "../../reducers/notificationSlice";
import { tabIds } from "../../data/constant";
import { useDispatch } from "react-redux";

/** Initial State */
const initialState = {
  notificationTypeId: 1,
  title: "",
  editorContent: "",
  startDate: moment.utc().startOf("day").format(),
  endDate: moment.utc().startOf("day").format(),
  startTime: moment.utc().set({ second: 0 }).format(),
  endTime: moment.utc().add(1, "hour").set({ second: 0 }).format(),
  errors: {},
};

const maxTitleChars = 100;
const maxDescriptionChars = 1000;
const restrictedCharsRegex = /[`!@#$%^*+=\[\]{};':"\\|,<>?~]/;

/** Reducer */
function reducer(state, action) {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.value };

    case "SET_ERROR":
      return {
        ...state,
        errors: { ...state.errors, [action.field]: action.value },
      };

    case "SET_ALL":
      return { ...state, ...action.payload };

    case "RESET":
      return initialState;

    default:
      return state;
  }
}

const mergeDateAndTime = (date, time) => {
  const dateMoment = moment.utc(date || new Date()).startOf("day");
  const timeMoment = moment.utc(time, "HH:mm:ss");


  return dateMoment
    .hour(timeMoment.hour())
    .minute(timeMoment.minute())
    .second(0)
    .format();
};

const CreateNotification = ({ onCancel, notificationData }) => {
  const [isModified, setIsModified] = useState(false);
  const dispatchNotif = useDispatch();
  const [publishNotification] = usePublishNotificationMutation();
  const { data: notificationTypes = [], isLoading } =
    useGetNotificationTypeQuery();
  const [forceRender, setForceRender] = useState(0);
  const [titleText, setTitleText] = useState("Active");
  const [buttonText, setButtonText] = useState("Save Draft");

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (notificationData) {
      if (notificationData?.isDraft === true) {
        setTitleText("Draft")
        setButtonText("Save & Update");
      }
      const mergedStart = mergeDateAndTime(
        notificationData.startDate,
        notificationData.startTime
      );
      const mergedEnd = mergeDateAndTime(
        notificationData.endDate,
        notificationData.endTime
      );

      dispatch({
        type: "SET_ALL",
        payload: {
          notificationTypeId: notificationData.notificationTypeId || 1,
          title: notificationData.title || "",
          editorContent: notificationData.description || "",
          startDate: moment(mergedStart).startOf("day").format(),
          startTime: mergedStart,
          endDate: moment(mergedEnd).startOf("day").format(),
          endTime: mergedEnd,
        },
      });

      // motif doesn't update timepicker unless we force rerender it.
      setForceRender((prev) => prev + 1);
      setIsModified(false);
    }
  }, [notificationData]);


  const validateField = (field, rawValue, updatedState) => {
    let errorMessage = "";
    const startDate = updatedState.startDate || state.startDate;
    const startTime = updatedState.startTime || state.startTime;
    const endDate = updatedState.endDate || state.endDate;
    const endTime = updatedState.endTime || state.endTime;
    const now = moment.utc().startOf("day").format();
    const nowTime = moment.utc().format('YYYY-MM-DDTHH:mm[Z]');



    switch (field) {
      case "title":
        if (restrictedCharsRegex.test(rawValue)) {
          errorMessage = "Special characters are not allowed.";
        } else if (rawValue.length > maxTitleChars) {
          errorMessage = "Maximum allowed characters exceeded.";
        }
        break;

      case "editorContent": {
        const div = document.createElement("div");
        div.innerHTML = rawValue || "";
        const plainText = div.textContent || div.innerText || "";
        if (restrictedCharsRegex.test(plainText)) {
          errorMessage = "Special characters not allowed.";
        } else if (plainText.length > maxDescriptionChars) {
          errorMessage = "Maximum allowed characters exceeded.";
        }
        else if (plainText === "") {
          setIsModified(false)
        }
        else {
          setIsModified(true)
        }
        break;
      }
      case "startDate":
      case "endDate":
        if (
          moment(endDate).format("YYYY-MM-DD") <
          moment(startDate).format("YYYY-MM-DD")
        ) {
          errorMessage = "End Date cannot be earlier than Start Date.";
        }
        break;

      case "startTime": {
        const isSameDay = moment(startDate).isSame(moment(now), "day");
        const startTimeSelected = moment(startTime).utc().format("HH:mm:ss");
        const nowTimeSelected = moment(nowTime).utc().format("HH:mm:ss");
        if (isSameDay && (startTimeSelected) < (nowTimeSelected)) {
          errorMessage = "Start time cannot be earlier than the current time.";
        }
        break;
      }

      case "endTime": {
        const sameDay = moment(startDate).isSame(endDate, "day");
        const startTimeSelected = moment(startTime).utc().format("HH:mm:ss");
        const endTimeSelected = moment(endTime).utc().format("HH:mm:ss");
        if (sameDay && (endTimeSelected) < (startTimeSelected)) {
          errorMessage = "End Time cannot be earlier than Start Time.";
        }
        break;
      }

      default:
        break;
    }

    dispatch({ type: "SET_ERROR", field, value: errorMessage });
  };

  const handleChange = (field, value) => {
    let updatedValue;

    if (field === "startDate") {
      updatedValue = moment(value).startOf("day").set({
        hour: moment(state.startTime).hour(),
        minute: moment(state.startTime).minute(),
        second: 0,
      }).utc().format();
    } else if (field === "startTime") {
      updatedValue = moment(state.startTime).set({
        hour: moment(value).hour(),
        minute: moment(value).minute(),
        second: 0,
      }).utc().format();
    } else if (field === "endDate") {
      updatedValue = moment(value).startOf("day").set({
        hour: moment(state.endTime).hour(),
        minute: moment(state.endTime).minute(),
        second: 0,
      }).utc().format();
    } else if (field === "endTime") {
      updatedValue = moment(state.endTime).set({
        hour: moment(value).hour(),
        minute: moment(value).minute(),
        second: 0,
      }).utc().format();
    }
    else if (field === "title") {
      updatedValue = value.slice(0, maxTitleChars + 1);
    }
    else {
      updatedValue = value
    }

    dispatch({ type: "SET_FIELD", field, value: updatedValue });

    if (field === "endDate") {
      validateField("startDate", state.startDate, { ...state, [field]: updatedValue });
    }

    else if (field === "startTime") {
      validateField("endTime", state.StartTime, { ...state, [field]: updatedValue });
    }

    validateField(field, updatedValue, { ...state, [field]: updatedValue });
  };


  const handleEditorContentChange = (content) => {
    handleChange("editorContent", content);
  };



  const handlePublishOrDraft = async (mode) => {
    const hasErrors = Object.values(state.errors).some((err) => err);

    if (hasErrors) return;

    const payload = {
      notificationId: notificationData?.notificationId || null,
      notificationTypeId: state.notificationTypeId,
      title: state.title,
      description: state.editorContent,
      startDate: moment.utc(state.startDate).format("YYYY-MM-DD"),
      startTime: moment.utc(state.startTime).format("HH:mm:ss"),
      endDate: moment.utc(state.endDate).format("YYYY-MM-DD"),
      endTime: moment.utc(state.endTime).format("HH:mm:ss"),
      saveMode: mode,
    };

    try {
      const status = await publishNotification(payload);
      if (status?.data === 201) {
        dispatchNotif(setActiveTab(tabIds.Active));
        onCancel();
      }
    } catch (err) {
      console.error("Error publishing notification:", err);
    }
  };

  const hasErrors = Object.values(state.errors).some((err) => err);

  return (
    <div className="message-settings-container">
      <div className="button-container">
        <p className="Label-big">Message Settings ({titleText})</p>
        <div className="button-group">
          <MotifButton
            size="medium"
            className="button"
            variant="text"
            onClick={onCancel}
          >
            Cancel
          </MotifButton>
          <MotifButton
            className="button"
            variant="secondary"
            size="medium"
            disabled={hasErrors || !isModified}
            onClick={() => handlePublishOrDraft("D")}
          >
            {buttonText}
          </MotifButton>
          <MotifButton
            className="button"
            size="medium"
            variant="primary"
            disabled={hasErrors || !isModified}
            onClick={() => handlePublishOrDraft("P")}
          >
            Publish
          </MotifButton>
        </div>
      </div>

      <div className="divider"></div>

      <div className="form-container">
        {/* Notification Type */}
        <div className="form-group select">
          <label htmlFor="type" className="Label-input">
            Select type
          </label>

          {!isLoading && (
            <MotifSelect
              id="type"
              className="motif-select-notification"
              value={state.notificationTypeId}
              onChange={(val) => handleChange("notificationTypeId", val)}
            >
              {notificationTypes?.data?.map((option) => (
                <MotifOption
                  key={option.notificationTypeId}
                  value={option.notificationTypeId}
                >
                  {option.typeName}
                </MotifOption>
              ))}
            </MotifSelect>
          )}
        </div>

        {/* Title */}
        <div className="form-group input">
          <div className="InputWithLabel">
            <div className="Label-input">Message title (optional)</div>
            <MotifFormField className="error-box">
              <MotifInput
                value={state.title}
                className="notification-title"
                aria-label="Input"
                id="input"
                label="Input"
                onChange={(e) => handleChange("title", e.target.value)}
              />
              {state.errors.title && (
                <MotifErrorMessage>{state.errors.title}</MotifErrorMessage>
              )}
            </MotifFormField>
          </div>
        </div>

        {/* Rich Text Editor */}
        <div className="text-editor">
          <RichTextEditor
            editorContent={state.editorContent}
            setEditorContent={handleEditorContentChange}
            editorContentError={state.errors.editorContent}
          />
          {state.errors.editorContent && (
            <MotifErrorMessage>{state.errors.editorContent}</MotifErrorMessage>
          )}
        </div>

        {/* Start/End Date & Time */}
        <div className="time-container">
          {/* Start */}
          <div className="timegroup">
            <div className="form-group">
              <div className="InputWithLabel">
                <div className="Label-input">Start date</div>
                <MotifDatePicker
                  className="Date-input"
                  key={"datepicker-startdate"}
                  minDate={new Date()}
                  clearIcon={null}
                  format="dd/MM/yyyy"
                  value={state.startDate}
                  onChange={(val) => handleChange("startDate", val)}
                />
                {/* {state.errors.startDate && (
                  <MotifErrorMessage>
                    {state.errors.startDate}
                  </MotifErrorMessage>
                )} */}
              </div>
            </div>
            <div className="form-group">
              <div className="InputWithLabel">
                <div className="Label-input">Start time (UTC)</div>
                <MotifFormField className="error-box">
                  <MotifTimeInput
                    className="Time-box"
                    key={forceRender}
                    format="HH:mm"
                    value={new Date(state.startTime)}
                    onChange={(val) => handleChange("startTime", val)}
                  />
                  {state.errors.startTime && (
                    <MotifErrorMessage>
                      {state.errors.startTime}
                    </MotifErrorMessage>
                  )}
                </MotifFormField>
              </div>
            </div>
          </div>

          {/* End */}
          <div className="timegroup" >
            <div className="form-group">
              <div className="InputWithLabel">
                <div className="Label-input">End date</div>
                <MotifFormField className="error-box">
                  <MotifDatePicker
                    className="Date-input"
                    key="datepicker-enddate"
                    clearIcon={null}
                    format="dd/MM/yyyy"
                    value={state.endDate}
                    onChange={(val) => handleChange("endDate", val)}
                  />
                  {state.errors.startDate && (
                    <MotifErrorMessage>{state.errors.startDate}</MotifErrorMessage>
                  )}
                  {!state.errors.startDate && state.errors.endDate && (
                    <MotifErrorMessage>{state.errors.endDate}</MotifErrorMessage>
                  )}
                </MotifFormField>
              </div>
            </div>
            <div className="form-group">
              <div className="InputWithLabel">
                <div className="Label-input">End time (UTC)</div>
                <MotifFormField className="error-box">
                  <MotifTimeInput
                    className="Time-box"
                    key={forceRender + 1}
                    format="HH:mm"
                    value={new Date(state.endTime)}
                    onChange={(val) => handleChange("endTime", val)}
                  />
                  {state.errors.endTime && (
                    <MotifErrorMessage>
                      {state.errors.endTime}
                    </MotifErrorMessage>
                  )}
                </MotifFormField>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNotification;
