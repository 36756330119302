import React, { useContext, useEffect, useState } from "react";
import {
  MotifButton,
  MotifFormField,
  MotifLabel,
  MotifOption,
  MotifSelect,
} from "@ey-xd/motif-react";
import _ from "lodash";
import ProjectScopeTree from "../../../ProjectDashboard/InstantInsights/ProjectScopeTree";
import PlatformService from "../../../../services/platformService";
import { setSelectedScopeId } from "../../../../reducers/promptManagementSlice";
import configureSetting from "../../../../assets/image/configure-setting.svg";
import { useDispatch, useSelector } from "react-redux";

const PromptProjectScope = (props) => {
  const platformService = new PlatformService();
  const { scopeList } = useSelector(state => state.scope);
  const dispatch = useDispatch();
  const [scopeFiltered, setScopeFiltered] = useState([]);
  const [treeData, setTreeData] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedTreeNode, setSelectedTreeNode] = useState(null);

  /**
   * Filter the scope applicable to Tab while user switch between tabs
   */
  useEffect(() => {
    switch (props?.tab) {
      case "InstantInsightPrompts":
        // Filter scopes with Instant Insight prompt defined under System Prompts
        setScopeFiltered(scopeList?.filter((scope) => scope.spiiStatus === 3 || scope.spiiStatus === 4))
        break;
      case "SystemPrompts":
        setScopeFiltered(scopeList);
        break;
      case "SaTPromptLibrary":
        setScopeFiltered(scopeList);
        break;
      default:
        setScopeFiltered([]);
        break;
    }
  }, [props, scopeList])

  /**
   * This method selects the node from the project scope tree
   * @param {*} node
   */
  const handleTreeNodeClick = (node) => {
    if (props.tab === "InstantInsightPrompts") {
      props.setSelectedNode(node, treeData);
    } else {
      setSelectedItem(node.api);
      props.setSelectedNode(node, {});
    }
    props.setFetchPromptsActively(!props.fetchPromptsActively);
  };

  /**
   * On Scope selected change to fetch the Tree structure data
   */
  const onScopeChange = async (item) => {
    dispatch(
      setSelectedScopeId({
        selectedScopeId: item.scopeId
      })
    );
    props.setSelectedScopeId(item.scopeId);
    props.setSelectedScope(item);
    props.setSelectedNode(null);
    if (props.tab === "InstantInsightPrompts") {
      let treeData = await platformService.getScopeTreeData(item.scopeId);
      setTreeData(treeData);
    } else if (props.tab === "SystemPrompts") {
      let apiData = await platformService.getSystemPromptAPIList(
        item.scopeId,
        "A"
      );
      setTreeData(apiData);
      setSelectedItem("");
    }
    else {
      let SaTData = await platformService.getSaTPromptData(
        item.scopeId,
        "A"
      );
      props.setSelectedNode(SaTData);
    }
  };

  const onManageProjectScopeBtnClick = () => {
    props.updateAddScopeSectionVisibility(true);
  }

  return (
    <div className="ii-prompt-left-section">
      <div
        id="ii-prompt-project-scope"
        className="ii-prompt-project-scope-container"
      >
        <span className="ii-prompt-select-scope-label">
          Select Project Scope
        </span>
        <MotifFormField>
          {scopeFiltered?.length === 0 ? (
            <MotifLabel id="selectscope">No Project Scope Found</MotifLabel>
          ) : null}

          <MotifSelect
            id="scopeSelect"
            label="selectscope"
            data-testid="scopeSelect"
            className="InputInstantInsight"
            ariaLabelledBy="select-label"
            placeholder="Select Project Scope"
            onChange={onScopeChange}
            disabled={scopeFiltered?.length === 0}
            required
          >
            {scopeFiltered && [
              _.sortBy(
                scopeFiltered.filter((item) =>
                  props.tab === "InstantInsightPrompts" ? item.isSubSectionAdded !== 0 : true
                ), [function (o) { return o.scopeName.toLowerCase(); }])
                .map((item) => {
                  return (
                    <MotifOption
                      key={item.scopeName}
                      className={item.scopeName}
                      value={item}
                    >
                      {item.scopeName}
                    </MotifOption>
                  );
                })
            ]}
          </MotifSelect>
        </MotifFormField>
      </div>
      <div className="ii-tree-container">
        {props.tab === "InstantInsightPrompts" &&
          treeData?.value?.length > 0 && (
            <ProjectScopeTree
              treeValue={treeData.value}
              showLines={true}
              treeData={treeData}
              treeNodeClick={handleTreeNodeClick}
              showCheckbox={false}
              showIcons={false}
              isFirstTimeRefreshInstantInsight={false}
              setShowAcknowledgeRefreshPopup={null}
              setSelectedTreeNode={setSelectedTreeNode}
              setLaunchedBy={null}
            ></ProjectScopeTree>
          )}

        {props.tab === "SystemPrompts" && (
          <div className="system-prompts-category-container">
            {treeData?.length > 0 &&
              treeData?.map((item, index) => {
                return (
                  <div
                    key={index.toString()}
                    className={`system-prompts-category ${item.api === selectedItem ? "active" : ""
                      }`}
                    onClick={() => {
                      handleTreeNodeClick({ api: item.api });
                    }}
                  >
                    <div className="system-prompts-category-name">
                      {item.api === "Instant Insight" ? (item.api + "s") : item.api}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {
        (props.tab === "SystemPrompts" || props.tab === "InstantInsightPrompts") &&
        <div className="ii-left-menu-footer">
          <MotifButton className="btn-manage-scope" onClick={onManageProjectScopeBtnClick}>
            <img alt='icon' src={configureSetting} />
            {
              props.tab === "InstantInsightPrompts" ?
                <span className="span-btn-txt">Manage Structure</span> :
                <span className="span-btn-txt">Manage Project Scope</span>
            }
          </MotifButton>
        </div>
      }
    </div>
  );
};

export default PromptProjectScope;
