import React, { useEffect, useRef, useState } from 'react';
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import { MotifButton, MotifFormField, MotifLabel, MotifRichTextEditor, MotifInput, MotifTextArea } from "@ey-xd/motif-react";


export default function AddEditPromptModal(props) {
    const { onCancelBtnClick, handleConfirmClick, onPromptTxtChange, onPromptTitlechange, promptTitle, promptText, maxLengthReached, editIITestPrompt, handleBlur, errorMessageForTitle, isConfirmBtnDisabled, errorMessageForPrompt } = props;
    const promptRef = useRef(null);

    return (
        <MotifModal
            id="addTestPrompt"
            show={true}
            size="lg"
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
            onClose={onCancelBtnClick}
            className="dialog-modal"
        >
            <MotifModalHeader>
                <div className="header-title">
                    {!editIITestPrompt ? 'Add New Prompt' : 'Edit Prompt'}
                </div>
            </MotifModalHeader>
            <MotifModalBody>
                <div>
                    <MotifFormField className="title-label">
                        <MotifLabel>
                            Title
                        </MotifLabel>
                    </MotifFormField>
                </div>
                <div className="info">
                    <MotifFormField className="password-input-wrapper">
                        <MotifInput
                            label="promptTitle"
                            onChange={onPromptTitlechange}
                            type="text"
                            value={promptTitle}
                            placeholder={"Enter a title"}
                            className={`prompt-textarea ${errorMessageForTitle ? 'error-border' : ''}`}
                            onBlur={handleBlur}
                        />
                    </MotifFormField>
                </div>
                <div className="error_container">
                    {maxLengthReached && !errorMessageForTitle && (
                        <div className="max_limit">
                            Maximum limit reached - 50 characters
                        </div>
                    )}
                    {errorMessageForTitle && <div className='errorText'>
                        {errorMessageForTitle}
                    </div>}
                </div>
                <div>
                    <MotifFormField className="title-prompt">
                        <MotifLabel>
                            Prompt
                        </MotifLabel>
                    </MotifFormField>
                </div>
                <div ref={promptRef}>
                    <div className="password-input-wrapper">
                        <MotifTextArea
                            style={{ padding: '10px' }}
                            className={`prompt-textarea ${errorMessageForPrompt ? 'error-border' : ''}`}
                            label="promptText"
                            onChange={onPromptTxtChange}
                            placeholder="Enter a prompt"
                            value={promptText}
                        />
                        <div className="error_container">
                            {errorMessageForPrompt &&
                                <div className='errorText'>
                                    {errorMessageForPrompt}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </MotifModalBody>
            <MotifModalFooter>
                <div className="footer-btns-div">
                    <div>
                        <MotifButton
                            variant="secondary"
                            onClick={onCancelBtnClick}
                            className="cancel-btn"
                        >
                            Cancel
                        </MotifButton>
                    </div>
                    <div>
                        <MotifButton
                            className="confirm-btn"
                            onClick={handleConfirmClick}
                            disabled={isConfirmBtnDisabled}
                        >
                            Confirm
                        </MotifButton>
                    </div>
                </div>
            </MotifModalFooter>
        </MotifModal>
    )
}
