import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../../context/dashboardContext";
import { paansConfig } from "../../config/paansConfig";
import { MotifButton, } from "@ey-xd/motif-react";
import styles from "../dashboard/dashboard.module.scss";
import gridMinus from "../../assets/image/grid-minus.svg";
import DashSuperAdmin from "../AdminDashboard/dashSuperAdmin";
import { useMsal } from '@azure/msal-react';
export function DashboardPlatformAdmin(props) {
  const navigate = useNavigate();
  const [isPaansAccepted, setPaansAccepted] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  let { paans } = useContext(DashboardContext);
  const { instance } = useMsal();

  useEffect(() => {
    if (Object.keys(paans).length && !paans.hasAccepted) {
      const urlPaans = paansConfig.PAANSEndpoint + paans.redirectUrl;
      window.location.href = urlPaans;
    } else if (Object.keys(paans).length && paans.hasAccepted) {
      setPaansAccepted(true);
    }
  }); 

  const handleClickProjDashboard = () => {
    navigate("/Dashboard");
  };

  /**
   * Description
   * -----------
   * This method shows the username on avatar image
   */
  const showLoggedInUserMenu = () => {
    setShowUserMenu(true);
    document.body.addEventListener("click", hideLoggedInUserMenu);
  };

  /**
  * Description
  * -----------
  * This method hides the username on avatar image
  */
  const hideLoggedInUserMenu = () => {
    setShowUserMenu(false);
  };

  /**
  * Description
  * -----------
  * This method logout from Azure Ad and navigate to logout page
  */
  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: "/LogOut",
    };
    instance.logout(logoutRequest);
  };

  return (
    isPaansAccepted && (
      <div className={styles.container}>        
        <div className={styles.admindashboard}>
          <div className={styles.adminpageHeader}>
            <div className={styles.headerContent}>
              <div className={styles.headerTitle}>
                <div className={styles.headerText}>
                  <div className={styles.headerTextDiv}>
                    <div className={styles.headerValue}>Admin Dashboard</div>
                  </div>
                </div>
              </div>
              <div className={styles.headerAction}>
                <MotifButton
                  className={styles.switchToProjectDashboard}
                  onClick={handleClickProjDashboard}
                >
                  <img src={gridMinus} alt=""></img>
                  <span className={styles.txtSwitchProjDash}>
                    Switch to Project Dashboard
                  </span>
                </MotifButton>
              </div>
            </div>
          </div>
          </div>
        <div>
          <DashSuperAdmin></DashSuperAdmin>
        </div>               
      </div>
    )
  );
}

export default DashboardPlatformAdmin;
