import { React, useContext, useState, useEffect } from "react";
import { MotifDropdown, MotifTooltip, MotifToast,MotifSelect,MotifOption, MotifFormField,MotifLabel} from "@ey-xd/motif-react";
import { MotifDropdownItem } from "@ey-xd/motif-react/Dropdown";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import editIcon from "../../../../assets/image/editResponse_light.svg";
import brokenIcon from "../../../../assets/image/no-link.svg";
import optionIcon from "../../../../assets/image/more-horiz.svg";
import moreHoriz from "../../../../assets/image/more-horiz.svg";
import refreshIcon from "../../../../assets/image/refresh-double.svg";
import { DashboardContext } from "../../../../context/dashboardContext";
import FavoriteDeleteModal from "./FavoriteDeleteModal";
import AcknowledgeRefreshPopup from "../../InstantInsights/AcknowledgeRefresh";
import FavouritePopUp from "./../../ExploreData/FavouritePopUp";
import WorkSpaceService from "../../../../services/workspaceService";
import icon_arrowdown from "../../../../assets/image/Tag_arrow.svg";
import icon_broken from "../../../../assets/image/brokenlink.svg";
import toastinfo from "../../../../assets/image/toastinfo.svg";

import edittitle from "../../../../assets/image/edit-title.svg";
import deletefavorite from "../../../../assets/image/delete.favorite.svg";

const FavoriteList = (props) => {
  const { selectedPWFavoritePrompt, setSelectedPWFavoritePrompt } = useContext(DashboardContext);
  const Prompt = require("../../../../data/model/prompt.js");
  const [showAcknowledgeRefreshPopup, setShowAcknowledgeRefreshPopup] =
    useState(false);
  const [acknowledgePrompt, setAcknowledgePrompt] = useState(null);
  const [refreshFavoritePrompts, setRefreshFavoritePrompts] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [promptResponse, setPromptResponse] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let PromptData = Prompt.PromptData;
  const constantData = require("../../../../data/constant.js");
  const [favPosition, setFavPosition] = useState(constantData.StyleType);
  const [showFavoritePopup, setShowFavoritePopup] = useState(false);
  const workspaceService = new WorkSpaceService();
  const [prevMenuIndex, setPrevMenuIndex] = useState(null);
  const [prevTagIndex, setPrevTagIndex] = useState(null);

  useEffect(() => {
    if (refreshFavoritePrompts || showDeletePrompt) {
      props.refreshFavoriteResponses();
    }
  }, [refreshFavoritePrompts, showDeletePrompt]);

  useEffect(() => {
    document.body.style.overflow = favPosition;
  }, [favPosition]);

  /**
   * This method is to open the option menu of the favorite response items
   * @param {number} index
   */

  const openOptionClick = (index) => {
    closeMenuClick(prevMenuIndex);
    closeTagMenuClick(prevTagIndex);
    let menuItem = document.getElementById("dropdown" + index);
    if (menuItem) {
      menuItem.style.display = "block";
    }
    let menuItemdrop = document.getElementById("dropdownTag" + index);
    if (menuItemdrop) {
      menuItemdrop.style.display = "none";
    }
    setPrevMenuIndex(index);

    
  };

  /**
   * This method is to close the option menu of the favorite response items
   * @param {number} index
   */
  const closeMenuClick = (index) => {
    let menuItem = document.getElementById("dropdown" + index);
    if (menuItem) {
      menuItem.style.display = "none";
    }
    
  };
 
  /**
   * Description
   * -----------
   * This method is used to delete the prompts from the history.
   * Parameters:
   * event: button click event object.
   * promptId: prompt id which needs to delete.
   */
  const deletePrompt = async (prompt) => {
    setPromptResponse(prompt);
    setShowDeleteModal(true);
  };

  /**
   * This method to open the acknowledge refresh instant insight modal
   * @param {number} id
   */
  const openAcknowledgeRefreshPopup = (id) => {
    setAcknowledgePrompt({ instantInsightPromptHistoryId: id });
    setShowAcknowledgeRefreshPopup(true);
  };

  const openOptionTagClick = (index) => {
    closeTagMenuClick(prevTagIndex);
    closeMenuClick(prevMenuIndex);
    let menuItem = document.getElementById("dropdownTag" + index);
    if (menuItem) {
      if(menuItem.style.display === 'none') {
        menuItem.style.display = "block";
      } else {
        menuItem.style.display = "none";
      }
    }
    let menuItemdrop = document.getElementById("dropdown" + index);
    if(menuItemdrop) {
      menuItemdrop.style.display = "none";
    } 
    setPrevTagIndex(index);
  };

  const closeTagMenuClick = (index) => {   
    let menuItem = document.getElementById("dropdownTag" + index);
    if (menuItem) {
      menuItem.style.display = "none";
    }   
  };
  /**
   * Description
   * -----------
   * This method is used to initialize all the parameters of promptdata.
   */
  const initializePrompt = () => {
    PromptData.convid = "";
    PromptData.promptid = "";
    PromptData.project_uuid = "";
    PromptData.prompttype = "";
    PromptData.title = "";
    PromptData.tags = "";
    PromptData.prompttext = "";
    PromptData.IsFavOrSaved = "";
    PromptData.Email = "";
  };

  const showFavPopUp = (isvisible) => {
    setShowFavoritePopup(isvisible);
    if (!isvisible) {
      setFavPosition("");
    } else {
      setFavPosition(constantData.StyleType);
    }
  };
  /**
   * Description
   * -----------
   * This method is used to edit the title & tags of prompts from the history.
   * Parameters:
   * event: button click event object.
   * promptId: prompt id which needs to delete.
   */
  const editPrompt = async (prompt) => {
    initializePrompt();
    PromptData.title = prompt.title;
    PromptData.tags = prompt.tags;
    PromptData.promptid = prompt.id;
    PromptData.prompttype = prompt.type;
    PromptData.project_uuid = sessionStorage.getItem("DashboardProjectId");
    showFavPopUp(true);
  };


  function formatDate(apiDate) {
    const date = new Date(apiDate);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23'
    };
    const dateString = date.toLocaleDateString('en-US', options);
    const formattedDate = dateString.replace(/(\d{4}),/, '$1 ') + ' (UTC)';
    return formattedDate;
}

  /**
   * Description
   * -----------
   * This method is update the favorite title and tags on confirm click
   * Parameters:
   * event: button click event object.
   * PromptData: it holds the data related title and tag.
   */
  async function onFavPopupConfirmClick(promptData) {
    if (PromptData != null) {
      const response = await workspaceService.editWorkspaceFavoritePrompts(
        promptData
      );
      if (response === 200) {
        props.refreshFavoriteResponses();
      }
    }
  }

  return (
    <div className="pw-favorites-container">
      <div className="pw-favorite-list-container">
        {props.favoriteList &&
          props.favoriteList.length > 0 &&
          props.favoriteList.map((favoriteResponse, index) => {
            const formattedDate = formatDate(favoriteResponse.createTS);
            return (
            <div key={`key${index}`} className="pw-favorite-box">
              <div
                onClick={() => setSelectedPWFavoritePrompt(favoriteResponse)}
                className={(selectedPWFavoritePrompt?.id === favoriteResponse?.id) ? "pw-favorite-upper-box-active" : "pw-favorite-upper-box"}
              >
                <div className="pw-favorite-upper-box-title" id="favorite-box">
                <span className={favoriteResponse.type === 'P' ?"closeProjectDrpDwnPublic" :"closeProjectDrpDwnConfidential"}> 
                {favoriteResponse.type === 'P' ? "Public" : "Confidential"}
                  </span>
                  <MotifDropdown
                    aria-labelledby="dropdown-trigger-1"
                    handleClickOutside={() => closeMenuClick(index)}
                    id={`dropdown` + index}
                    trigger={
                      <MotifIconButton
                        data-testid="optionMenu"
                        id={`dropdownButton${favoriteResponse.title}`}
                        onClick={($event) => {
                          $event.stopPropagation();
                          openOptionClick(index);
                        }}
                      >
                        <img src={moreHoriz} className="actionborder" alt="" />
                      </MotifIconButton>
                    }
                  >
                    <MotifDropdownItem
                      onClick={() => editPrompt(favoriteResponse)}
                    >
                      <img className="favimage" src={edittitle} alt="" /> 
                         <span className="closeProjectDrpDwn">Edit Title and Tags</span>
                      
                    </MotifDropdownItem>
                    <MotifDropdownItem
                      onClick={() => deletePrompt(favoriteResponse)}
                    >
                      <img className="favimage" src={deletefavorite} alt="" />
                      <span className="closeProjectDrpDwn">Remove from Favorites</span>
                    </MotifDropdownItem>
                  </MotifDropdown>
                </div>
               <div className="favorite-title-content">
                     <span className="title-content">{favoriteResponse.title}</span> 
                </div>
                
                <div className="divider-favorites" >
                <hr className="favorites-divider"/>
                            <div className="datewithlink">
                              <span className="date">{formattedDate}</span>
                      {favoriteResponse.isBroken === "Y" &&
                        <MotifTooltip
                        placement="bottom"
                        hideCloseButton={true}
                          trigger={<img src={icon_broken} alt="" />}
                        >
                          Document deleted
                        </MotifTooltip>
                      }

                    </div>
                  </div>
                {/* <div className="pw-favorite-action-box">
                  <div className="pw-favorite-action-group">
                    <div className="view-tags-box"> */}
                      {/* <MotifFormField>
                        <MotifLabel id="select-label">View Tags</MotifLabel>
                        <MotifSelect
                          className="view-tags-select"
                          ariaLabelledBy="select-label"
                          data-testid="selecttargetsector"
                          visibleOptions={5}
                          onClick={($event) => {$event.stopPropagation();}}
                        >
                          {favoriteResponse.tags?.split(",").map((item) => {
                            return (
                              <MotifOption
                                value={item.toString()}
                                key={item.toString()}
                              >
                                {item.toString()}
                              </MotifOption>
                            );
                          })}
                        </MotifSelect>
                      </MotifFormField> */}
                      {/* {favoriteResponse.tags.length > 0 ? (
                        <div className="tagContainer">
                          <MotifDropdown
                            className="view-tags-select"
                            placement="bottom-start"
                            aria-labelledby="dropdown-trigger-1"
                            handleClickOutside={() => closeTagMenuClick(index)}
                            id={`dropdownTag` + index}
                            trigger={
                              <MotifIconButton
                                data-testid="optionMenu"
                                id={`dropdownButton${favoriteResponse.tags}`}
                                onClick={($event) => {
                                  $event.stopPropagation();
                                  openOptionTagClick(index);
                                }}
                              >
                                <div>
                                  <div>
                                    <span className="tagContainerText">
                                      {" "}
                                      <span className="closeProjectDrpDwn">
                                      View Tags
                                      </span>                                    
                                    </span>
                                    &nbsp;
                                    <img src={icon_arrowdown} alt="" />
                                  </div>
                                </div>
                              </MotifIconButton>
                            }
                          >
                            {favoriteResponse.tags?.split(",").map((item) => {
                              return (
                                <div onClick={(e) => e.stopPropagation()}>
                                  <MotifDropdownItem
                                    onClick={function noRefCheck() {}}
                                    value={item.toString()}
                                    key={item.toString()}
                                  >
                                    {item.toString()}
                                  </MotifDropdownItem>
                                </div>
                              );
                            })}
                          </MotifDropdown>
                        </div>
                      ) : (
                        <div className="notagsContainer">
                          <span className="closeProjectDrpDwn">
                            No tags added
                          </span>
                        </div>
                      )}
                    </div>
                    {favoriteResponse.type === "II" &&
                      !favoriteResponse.isRefreshAcknowledged && (
                        <div>
                          <MotifIconButton
                            data-testid="refreshII"
                            onClick={() => {
                              openAcknowledgeRefreshPopup(favoriteResponse.id);
                            }}
                          >
                            <img
                              src={refreshIcon}
                              className="refresh-icon"
                              alt="refresh instant insight"
                            />
                          </MotifIconButton>
                        </div>
                      )}
                    {favoriteResponse.isEdited === "Y" && (
                      <div>
                        <MotifTooltip
                          className={`tooltipEdit${favoriteResponse.projectName}`}
                          trigger={<img src={editIcon} alt="" />}
                        >
                          Edited
                        </MotifTooltip>
                      </div>
                    )}

                    {favoriteResponse.isBroken === "Y" && (
                      <div>
                        <MotifTooltip
                          className={`tooltipBroken${favoriteResponse.projectName}`}
                          trigger={<img src={brokenIcon} alt="" />}
                        >
                          Document(s) missing
                        </MotifTooltip>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
            )
          })}
      </div>
      {showFavoritePopup && (
        <FavouritePopUp
          setOpenModal={showFavPopUp}
          promptdata={PromptData}
          setconfirm={onFavPopupConfirmClick}
          isExplore={false}
        ></FavouritePopUp>
      )}
      {showDeleteModal && (
        <FavoriteDeleteModal
          favoriteResponse={promptResponse}
          setShowDeleteModal={setShowDeleteModal}
          setShowDeletePrompt={setShowDeletePrompt}
        ></FavoriteDeleteModal>
      )}
      {showDeletePrompt && (
        <MotifToast
        className="favdeletetoast"
          variant="info"
          icons={{ toast: <img src={toastinfo} className="toastimg" alt="" /> }}
          actionName=""
          position="bottom"
          onClose={() => {
            setShowDeletePrompt(false);
          }}
        >
          <div className="toastcontent">
          Favorite has been removed successfully  
          </div>
        </MotifToast>
      )}
      {showAcknowledgeRefreshPopup && (
        <AcknowledgeRefreshPopup
          source="ProjectWorkspace"
          setShowAcknowledgeRefreshPopup={setShowAcknowledgeRefreshPopup}
          setRefreshPrompts={setRefreshFavoritePrompts}
          prompt={acknowledgePrompt}
        />
      )}
    </div>
  );
};
export default FavoriteList;
