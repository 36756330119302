import React, { useState } from "react";
import { MotifButton, MotifTooltip } from "@ey-xd/motif-react";
import { documentLibraryTabStates } from "../../../data/constant";
import PropTypes from "prop-types";

export function DocumentUploaderFooter(props) {
  const [isDocumentRemoved, setIsDocumentRemoved] = useState(false);
  const removeDuplicateDocs = () => {
    if (props.selectedDocuments?.length < props.duplicateDocuments.length) {
      props.removeSelectedDocuments();
      setIsDocumentRemoved(true);
    } else {
      props.setClearDuplicateDocuments(true);
    }
  };
  return (
    <div className="document-uploader-footer">
      {props.activeTabStates === documentLibraryTabStates.ShowDocuments &&
        (
          <div className="document-upload-left-flex">
            <MotifTooltip
              trigger={
                <MotifButton
                  label="btnModify"
                  onClick={props.allowDocumentTableSelection}
                  size="medium"
                  type="button"
                  variant="secondary"
                  disabled={!props.isAllDocumentsValid}
                >
                  Modify selection
                </MotifButton>
              }
              hide={props.isAllDocumentsValid}
            >
              Remove the error documents to continue
            </MotifTooltip>
          </div>
        )}

      {props.activeTabStates === documentLibraryTabStates.DuplicateDocuments &&
         (
          <div className="document-upload-left-flex">
            {props.selectedDocuments?.length <= 0 && (
              <MotifTooltip
                trigger={
                  <MotifButton
                    label="btnModify"
                    size="medium"
                    type="button"
                    variant="secondary"
                    disabled={props.selectedDocuments.length <= 0}
                  >
                    Remove documents
                  </MotifButton>
                }
              >
                Select documents to continue
              </MotifTooltip>
            )}

            {props.selectedDocuments?.length > 0 && (
              <MotifTooltip
                trigger={
                  <MotifButton
                    label="btnModify"
                    size="medium"
                    type="button"
                    variant="secondary"
                    onClick={removeDuplicateDocs}
                    className="doc-upload-remove-documents-button"
                  >
                    Remove documents
                  </MotifButton>
                }
              >
                Click to remove selected documents
              </MotifTooltip>
            )}
          </div>
        )}

      {[
        documentLibraryTabStates.ShowDocuments,
        documentLibraryTabStates.SpecialCharsDocuments,
        documentLibraryTabStates.UnsupportedDocuments,
        documentLibraryTabStates.PasswordProtectedDocuments,
      ].includes(props.activeTabStates) && (
        <>
          {props.activeTabStates !== documentLibraryTabStates.ShowDocuments &&
            props.activeTabStates !==
              documentLibraryTabStates.PasswordProtectedDocuments && (
              <div className="document-upload-left-flex">
                <MotifButton
                  label="btnRemoveAll"
                  size="medium"
                  type="button"
                  variant="secondary"
                  className="doc-upload-remove-documents-button"
                  onClick={() => {
                    if (
                      props.activeTabStates ===
                      documentLibraryTabStates.SpecialCharsDocuments
                    ) {
                      props.setClearSpecialCharsDocuments(true);
                    }
                    if (
                      props.activeTabStates ===
                      documentLibraryTabStates.UnsupportedDocuments
                    ) {
                      props.setClearUnsupportedDocuments(true);
                    }
                  }}
                >
                  Remove documents
                </MotifButton>
              </div>
            )}

          <div className="document-upload-primary-buttons">
            <MotifButton
              label="btnModify"
              size="medium"
              type="button"
              variant="secondary"
              onClick={() => {
                props.onCancelDocumentClick(true);
              }}
            >
              Cancel
            </MotifButton>
           
              <MotifTooltip
                trigger={
                  <MotifButton
                    size="large"
                    type="button"
                    disabled={ 
                      props.isUploadClicked ||
                      props.documents.length <= 0 ||
                      !props.isAllDocumentsValid ||
                      props.isDocumentCountExceeds ||
                      props.isDocumentSizeExceeds
                    }
                    onClick={props.onSubmitValidDocuments}
                  >
                    Confirm
                  </MotifButton>
                }
                hide={props.isAllDocumentsValid}
              >
                Remove the error documents to continue
              </MotifTooltip>
            
          </div>
        </>
      )}

      {props.activeTabStates === documentLibraryTabStates.ModifyDocuments && (
        <div className="document-upload-left-flex">
          <span className="modify-selection-text">Modify selection</span>
          {props.selectedDocuments?.length <= 0 && (
            <MotifTooltip
              trigger={
                <MotifButton
                  label="btnModify"
                  size="medium"
                  type="button"
                  variant="secondary"
                  disabled={props.selectedDocuments.length <= 0}
                >
                  Remove documents
                </MotifButton>
              }
            >
              Select at least one document to continue
            </MotifTooltip>
          )}

          {props.selectedDocuments?.length > 0 && (
            <MotifTooltip
              trigger={
                <MotifButton
                  label="btnModify"
                  size="medium"
                  type="button"
                  variant="secondary"
                  onClick={props.removeSelectedDocuments}
                  className="doc-upload-remove-documents-button"
                >
                  Remove documents
                </MotifButton>
              }
            >
              Click to remove selected documents
            </MotifTooltip>
          )}

          <MotifButton
            label="btnModify"
            size="medium"
            type="button"
            variant="secondary"
            onClick={props.removeDocumentSelection}
          >
            Back
          </MotifButton>
        </div>
      )}

      {props.activeTabStates ===
        documentLibraryTabStates.DuplicateDocuments && (
        <div className="document-upload-primary-buttons">
          {props.selectedDocuments?.length <= 0 && (
            <MotifTooltip
              trigger={
                <MotifButton
                  label="btnModify"
                  size="medium"
                  type="button"
                  variant="secondary"
                  disabled={props.selectedDocuments.length <= 0}
                >
                  Keep a copy
                </MotifButton>
              }
            >
              Select documents to continue
            </MotifTooltip>
          )}

              {props.selectedDocuments?.length > 0 && (
                <MotifTooltip
                allowClick={false}
                  trigger={
                    <MotifButton
                      label="btnModify"
                      size="medium"
                      type="button"
                      variant="secondary"
                      onClick={() => {
                        props.onKeepACopyDocumentClick(true);
                      }}
                    >
                      Keep a copy
                    </MotifButton>
                  }
                >
                  Click to keep a copy of selected documents
                </MotifTooltip>
              )}
          {props.selectedDocuments?.length <= 0 && (
            <MotifTooltip
              trigger={
                <MotifButton
                  label="btnModify"
                  size="medium"
                  type="button"
                  variant="primary"
                  disabled={props.selectedDocuments.length <= 0}
                >
                  Replace
                </MotifButton>
              }
            >
              Select documents to continue
            </MotifTooltip>
          )}

              {props.selectedDocuments?.length > 0 && (
                <MotifTooltip
                allowClick={false}
                  trigger={
                    <MotifButton
                      label="btnModify"
                      size="medium"
                      type="button"
                      variant="primary"
                      onClick={() => {
                        props.onReplaceDocumentClick(true);
                      }}
                    >
                      Replace
                    </MotifButton>
                  }
                >
                  Click to replace selected documents
                </MotifTooltip>
              )}
              
        </div>
      )}
    </div>
  );
}
export default DocumentUploaderFooter;

DocumentUploaderFooter.propTypes = {
  activeTabStates: PropTypes.string,
  documents: PropTypes.array,
  selectedDocuments: PropTypes.array,
  isAllDocumentsValid: PropTypes.bool,
  isDocumentSizeExceeds: PropTypes.bool,
  isDocumentCountExceeds: PropTypes.bool,
  onSubmitValidDocuments: PropTypes.func,
  allowDocumentTableSelection: PropTypes.func,
  removeSelectedDocuments: PropTypes.func,
  removeDocumentSelection: PropTypes.func,
  setClearSpecialCharsDocuments: PropTypes.func,
  setClearUnsupportedDocuments: PropTypes.func,
  setClearDuplicateDocuments: PropTypes.func,
  onCloseUploadModal: PropTypes.func,
  isUploadClicked: PropTypes.bool
};
