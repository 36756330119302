import { useEffect, useState, useContext } from "react";

import "./AddDocument.css";
import _ from "lodash";
import { MotifButton, MotifTooltip, MotifToast } from "@ey-xd/motif-react";
import UploadModal from "./UploadModal";
import FilesUploadedGrid from "./UploadedFilesGrid";
import { groupConfig } from "../../../config/groupConfig";
import upload from "../../../assets/image/upload.svg";
import DocumentService from "../../../services/documentService";
import { DashboardContext } from "../../../context/dashboardContext";
import DocumentUploader from "../../common/DocumentUploader/DocumentUploader";
import CommonComponent from "../../common/CommonComponent";

export function AddDocument({ classcheck, isDocumentLibrary }) {
  const documentservice = new DocumentService();
  const {
    setIsDocumentUploadProcessInitiated,
    isDocumentDeleteProcessInitiated,
    setIsDocumentDeleteProcessInitiated,
    setIsLocalUploadInitiated,
    setIsSharepointUploadInitiated,
    documentList,
    setShowDocumentUploadMessage,
    documentUploadSubmitted,
    setDocumentUploadSubmitted,
  } = useContext(DashboardContext);
  const [isSATUser, setSATUser] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileCountLimit, setFileCountLimit] = useState(0);
  const [uploadDisable, setuploadDisable] = useState(false);
  const [fielsCount, setFilesCount] = useState({});
  const [fileFileLimitSize, setFileLimitSize] = useState(0);
  const [documentStates, setDocumentStates] = useState({});
  const [showUploadedModal, setShowUploadedModal] = useState(false);
  const [uploadType, setUploadType] = useState("");
  const [uploadDetails, setUploadDetails] = useState(null);
  const [refreshDocumentGrid, setRefreshDocumentGrid] = useState(false);

  const [showDeletedComplete, setshowDeletedComplete] = useState(false);
  const [documentName, setdocumentName] = useState("");
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [showErrorWhileLocalUpload, setShowErrorWhileLocalUpload] =
    useState(false);
  const [uploadedDocumentList, setUploadedDocumentList] = useState([]);
  let InititaData = [];

  /**
   * Description
   * -----------
   * This method used to file validation details sending to uploadModal component
   * Parameters:
   * filesCount: allowed file count limit.
   * totalFilesSize: allowed total file size limit.
   */
  function getFileLimits(filesCount, totalFilesSize, data, count) {
    const activeData = data.filter(item => item.status !== "Deleted" && data.status !== "Error");
    setUploadedDocumentList(activeData);
    getDocumentSettingsDetails(filesCount, totalFilesSize, data, count);
  }

  /**
   * Description
   * -----------
   * This useEffect will check if current user type
   */
  useEffect(() => {
    setSATUser(localStorage.getItem("groupName") == groupConfig.groupNameSat);
  }, []);

  // /**
  //  * Description
  //  * -----------
  //  * This useEffect will update the document list if there is status change of uploaded documents.
  //  */
  useEffect(() => {
    if (documentList?.length) {
      setUploadedDocuments(documentList);
    }
  }, [documentList]);

  /**
   * Description
   * -----------
   * This useEffect will use to initiate the process to check the status of the recently deleted document.
   */
  useEffect(() => {
    if (isDocumentDeleteProcessInitiated) {
      let interval = setInterval(async () => {
        const uploadDocuments = await documentservice.getDocuments(
          sessionStorage.getItem("DashboardProjectId")
        );
        if (uploadDocuments.length > 0) {
          setUploadedDocuments(uploadDocuments);
          const deletedCompletedDocs = _.filter(
            uploadDocuments,
            function (item) {
              return item.status === "Deleted";
            }
          );
          if (deletedCompletedDocs.length > 0) {
            deletedCompletedDocs.sort(function (a, b) {
              return new Date(b.modifiedDate) - new Date(a.modifiedDate);
            });
            setdocumentName(deletedCompletedDocs[0].documentName);
            setshowDeletedComplete(true);

            setIsDocumentDeleteProcessInitiated(false);
            clearInterval(interval);
          }
        }
      }, 1000 * process.env.REACT_APP_POLLING_TIME_IN_SECOND);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isDocumentDeleteProcessInitiated]);

  /**
   * Description
   * -----------
   * This method will be called when local document upload initiates.
   * Parameters:
   * isUploaded: check local file upload process initiated or not.
   */
  const localFileUploadSubmitted = (isUploaded) => {
    if (isUploaded) {
      setIsLocalUploadInitiated(true);
      setIsSharepointUploadInitiated(false);
      setIsDocumentUploadProcessInitiated(true);
      setDocumentUploadSubmitted(!documentUploadSubmitted);
      setShowDocumentUploadMessage(false);
      setRefreshDocumentGrid(!refreshDocumentGrid);
      setShow(!show);
    } else {
      setShowErrorWhileLocalUpload(true);
    }
  };

  /**
   * Description
   * -----------
   * This method will be called when delete document initiates.
   * Parameters:
   * isDeleted: check delete file process initiated or not.
   */
  const deleteSubmitted = (isDeleted) => {
    if (isDeleted) {
      setIsDocumentDeleteProcessInitiated(true);
    }
  };

  /**
   * Description
   * -----------
   * This method will be called when sharepoint document upload initiates.
   * Parameters:
   * isUploaded: check sharepoint file upload process initiated or not.
   */
  const sharePointFileUploadSubmitted = (isUploaded) => {
    if (isUploaded) {
      setIsSharepointUploadInitiated(true);
      setIsLocalUploadInitiated(false);
      setIsDocumentUploadProcessInitiated(true);
      setDocumentUploadSubmitted(!documentUploadSubmitted);
      setShowDocumentUploadMessage(false);
      setShow(!show);
    }
  };

  const onSelectLocalFiles = (selectedFiles) => {
    if (selectedFiles.files.length) {
      setUploadType(selectedFiles.type);
      let selectedDocuments = _.map(selectedFiles.files, (f) => {
        return { file: f };
      });
      setSelectedFiles(selectedDocuments);
      setUploadDetails(selectedFiles?.details);
      setShowUploadedModal(true);
      //setShow(!show);
    }
  };

  /**
   * Description
   * -----------
   * This method use to get the dcoumentsetting configuration(limitaion of file count and size).
   * Parameters:
   * filesCount: allowed file count limit.
   * totalFilesSize: allowed total file size limit.
   */
  async function getDocumentSettingsDetails(
    filesCount,
    totalFilesSize,
    data,
    count
  ) {
    InititaData = await documentservice.getDocumentSettings();
    if (
      InititaData?.noOfDocuments !== "" &&
      InititaData?.noOfDocuments !== null
    ) {
      setFileCountLimit(parseInt(InititaData.noOfDocuments));
      let filesizeGB = parseInt(InititaData.documentSize);
      let resBytes = filesizeGB * 1073741824;
      setFileLimitSize(resBytes);
      fileLimitValidationInfo(
        parseInt(InititaData.noOfDocuments),
        filesCount,
        resBytes,
        totalFilesSize,
        parseInt(InititaData.documentSize),
        data,
        count
      );
      setDocumentsDetails(InititaData, count, totalFilesSize);
    }
  }

  /**
   * Description
   * -----------
   * This method use to prepare file validation to send uploadcomponent modal.
   * Parameters:
   * fileLimit: This one is onChange event parameter to get the input text
   * totalfilecount: This parameter will show to total file count.
   * filzesizeLimit: Allowed Total file size limit in bytes.
   * totalfilesize: Uploaded documents total size.
   * maxFileSize: maximum file size limit.
   */
  function fileLimitValidationInfo(
    fileLimit,
    totalFileCount,
    filzesizeLimit,
    totalFileSize,
    maxFileSize,
    data,
    count
  ) {
    if (fileLimit > count && filzesizeLimit > totalFileSize) {
      let result = fileLimit - totalFileCount;
      let resultfilesize = filzesizeLimit - totalFileSize;
      setuploadDisable(false);
      let rescount = {
        avialbalefileuplaod: result,
        filesuploadcount: totalFileCount,
        MaxFilesuploadLimit: fileLimit,

        availableFilesizeLimit: resultfilesize,
        totalFileSize: totalFileSize,
        MaxFFileSizeLimit: maxFileSize,

        uploadedFilesdata: data,
      };
      setFilesCount(rescount);
    } else {
      setuploadDisable(true);
    }
  }

  const setDocumentsDetails = (documentSetting, filesCount, totalFilesSize) => {
    const docStatus = {
      documentsCount: filesCount,
      documentsSize: totalFilesSize,
      maxDocumentCount: documentSetting.noOfDocuments,
      maxDocumentSize: documentSetting.documentSize,
    };

    setDocumentStates(docStatus);
  };

  return (
    <div>
      {!isDocumentLibrary && <CommonComponent />}
      <div className={classcheck === "Library" ? "Frame123" : "Frame1"}>
        <div
          className={
            uploadedDocumentList?.length > 0
              ? "Title12 title12Width"
              : "Title12 title1Width"
          }
        >
          <div className="TextAndSupportingText">
            <div className="Text2">
              {!isDocumentLibrary && <div className="Text6">Add Documents</div>}
            </div>
          </div>

          <div className="motif-button-content">
            {localStorage.getItem("userRole") != "User (Non-SaT)" &&
              localStorage.getItem("userRole") !== "Viewer" && (
                <div className="mot">
                  <MotifTooltip
                    style={{ marginTop: 150 }}
                    trigger={
                      <MotifButton
                        disabled={uploadDisable}
                        onClick={(e) => {
                          setShow(true);
                        }}
                        className={
                          uploadDisable
                            ? "motif-upload-doc-button-disable"
                            : "motif-upload-doc-button"
                        }
                      >
                        <img src={upload} alt="upload document icon" />
                        Upload Document
                      </MotifButton>
                    }
                    placement="bottom"
                    contentClassName="tooltipWithoutClose"
                    allowHover={true}
                    allowClick={false}
                    hideCloseButton={true}
                    hide={!uploadDisable}
                  >
                    <div className="tooltip-content">
                      Maximum upload limit reached, remove documents to continue
                    </div>
                  </MotifTooltip>
                </div>
              )}
          </div>
        </div>
        <FilesUploadedGrid
          fileCountcheck={getFileLimits}
          openShow={show}
          filesLimit={fileCountLimit}
          fileSizeLimit={fileFileLimitSize}
          documentList={uploadedDocuments}
          deleteInitialted={deleteSubmitted}
          reloadDocuments={refreshDocumentGrid}
        />
      </div>
      {showErrorWhileLocalUpload && (
        <MotifToast
          variant="error"
          actionName=""
          position="bottom"
          onClose={() => {
            setShowErrorWhileLocalUpload(false);
          }}
        >
          <div>
            Something went wrong while uploading document. Please try again.
          </div>
        </MotifToast>
      )}
      {show && (
        <UploadModal
          openShow={show}
          setOpenModal={setShow}
          filescountInfo={fielsCount}
          localFileUploadSuccess={localFileUploadSubmitted}
          sharePointFileUploadSuccess={sharePointFileUploadSubmitted}
          onSelectLocalFiles={onSelectLocalFiles}
        />
      )}

      {showUploadedModal && (
        <DocumentUploader
          uploadType={uploadType}
          documentList={selectedFiles}
          documentStates={documentStates}
          uploadedDocuments={uploadedDocumentList}
          onCloseUploadModal={setShowUploadedModal}
          localFileUploadSuccess={localFileUploadSubmitted}
          sharePointFileUploadSuccess={sharePointFileUploadSubmitted}
          uploadDetails={uploadDetails}
        />
      )}
    </div>
  );
}

export default AddDocument;
