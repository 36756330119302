/**
 * Manage Structure component
 * Modified on 07-01-2025
 */
//  Generic imports
import React, { useContext, useEffect, useState } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import { useDispatch } from "react-redux";

//  Motif controls
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import { MotifButton, MotifSelect, MotifOption, MotifFormField, MotifLabel, MotifToast } from "@ey-xd/motif-react";

//  Icons imports
import arrowLeft from "../../../../assets/image/arrow-left-black.svg";
import noProject from "../../../../assets/image/no-project-image.png";
import xmarkCircle from "../../../../assets/image/xmark-circle.svg";
import editPencil from "../../../../assets/image/edit-pencil.svg";
import plus from "../../../../assets/image/plus_scope.svg";
import check_circle from "../../../../assets/image/check-circle.svg";
import error_circle from "../../../../assets/image/error.svg";
import info_circle from "../../../../assets/image/info-empty.svg";

//  Internal imports
import './ManageStructure.css';
import PlatformService from '../../../../services/platformService';
import TreeView from "./TreeView";
import EditStructureModal from "../InstantInsightPrompts/EditStructureModal";
import DiscardTreeHierarchyModal from './DiscardTreeHierarchyModal';
import DiscardIncompleteStructure from './DiscardIncompleteStructure';
import { DashboardContext } from "../../../../context/dashboardContext.js";
import { setIsStructureComplete } from "../../../../reducers/promptManagementSlice";

export default function ManageStructure(props) {

    const { updateAddScopeSectionVisibility } = { ...props };

    //  Initialization of services
    const platformService = new PlatformService();

    //  Initialization of hooks
    const dispatch = useDispatch();
    const { scopeAll } = useContext(DashboardContext);

    //#region Component State

    const [selectedScope, setSelectedScope] = useState('');
    const [selectedScopeTemp, setSelectedScopeTemp] = useState();
    const [scopeHasStructure, setScopeHasStructure] = useState(true);
    const [treeData, setTreeData] = useState(null);
    const [message, setMessage] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCategoryTemp, setSelectedCategoryTemp] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedSectionTemp, setSelectedSectionTemp] = useState(null);
    const [selectedSubSection, setSelectedSubSection] = useState(null);
    const [showSection, setShowSection] = useState(false);
    const [showSubSection, setShowSubSection] = useState(false);
    const [categories, setCategories] = useState([]);
    const [sections, setSections] = useState([]);
    const [subSections, setSubSections] = useState([]);
    const filteredScope = scopeAll?.filter((scope) => scope.spiiStatus === 3 || scope.spiiStatus === 4);
    const [showEditStructureModal, setShowEditStructureModal] = useState(false);
    const [isCategoryDisabled, setIsCategoryDisabled] = useState(true);
    const [isSectionDisabled, setIsSectionDisabled] = useState(true);
    const [isSubSectionDisabled, setIsSubSectionDisabled] = useState(true);
    const [haveUnsavedHierarchy, setHaveUnsavedHierarchy] = useState(false);
    const [areNodesSelected, setAreNodesSelected] = useState(false);
    const [isDiscardButtonDisabled, setIsDiscardButtonDisabled] = useState(true);
    const [isAddCategoryDisabled, setIsAddCategoryDisabled] = useState(false);
    const [isAddSectionDisabled, setIsAddSectionDisabled] = useState(false);
    const [isSectionDDLDisabled, setIsSectionDDLDisabled] = useState(false);
    const [isSubSectionDDLDisabled, setIsSubSectionDDLDisabled] = useState(false);
    const [isDiscardModalVisible, setIsDiscardModalVisible] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [unsavedNodesList, setUnsavedNodesList] = useState([]);
    const [operationType, setOperationType] = useState("");
    const [targetType, setTargetType] = useState("");
    const [toastVariant, setToastVariant] = useState("success");
    const [nodeExpansionGraph, setNodeExpansionGraph] = useState([]);
    const [editStructureModalTitle, setEditStructureModalTitle] = useState("");
    const [displayDiscardModal, setDisplayDiscardModal] = useState(false);
    const [discardSource, setDiscardSource] = useState(null);
    const [discardMessageType, setDiscardMessageType] = useState('navigation');
    const [haveUnpublishedHierarchy, setHaveUnpublishedHierarchy] = useState(false);
    const constantData = require("../../../../data/constant.js");

    //#endregion

    //#region Side Effects

    useEffect(() => {
        setSelectedNodes([]);
        setNodeExpansionGraph([]);
        setRefresh(!refresh);
        const fetchScopeSectionSubSections = async () => {
            if (selectedScope) {
                try {
                    const treeData = await platformService.getScopeTreeData(selectedScope.scopeId);
                    let formattedTreeData = [...treeData.children];
                
                    unsavedNodesList.forEach(node => {
                        if (node.scopeId === selectedScope.scopeId) {
                            if (node.newObject.nodeType === 1 && node.newObject.id === null) {
                                // Case of preexisting unsaved category node, add it back to tree. This will also add any sections that were added to this node.
                                formattedTreeData = [...formattedTreeData, node.newObject];
                            }
                            if (node.newObject.nodeType === 2 && node.newObject.parent.id !== null) {
                                // Case of preexisting unsaved section node belonging to an existing category. Add it back to same existing category.
                                let existingCategory = getNodeById(formattedTreeData, node.newObject.parent.id);
                                existingCategory.children = [...existingCategory.children, node.newObject];
                            }
                        }
                    });
                    setTreeData(formattedTreeData);
                    resetEditStructure();
                    if (formattedTreeData.length === 0) {
                        setScopeHasStructure(false);
                        setMessage("Please create category, section and subsections")
                    }
                    else {
                        setScopeHasStructure(true);
                    }
                } catch (error) {
                    console.error("Error fetching scope section subsections:", error);
                }
            }
            else {
                setMessage("Select a Project Scope to view the tree hierarchy")
            }
        };
        fetchScopeSectionSubSections();
        //  eslint-disable-next-line
    }, [selectedScope, refreshData])

    useEffect(() => {
        if (unsavedNodesList.length === 0) {
            setHaveUnsavedHierarchy(false);
        }
    }, [unsavedNodesList])

    useEffect(() => {
        if (operationType === "edit") {
            switch (targetType) {
                case "category":
                    setEditStructureModalTitle("Rename Category");
                    break;
                case "section":
                    setEditStructureModalTitle("Rename Section");
                    break;

                case "subSection":
                    setEditStructureModalTitle("Rename Subsection");
                    break;

                default:
                    break;
            }
        }
        else if (operationType === "add") {
            switch (targetType) {
                case "category":
                    setEditStructureModalTitle("Add New Category");
                    break;
                case "section":
                    setEditStructureModalTitle("Add New Section");
                    break;

                case "subSection":
                    setEditStructureModalTitle("Add New Subsection");
                    break;

                default:
                    break;
            }
        }
    }, [operationType, targetType])

    useEffect(() => {
        if (treeData) {
            if (treeData?.length === 0) {
                setScopeHasStructure(false);
                setHaveUnpublishedHierarchy(false);
            }
            else {
                setScopeHasStructure(true);
                setHaveUnpublishedHierarchy(checkForUnpublishedNodes(treeData));
            }
            const categories = treeData || [];
            setCategories(categories);
            if (selectedCategory) {
                if (operationType !== "edit") {
                    setSections(selectedCategory.children);
                }
            }
            if (selectedSection) {
                if (operationType !== "edit") {
                    setSubSections(selectedSection.children);
                }
            }
            if (targetType === "category") {
                setShowSection(false);
                setShowSubSection(false);
            }
            if (targetType === "section") {
                if (operationType !== "edit") {
                    setShowSubSection(false);
                }
            }
        }
        //  eslint-disable-next-line
    }, [treeData])

    useEffect(() => {
        if (selectedCategory) {
            setSections(selectedCategory.children);
            if(selectedCategory.children.length === 0){
                setIsSectionDDLDisabled(true);
            }
            else{
                setIsSectionDDLDisabled(false);
            }
            setShowSection(true);
        }
    }, [selectedCategory])

    useEffect(() => {
        if (selectedSection) {
            setSubSections(selectedSection.children);
            if(selectedSection.children.length === 0){
                setIsSectionDDLDisabled(false);
                setIsSubSectionDDLDisabled(true);
            }
            else{
                setIsSubSectionDDLDisabled(false);
            }
            setShowSubSection(true);
        }
    }, [selectedSection])

    useEffect(() => {
        setIsDiscardButtonDisabled(!areNodesSelected);
    }, [areNodesSelected]);

    //  Hook to set isStructureComplete in redux store
    useEffect(() => {
        dispatch(
            setIsStructureComplete({
                isStructureComplete: !haveUnsavedHierarchy,
            })
        );
        //  eslint-disable-next-line
    }, [haveUnsavedHierarchy]);

    //#endregion

    //#region Methods

    const getNodeById = (nodes, targetId) => {
        for (const node of nodes) {
            if (node.id === targetId) {
                return node; // Match found
            }
            if (node.children && node.children.length > 0) {
                const result = getNodeById(node.children, targetId); // Search in children
                if (result) {
                    return result;
                }
            }
        }
        return null; // No match found
    };

    const checkForUnpublishedNodes = (nodes) => {
        for (let node of nodes) {
            if (!node.isPublished) {
                return true;
            }
            if (node.children && node.children.length > 0) {
                if (checkForUnpublishedNodes(node.children)) {
                    return true; // Stop further recursion if a match is found
                }
            }
        }
        return false;
    }

    const bubbleBackData = (selectedNodes) => {
        setSelectedNodes(selectedNodes);
    }

    const handleModalClose = () => {
        setIsDiscardModalVisible(false);
    }

    const updateAreNodesSelected = (param) => {
        setAreNodesSelected(param);
    }

    const resetEditStructure = () => {
        setSelectedCategory(null);
        setSelectedSection(null);
        setSelectedSubSection(null);
        setShowSection(false);
        setShowSubSection(false);
    }

    const addNewNodeToTree = async (newObject) => {
        if (targetType === "category") {
            // add to categories
            setUnsavedNodesList([...unsavedNodesList, { scopeId: selectedScope.scopeId, newObject }]);
            setTreeData([...treeData, newObject]);
            setSelectedCategory(newObject);
            setSelectedSection(null);
            setNodeExpansionGraph([...nodeExpansionGraph, newObject]);
            setHaveUnsavedHierarchy(true);
            setIsAddCategoryDisabled(true);
            setIsCategoryDisabled(false);
        }
        else if (targetType === "section") {
            // add to section
            let category = treeData.find((category) => {
                return category.value === selectedCategory.value
            })
            category.children = [...category.children, newObject];
            if (category.id) {
                // In case of section being added to an existing/Already Saved category then add it to unsaved nodes list, otherwise if it is being added to unsaved/new category then it would have already been added to unsaved nodes list as child of category in lines above
                setUnsavedNodesList([...unsavedNodesList, { scopeId: selectedScope.scopeId, newObject }]);
            }
            setTreeData([...treeData]);
            setSelectedSection(newObject);
            setSelectedSubSection(null);
            setNodeExpansionGraph([...nodeExpansionGraph, newObject]);
            setHaveUnsavedHierarchy(true);
            setIsAddCategoryDisabled(true);
            setIsAddSectionDisabled(true);
            setIsSectionDisabled(false);
        }
        else if (targetType === "subSection") {
            // add to subSection. It is always saved.
            await platformService.saveScopeTreeHierarchy({
                "scopeId": selectedScope.scopeId,
                "categoryId": selectedCategory.id,
                "categoryName": selectedCategory.value,
                "sectionId": selectedSection.id,
                "sectionName": selectedSection.value,
                "subSectionId": newObject.id,
                "subSectionName": newObject.value
            });
            const treeData = await platformService.getScopeTreeData(selectedScope.scopeId);
            const formattedTreeData = [...treeData.children];
            setTreeData(formattedTreeData);

            const updatedUnsavedNodeList = removeFromUnsavedNodesList([selectedCategory.value]);
            setUnsavedNodesList(updatedUnsavedNodeList);

            let category = formattedTreeData.find((category) => {
                return category.value === selectedCategory.value
            })
            setSelectedCategory(category);

            let section = category.children.find((section) => {
                return section.value === selectedSection.value
            })
            setSelectedSection(section);

            let subSection = section.children.find((subSection) => {
                return subSection.value === newObject.value
            })
            setSelectedSubSection(subSection);

            if (formattedTreeData.length === 0) {
                setScopeHasStructure(false);
                setMessage("Please create Category, Section and Subsections")
            }
            else {
                setScopeHasStructure(true);
            }            
            setNodeExpansionGraph([...nodeExpansionGraph, newObject]);
            setIsAddCategoryDisabled(false);
            setIsAddSectionDisabled(false);
            setIsSubSectionDisabled(false);
        }
    }

    /**
     * Function to handle back button
     * @returns 
     */
    const onBackBtnClick = () => {
        //  intercept if incomplete structure
        if (haveUnsavedHierarchy) {
            setDiscardSource('Back');
            setDiscardMessageType('navigation');
            setDisplayDiscardModal(true);
            return false;
        }
        updateAddScopeSectionVisibility(false);
    }

    const noData = (section) => {
        return (
            <div className="noScopeDiv">
                <img src={noProject} className="no scopes" alt=""></img>
                <div className="noScopeMsgCont">
                    <div className="no-project-scope-title">
                        {
                            (()=>{
                                if(!selectedScope){
                                    return "No selection made"
                                }
                                else if(section === "treeview"){
                                    return "No structure created"
                                }
                            })()
                        }
                    </div>
                    <div className="no-project-scope-detail">
                        {
                            section === "treeview" ? message : "Select a Project Scope to view the structure"
                        }
                    </div>
                </div>
            </div>
        )
    }

    /**
     * Function to handle scope dropdown value change
     * @param {*} item 
     * @returns 
     */
    const onScopeChange = (item) => {
        //  intercept if incomplete structure
        if (haveUnsavedHierarchy) {
            setDiscardSource('ScopeChange');
            setDiscardMessageType('selectionChange');
            setSelectedScopeTemp(item);
            setDisplayDiscardModal(true);
            return false;
        }
        setSelectedScope(item);
        setIsCategoryDisabled(true);
        setIsSectionDisabled(true);
        setIsSubSectionDisabled(true);
        setIsAddCategoryDisabled(false);
        setIsAddSectionDisabled(false);
    }

    /**
     * Function to handle category dropdown value change
     * @param {*} item 
     * @returns 
     */
    const onCategoryChange = (item) => {
        //  intercept if incomplete structure
        if (haveUnsavedHierarchy) {
            setDiscardSource('CategoryChange');
            setDiscardMessageType('selectionChange');
            setSelectedCategoryTemp(item);
            setDisplayDiscardModal(true);
            return false;
        }
        setNodeExpansionGraph([...nodeExpansionGraph, item]);
        setSelectedCategory(item);
        setSelectedSection(null);
        setSelectedSubSection(null);
        setIsCategoryDisabled(false);
        setShowSubSection(false);
        setIsSectionDisabled(true);
    }
    
    /**
     * Function to handle section dropdown value change
     * @param {*} item 
     * @returns 
     */
    const onSectionChange = (item) => {
        //  intercept if incomplete structure
        if (haveUnsavedHierarchy) {
            setDiscardSource('SectionChange');
            setDiscardMessageType('selectionChange');
            setSelectedSectionTemp(item);
            setDisplayDiscardModal(true);
            return false;
        }
        setNodeExpansionGraph([...nodeExpansionGraph, item]);
        setSelectedSection(item);
        setSelectedSubSection(null);
        setIsSectionDisabled(false);
        setIsSubSectionDisabled(true);
    }

    
    /**
     * Function to reset selected value to old incase of incomplete structure
     * @param {*} vField, vValue
     * @returns 
     */
    const setSelectedValueCustom = (vField, vValue) => {
        const divSelectedVal = document.querySelector(`.${vField} .motif-select-input-text`);
        if (divSelectedVal) {
            divSelectedVal.innerHTML = vValue;
        }
    }

    const onSubsectionChange = (item) => {
        setNodeExpansionGraph([...nodeExpansionGraph, item]);
        setSelectedSubSection(item)
        setIsSubSectionDisabled(false);
    }

    const renameBtnClick = (event, type) => {
        setOperationType("edit");
        setTargetType(type);
        setShowEditStructureModal(true);
    }

    const addBtnClick = (event, type) => {
        setOperationType("add");
        setTargetType(type);
        setShowEditStructureModal(true);
    }

    /**
     * This method is to save the update node value to database or temp dataset
     * Parameters:
      * targetType: this one is the selected type of node
      * selectedId: this one is the selected node id by user
      * selectedText: this one is the updated value by user
      *
     */
    const renameScopeStructure = async (targetType, selectedId, selectedText) => {
        if (operationType === "edit") {
            if (selectedId !== null) {
                let structureRequest = {};
                structureRequest.id = selectedId;
                structureRequest.type = targetType;
                structureRequest.name = selectedText;
                const updateDataStatus = await platformService.renameScopeStructure(structureRequest);
                if (updateDataStatus === true) {
                    renameSelection(targetType, selectedId, selectedText);
                    setMessage(capitalize(targetType) + " renamed successfully ");
                    setToastVariant("success");
                    setShowToast(true);
                    const treeData = await platformService.getScopeTreeData(selectedScope.scopeId);
                    const formattedTreeData = [...treeData.children];
                    setTreeData(formattedTreeData);
                    setIsCategoryDisabled(true);
                    setIsSectionDisabled(true);
                    setIsSubSectionDisabled(true);
                }
                else {
                    setToastVariant("error");
                    setMessage("Renaming the " + capitalize(targetType) + " failed due to a technical error. Please try again later.");
                    setShowToast(true);
                }
            }
            else {
                renameSelection(targetType, selectedId, selectedText);
                if (targetType === "category") {
                    updateValueByNodeType(1, selectedText);
                    setMessage(capitalize(targetType) + " renamed successfully ");
                    setToastVariant("success");
                    setShowToast(true);
                }
                else if (targetType === "section") {
                    updateValueByNodeType(2, selectedText);
                    setMessage(capitalize(targetType) + " renamed successfully ");
                    setToastVariant("success");
                    setShowToast(true);
                }
                else if (targetType === "subSection") {
                    updateValueByNodeType(3, selectedText);
                    setMessage(capitalize(targetType) + " renamed successfully ");
                    setToastVariant("success");
                    setShowToast(true);
                    setIsCategoryDisabled(true);
                    setIsSectionDisabled(true);
                    setIsSubSectionDisabled(true);
                }
            }
        }
    }

    /**
     * This method is to handle the updation call of tree node value
     * Parameters:
      * targetNodeType: this one is the selected type of node
      * newValue: this one is the selected value by user
      *
     */
    const updateValueByNodeType = (targetNodeType, newValue) => {
        const updatedTreeData = [...treeData];
        updatedTreeData.forEach(node => updateTreeValueByNodeType(node, targetNodeType, newValue));
        setTreeData(updatedTreeData);
    }

    /**
     * This method is to update specific node value in a tree
     * Parameters:
      * node: this one is the selected node by user
      * targetNodeType: this one is the selected type of node
      * newValue: this one is the selected value by user
      *
     */
    const updateTreeValueByNodeType = (node, targetNodeType, newValue) => {
        if (node.nodeType === targetNodeType && node.id === null) {
            node.value = newValue;
        }
        if (Array.isArray(node.children)) {
            node.children.forEach(child => updateTreeValueByNodeType(child, targetNodeType, newValue));
        }
    }

    /**
     * This method is to capitalize the first letter of test for toast message
     * @param {*} value
     */
    const capitalize = (word) => {
        const newword = word.toLowerCase();
        return newword.charAt(0).toUpperCase() + newword.slice(1);
    }

    const handleDiscardClick = () => {
        setIsDiscardModalVisible(true);
    }

    const discardHierarchy = async () => {
        const selectedValues = [];
        const payload = {
            "Topic": [],
            "Section": [],
            "SubSection": []
        }
        selectedNodes.forEach((node) => {
            if (node.id !== null) {
                // Case of existing/already saved node
                switch (node.nodeType) {
                    case 1:
                        // Case of Category/Topic
                        payload.Topic.push(node.id);
                        break;
                    case 2:
                        // Case of Section
                        payload.Section.push(node.id);
                        break;
                    case 3:
                        // Case of Sub-Section
                        payload.SubSection.push(node.id);
                        break;
                    default:
                        break;
                }
            }
            else {
                // Case of not yet saved(In Memory) node
                selectedValues.push(node.value);
            }
        })
        if (payload.Topic.length > 0 || payload.Section.length > 0 || payload.SubSection.length > 0) {
            // Discard API call
            await platformService.DiscardHierarchy(payload);
            setRefreshData(!refreshData);
            setAreNodesSelected(false);
            setSelectedCategory(null);
            setShowSection(false);
            setShowSubSection(false);
            setSelectedNodes([]);
            setToastVariant("info");
            setMessage("Selected items discarded successfully");
            setShowToast(true);
        }
        if (selectedValues.length > 0) {
            // Discard in memory heirarchy
            const updatedUnsavedNodeList = removeFromUnsavedNodesList(selectedValues);
            setUnsavedNodesList(updatedUnsavedNodeList);
            const updatedTreeData = removeSelectedNodes(selectedValues);
            setTreeData(updatedTreeData);
            setAreNodesSelected(false);
            setSelectedCategory(null);
            setShowSection(false);
            setShowSubSection(false);
            setSelectedNodes([]);
            //setRefresh(!refresh);
            setToastVariant("info");
            setMessage("Selected items discarded successfully");
            setShowToast(true);
        }
    }

    const removeFromUnsavedNodesList = (selectedValues) => {
        return removeNodesFromUnsavedNodes(unsavedNodesList, selectedValues);
    }

    const removeNodesFromUnsavedNodes = (nodes, selectedValues) => {
        if (!Array.isArray(nodes)) return [];

        return nodes
        .filter((node) => !selectedValues.includes(node.newObject?.value || node.value)) // Handle both top-level and child nodes.
        .map((node) => {
            if (node.newObject) {
                // For top-level nodes.
                return {
                    ...node, // Keep properties like `scopeId`.
                    newObject: {
                        ...node.newObject,
                        children: removeNodesFromUnsavedNodes(node.newObject.children, selectedValues), // Recurse for children.
                    },
                };
            } else {
                // For child nodes without `newObject`.
                return {
                    ...node,
                    children: removeNodesFromUnsavedNodes(node.children, selectedValues), // Recurse for deeper levels.
                };
            }
        });
    };

    const removeSelectedNodes = (selectedValues) => {
        return removeNodes(treeData, selectedValues);
    }

    const removeNodes = (nodes, selectedValues) => {
        return nodes?.filter((node) => !selectedValues.includes(node.value))
            .map((node) => ({
                ...node,
                children: removeNodes(node.children, selectedValues),
            }));
    };

    /**
     * Function to handle discard confirmation
     * on Back button click
     * on Scope dropdown value change
     * on Category dropdown value change
     * on Section dropdown value change
     */
    const onConfirmDiscard = () => {
        switch (discardSource) {
            case 'Back':
                updateAddScopeSectionVisibility(false);
                break;
            case 'ScopeChange':
                if (selectedSection) {
                    let category = treeData.find((category) => {
                        return category.value === selectedCategory.value
                    })
                    category.children = category.children.filter((element) => element.id);
                    setTreeData((treeData) => treeData.filter((element) => element.id));
                    setNodeExpansionGraph((nodeExpansionGraph) => nodeExpansionGraph.filter((element) => element.id));
                    setSelectedSection(null);
                }
                if (selectedCategory) {
                    setTreeData((treeData) => treeData.filter((element) => element.id));
                    setNodeExpansionGraph((nodeExpansionGraph) => nodeExpansionGraph.filter((element) => element.id));
                    setSelectedCategory(null);
                }
                setIsAddCategoryDisabled(false);
                setIsAddSectionDisabled(false);
                setHaveUnsavedHierarchy(false);
                setSelectedScope(selectedScopeTemp);
                setIsCategoryDisabled(true);
                setIsSectionDisabled(true);
                setIsSubSectionDisabled(true);
                break;
            case 'CategoryChange':
                if (selectedSection) {
                    let category = treeData.find((category) => {
                        return category.value === selectedCategory.value
                    });
                    category.children = category.children.filter((element) => element.id);
                    setTreeData((treeData) => treeData.filter((element) => element.id));
                    setNodeExpansionGraph((nodeExpansionGraph) => nodeExpansionGraph.filter((element) => element.id));
                    setSelectedSection(null);
                }
                if (selectedCategory) {
                    setTreeData((treeData) => treeData.filter((element) => element.id));
                    setNodeExpansionGraph((nodeExpansionGraph) => nodeExpansionGraph.filter((element) => element.id));
                }
                setIsAddSectionDisabled(false);
                setHaveUnsavedHierarchy(false);
                setSelectedCategory(selectedCategoryTemp);
                setNodeExpansionGraph([...nodeExpansionGraph, selectedCategoryTemp]);
                setSelectedSection(null);
                setSelectedSubSection(null);
                setIsCategoryDisabled(false);
                break;
            case 'SectionChange':
                if (selectedSection) {
                    let category = treeData.find((category) => {
                        return category.value === selectedCategory.value
                    })
                    category.children = category.children.filter((element) => element.id);
                    setTreeData((treeData) => treeData.filter((element) => element.id));
                    setNodeExpansionGraph((nodeExpansionGraph) => nodeExpansionGraph.filter((element) => element.id));
                }
                setHaveUnsavedHierarchy(false);
                setNodeExpansionGraph([...nodeExpansionGraph, selectedSectionTemp]);
                setSelectedSection(selectedSectionTemp);
                setSelectedSubSection(null);
                setIsSectionDisabled(false);                
                break;
            default:
                break;
        }
    }

    /**
     * Function to handle cancel discard action
     * on Scope dropdown value change
     * on Category dropdown value change
     * on Section dropdown value change
     * to reset old value on select dropdown
     */
    const onCancelDiscard = () => {
        switch (discardSource) {
            case 'ScopeChange':
                setSelectedValueCustom('manageStructureSelectScope', selectedScope.scopeName);
                break;
            case 'CategoryChange':
                setSelectedValueCustom('manageStructureSelectCategory', selectedCategory.value);
                break;
            case 'SectionChange':
                setSelectedValueCustom('manageStructureSelectSection', selectedSection.value);
                break;
            default:
                break;
        }
    }

    /**
     * Hook to handle refresh to show discard confirmation.
     */
    useBeforeUnload((e) => {
        if (haveUnsavedHierarchy) {
            e.preventDefault();
        }
    });

    // Function to handle renaming the category,section,subSection based on id and nodeType
    const renameSelection = (targetType, id, newValue) => {
        if (targetType === "category") {
            const updatedCategory = categories.map((item) => {
                if (item.id === id && item.nodeType === 1) {
                    return { ...item, value: newValue };
                }
                return item;
            });
            setCategories(updatedCategory);
            const updatedSelected = updatedCategory.find(category => category.id === id && category.nodeType === 1);
            setSelectedCategory(updatedSelected);
        }
        else if (targetType === "section") {
            const updatedSections = sections.map((item) => {
                if (item.id === id && item.nodeType === 2) {
                    return { ...item, value: newValue };
                }
                return item;
            });
            setSections(updatedSections);
            const updatedSelected = updatedSections.find(section => section.id === id && section.nodeType === 2);
            setSelectedSection(updatedSelected);
        }
        else if (targetType === "subSection") {
            const updatedSubSections = subSections.map((item) => {
                if (item.id === id && item.nodeType === 3) {
                    return { ...item, value: newValue };
                }
                return item;
            });
            setSubSections(updatedSubSections);
            const updatedSelected = updatedSubSections.find(subSection => subSection.id === id && subSection.nodeType === 3);
            setSelectedSubSection(updatedSelected);
        }
    };

    //#endregion

    //#region Component JSX


    return (
        <>
            {
                displayDiscardModal &&
                <DiscardIncompleteStructure trigger="manual" when={null} setOpenModal={setDisplayDiscardModal} messageType={discardMessageType} onConfirm={onConfirmDiscard} onCancel={onCancelDiscard} />
            }
            <DiscardIncompleteStructure trigger="dynamic" when={haveUnsavedHierarchy} setOpenModal={null} messageType={null} onConfirm={null} onCancel={null} />
            <div className="manageStructureComponent">
                <div className="back-section project-scope-field-txt">
                    <MotifIconButton className="icon-btn" onClick={onBackBtnClick}>
                        <div className="back-btn">
                            <img src={arrowLeft} alt="Back" /><span>Back</span>
                        </div>
                    </MotifIconButton>
                </div>
                <div className="parent-top-heading">
                    <div className="top-heading">
                        Manage Structure
                    </div>
                </div>
                <div className='projectScopeSlection'>
                    <span className="select-project-scope-dropdown-title">Project Scope</span>
                    <MotifFormField>
                        {
                            filteredScope?.length === 0 ?
                                <MotifLabel id="selectscope">No Project Scope Found</MotifLabel>
                                : null
                        }
                        <MotifSelect
                            id="scopeSelect"
                            label="selectscope"
                            className="InputInstantInsight manageStructureSelectScope"
                            ariaLabelledBy="select-label"
                            placeholder="Select Project Scope"
                            onChange={onScopeChange}
                            disabled={filteredScope?.length === 0}
                            required
                        >
                            {filteredScope &&
                                [...filteredScope.map((item) => {
                                    return (
                                        <MotifOption
                                            className={item.scopeName}
                                            value={item}
                                            key={item.scopeName}
                                        >
                                            {item.scopeName}
                                        </MotifOption>
                                    );
                                })]
                            }
                        </MotifSelect>
                    </MotifFormField>
                </div>
                <div className='manageStructureMain'>
                    <div className='editStructure'>
                        <div className='structureTitle'>
                            Edit Structure{/*
                            */}<span>Add or rename the Category, Section and Subsection</span>
                        </div>
                        <div className='structureData'>
                            {!selectedScope ? noData("editSection") :
                                <>
                                    <div className='infoGroup'>
                                        <MotifLabel>Category</MotifLabel>
                                        <MotifFormField>
                                            <MotifSelect
                                                label="selectCategory"
                                                className="Input manageStructureSelectCategory"
                                                placeholder='Select Category'
                                                value={selectedCategory}
                                                disabled={!scopeHasStructure}
                                                onChange={onCategoryChange}
                                                required
                                            >
                                                {
                                                    categories?.map((category) => {
                                                        return (
                                                            <MotifOption
                                                                key={category.id}
                                                                value={category}
                                                            >
                                                                {category.value}
                                                            </MotifOption>
                                                        );
                                                    })
                                                }
                                            </MotifSelect>
                                        </MotifFormField>
                                        <div className='buttonsGroup'>
                                            <MotifIconButton className="iconBtn" onClick={(e) => renameBtnClick(e, "category")} disabled={isCategoryDisabled || !selectedCategory}>
                                                <img src={editPencil} alt="Rename" /><span>Rename</span>
                                            </MotifIconButton>
                                            <MotifButton variant="secondary" className="iconBtn btn-add-scope" onClick={(e) => addBtnClick(e, "category")} disabled={isAddCategoryDisabled}
                                            >
                                                <img src={plus} alt="Add" />Add
                                            </MotifButton>
                                        </div>
                                    </div>
                                    {
                                        showSection && (
                                            <div className='infoGroup'>
                                                <MotifLabel>Section</MotifLabel>
                                                <MotifFormField>
                                                    <MotifSelect
                                                        label="selectSection"
                                                        placeholder='Select Section'
                                                        className="Input manageStructureSelectSection"
                                                        value={selectedSection}
                                                        disabled={isSectionDDLDisabled}
                                                        onChange={onSectionChange}
                                                        required
                                                    >
                                                        {
                                                            sections?.map((section) => {
                                                                return (
                                                                    <MotifOption
                                                                        key={section.id}
                                                                        value={section}
                                                                    >
                                                                        {section.value}
                                                                    </MotifOption>
                                                                );
                                                            })
                                                        }
                                                    </MotifSelect>
                                                </MotifFormField>
                                                <div className='buttonsGroup'>
                                                    <MotifIconButton className="iconBtn" onClick={(e) => renameBtnClick(e, "section")} disabled={isSectionDisabled || !selectedSection}>
                                                        <img src={editPencil} alt="Rename" /><span>Rename</span>
                                                    </MotifIconButton>
                                                    <MotifButton variant="secondary" className="iconBtn btn-add-scope" onClick={(e) => addBtnClick(e, "section")} disabled={isAddSectionDisabled}>
                                                        <img src={plus} alt="Add" />Add
                                                    </MotifButton>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            }

                            {
                                showSubSection && (
                                    <div className='infoGroup'>
                                        <MotifLabel>Subsection</MotifLabel>
                                        <MotifFormField>
                                            <MotifSelect
                                                label="selectSubSection"
                                                placeholder='Select Subsection'
                                                className="Input"
                                                value={selectedSubSection}
                                                disabled={isSubSectionDDLDisabled}
                                                onChange={onSubsectionChange}
                                                required
                                            >
                                                {
                                                    subSections?.map((subSection) => {
                                                        return (
                                                            <MotifOption
                                                                id={subSection.id}
                                                                value={subSection}
                                                                key={subSection.id}
                                                            >
                                                                {subSection.value}
                                                            </MotifOption>
                                                        );
                                                    })
                                                }
                                            </MotifSelect>
                                        </MotifFormField>
                                        <div className='buttonsGroup'>
                                            <MotifIconButton className="iconBtn" onClick={(e) => renameBtnClick(e, "subSection")} disabled={isSubSectionDisabled || !selectedSubSection}>
                                                <img src={editPencil} alt="Rename" /><span>Rename</span>
                                            </MotifIconButton>
                                            <MotifButton variant="secondary" className="iconBtn btn-add-scope" onClick={(e) => addBtnClick(e, "subSection")}
                                            >
                                                <img src={plus} alt="Add" />Add
                                            </MotifButton>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                showEditStructureModal && (
                                    <EditStructureModal
                                        title={editStructureModalTitle}
                                        subTitle={targetType === 'category'
                                            ? constantData.renameCategoryHeader
                                            : targetType === 'section'
                                                ? constantData.renameSectionHeader
                                                : targetType === 'subSection'
                                                    ? constantData.renameSubSectionHeader
                                                    : ''}
                                        operationType={operationType}
                                        targetType={targetType}
                                        selectedScope={selectedScope}
                                        category={selectedCategory}
                                        section={selectedSection}
                                        subSection={selectedSubSection}
                                        addNewNodeToTree={addNewNodeToTree}
                                        closeEditStructureModal={() => {
                                            setShowEditStructureModal(false);
                                        }}
                                        renameScopeStructure={renameScopeStructure}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className='treeView'>
                        <div className={haveUnpublishedHierarchy ? 'structureTitle discard-wrapper' : 'structureTitle'}>
                            <div>
                                Tree View{/*
                                */}<span>Preview of Category, Section and Subsection</span>
                            </div>
                            {
                                haveUnpublishedHierarchy &&
                                <div>
                                    <MotifButton
                                        variant="secondary"
                                        size='small'
                                        onClick={() => {
                                            handleDiscardClick();
                                        }}
                                        className="discard-button"
                                        disabled={isDiscardButtonDisabled}
                                    >
                                        <img alt='icon' className='cross-icon' src={xmarkCircle} />
                                        <div className='discard-text'>Discard</div>
                                    </MotifButton>
                                </div>
                            }
                        </div>
                        <div className='structureData'>
                            {!selectedScope || !scopeHasStructure ? noData("treeview") :
                                <div>
                                    <TreeView refresh={refresh} treeData={treeData} showCheckbox={true} nodeExpansionGraph={nodeExpansionGraph} updateAreNodesSelected={updateAreNodesSelected} bubbleBackData={bubbleBackData} />
                                </div>
                            }
                        </div>
                        {
                            isDiscardModalVisible && (
                                <DiscardTreeHierarchyModal selectedNodes={selectedNodes} handleModalClose={handleModalClose} handleDiscardConfirmClick={discardHierarchy} />
                            )
                        }
                    </div>
                    {
                        showToast && (
                            <MotifToast
                                icons={(toastVariant === 'success') ? { toast: <img src={check_circle} alt=""></img> } : (toastVariant === 'info') ? { toast: <img src={info_circle} alt=""></img> } : { toast: <img src={error_circle} alt=""></img> }}
                                variant={toastVariant}
                                position="bottom"
                                onClose={() => {
                                    setShowToast(false);
                                }}
                                className='toast-div'
                            >
                                <div className='toast-msg'>{message}</div>
                            </MotifToast>
                        )
                    }
                </div>
            </div >
        </>
    )

    //#endregion
}
