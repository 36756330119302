import PropTypes from "prop-types";
import { MotifButton} from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import icon_warning from "../../../assets/image/warning-triangle_red.svg";

const DiscardSelectionDocumentModal = (props) => {
 
  /**
   * This method will be closed the modal
   */
  const btnModalCloseClick = () => {
    props.closeDiscardSelectionModal();
  };

  /**
   * This method will be closed the modals
   */
  const btnModalConfirmClick = () => {
    props.confirmDiscardSelectionModal();
  };

  

  return (
    <div>
      <MotifModal
        id="motifModalConfirmMovePrompt"
        className="genericModal"
        size="md"
        show={true}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={btnModalCloseClick}
      >
        <div>
          <MotifModalHeader>
            <div className="warning-image-div">
              <img
                className="icon-refresh"
                src={icon_warning}
                alt="warniing_image"
              ></img>
            </div>
          </MotifModalHeader>
          <div className="confirm-move-prompt-modal-header">
          Discard Selection
          </div>
        </div>
        <MotifModalBody>
          <div className="document-replace-copy-description">
          Discard documents selected for upload to the Document Library? This action cannot be undone.
          </div>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="share-alert-btns-div">
            <div>
              <MotifButton
                variant="primary-alt"
                onClick={() => {
                  btnModalCloseClick()
                }}
                className="share-alert-cancel-button"
              >
                Cancel
              </MotifButton>
            </div>
            <div>
              <MotifButton
                className="share-alert-go-to-tw-button"
                onClick={()=>{btnModalConfirmClick()}}
              >
                Confirm
              </MotifButton>
            </div>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
};

DiscardSelectionDocumentModal.propTypes = {
  closeDiscardSelectionModal:PropTypes.func,
  confirmDiscardSelectionModal:PropTypes.func, 
};

export default DiscardSelectionDocumentModal;
