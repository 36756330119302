//  Generic imports
import React, { useState, useRef, useEffect } from "react";

//  Motif imports
import MotifRichTextEditor from "@ey-xd/motif-react/RichTextEditor";
import {
  MotifButton,
  MotifInput,
  MotifCard,
  MotifLabel,
  MotifFormField,
} from "@ey-xd/motif-react";

//  Internal imports
import "./RichTextEditor.css";
const constantData = require("../../data/constant.js");

const RichTextEditor = ({
  editorContent,
  setEditorContent,
  handleEditorDataChange,
  editorContentError,
}) => {
  const [selectedText, setSelectedText] = useState("");
  const [isLinkModalVisible, setIsLinkModalVisible] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [selectionRange, setSelectionRange] = useState(null);
  const notificationReference = useRef(null);
  const [error, setError] = useState(false);

  //  Getting the domain name from .env file
  const domainNames = process.env.REACT_APP_DOMAIN_NAMES || "";
  const allowedDomainNames = domainNames.split(",").map((url) => url.trim());
  const maxDescriptionChars = 1000;

  useEffect(() => {
    overrideLinkInputEvent();
  }, []);

  const handleSelectionChange = () => {
    const selection = window.getSelection();
    const text = selection.toString();
    if (text) {
      setSelectedText(text);
      setSelectionRange(selection.getRangeAt(0));
      setIsLinkModalVisible(true);
    }
  };

  const handleSaveLink = () => {
    const linkedText = `<a href="${linkUrl}" style="font-weight:bold;">${linkUrl}</a>`;
    const tempElement = document.createElement("div");
    tempElement.innerHTML = linkedText;
    const linkedNode = tempElement.firstChild;

    const range = selectionRange;
    range.deleteContents();
    range.insertNode(linkedNode);

    const updatedContent = document.querySelector(".motifeditor").innerHTML;
    setEditorContent(updatedContent);
    // handleEditorDataChange(updatedContent);
    setIsLinkModalVisible(false);
  };

  const handleLinkUrlChange = (e) => {
    setLinkUrl(e.target.value);
  };

  const validateLinkInput = (link, saveLink) => {
    let isValidLink = false;
    let validationError = "";

    const linkInputText = notificationReference.current?.querySelector(
      ".ql-tooltip.ql-editing > input"
    );

    if (linkInputText) {
      linkInputText.placeholder = "https://discovercontent.ey.net/";
    }
    // Regular expression to validate URLs
    const urlPattern = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/;


    const domainName = URL.canParse(link) ? new URL(link).hostname : "";
    const errorMessageContainer = document.getElementById("linkError");
    if (link.trim() === "") {
      validationError = "";
      if (errorMessageContainer) {
        errorMessageContainer.innerText = "";
      }
      linkInputText.classList.remove("link-minput-error");
      return;
    } else {
      if (!urlPattern.test(link)) {
        validationError = constantData.INVALID_LINK_ERROR;
        linkInputText.classList.add("link-minput-error");
      } else if (!allowedDomainNames.includes(domainName)) {
        validationError = constantData.ADD_VALID_LINK_ERROR;
        linkInputText.classList.add("link-minput-error");
      } else {
        isValidLink = true;
        linkInputText.classList.remove("link-minput-error");
      }
    }

    if (errorMessageContainer) {
      errorMessageContainer.innerText = validationError;
    }
    if (saveLink) {
      saveLink.style = isValidLink ? "" : "color:grey; pointer-events:none";
    }
  };

  const overrideLinkInputEvent = () => {
    const promptEditor = notificationReference.current;
    const linkInputText = promptEditor?.querySelector(
      ".ql-tooltip.ql-editing > input"
    );
    const saveLink = promptEditor?.querySelector(
      ".ql-tooltip.ql-editing > a.ql-action"
    );

    if (linkInputText) {
      linkInputText.placeholder = "https://discovercontent.ey.net/";
      //  Insert error message div
      const linkTooltipContainer = promptEditor?.querySelector(
        ".ql-tooltip.ql-editing"
      );
      const errorMessageContainer = document.createElement("div");
      errorMessageContainer.setAttribute("id", "linkError");
      errorMessageContainer.setAttribute("class", "linkError");
      linkTooltipContainer.appendChild(errorMessageContainer);

      linkInputText.addEventListener("input", (e) => {
        validateLinkInput(e.target.value, saveLink);
      });
      linkInputText.addEventListener("focus", (e) => {
        validateLinkInput(e.target.value, saveLink);
      });
      validateLinkInput(linkInputText.value, saveLink);
    } else {
      setTimeout(overrideLinkInputEvent, 10);
    }
  };

  const toolbarOptions = [
    [{ font: ["MyCustomFont", "Arial", "Courier"] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ];

  return (
    <div>
      <div className="mlabel-message-desc">Message description</div>
      <div className="motif-rich-editor-div " ref={notificationReference}>
        <MotifRichTextEditor
          className={`motifeditor ${editorContentError ? "editor-error" : ""}`}
          fonts={["hello"]}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "video",
          ]}
          modules={{
            clipboard: {
              matchVisual: false,
            },
            toolbar: [
              [
                {
                  font: [],
                },
              ],
              ["bold", "italic"],
              [
                {
                  header: "1",
                },
                {
                  header: "2",
                },
              ],
              ["link"],
              [
                {
                  list: "ordered",
                },
                {
                  list: "bullet",
                },
              ],
            ],
          }}
          onChange={(content, delta, source, editor) => {
            const updatedContent = editor.getHTML();
            const div = document.createElement("div");
            div.innerHTML = updatedContent || "";
            const plainText = div.textContent || div.innerText || "";
            if (plainText.length-1 >maxDescriptionChars) {
              let updatedContent1 = plainText.trim().slice(0, maxDescriptionChars+1); 
              setEditorContent(updatedContent1)
              return;
           }
            setEditorContent(updatedContent);
            //handleEditorDataChange(updatedContent);
          }}
          name="editorContent"
          value={editorContent}
          onMouseUp={handleSelectionChange}
        />
      </div>
    </div>
  );
};

export default RichTextEditor;
