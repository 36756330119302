import { configureStore } from "@reduxjs/toolkit";
import instantInsightReducer from "./reducers/instantInsightSlice";
import promptManagementReducer from "./reducers/promptManagementSlice";
import scopeReducer from "./reducers/scopeSlice";
import activeUserReducer from "./reducers/activeUserRoleSlice";
import activeSatScopeReducer from "./reducers/activeSatScopeIdSlice";
import { notificationApi } from "./reducers/notificationApi";
import notificationReducer from "./reducers/notificationSlice";

const store = configureStore({
  reducer: {
    instantInsight: instantInsightReducer,
    promptManagement: promptManagementReducer,
    scope: scopeReducer,
    activeUser: activeUserReducer,
    satScopeId: activeSatScopeReducer,
    notification: notificationReducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(notificationApi.middleware),
});

export default store;
