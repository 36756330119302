import React, { useEffect, useState } from 'react'
import MotifCarousel from '@ey-xd/motif-react/Carousel';
import { MotifIconButton } from '@ey-xd/motif-react/components/button';
import PlatformService from "../../services/platformService.js";
import './NotificationSlider.css';
import info from '../../assets/image/info-empty.svg'
import notification_issues from '../../assets/image/notification_issues.svg'
import arrow from '../../assets/image/arrowright.svg';
import warningTriangle from '../../assets/image/notification_warning.svg';
import nav_arrow_left from '../../assets/image/nav-arrow-left.svg';
import nav_arrow_right from '../../assets/image/nav-arrow-right.svg';
import pageFlip from '../../assets/image/notification_news.svg';
import close from '../../assets/image/cancel.svg';

export default function NotificationSlider({sendNotificationData}) {
  const platformService = new PlatformService();
  const [notificationData, setNotificationData] = useState("");

  const getNotificationData = async () => {
    let response = await platformService.allNotifications();
    setNotificationData(response);
  };
  useEffect(() => {
    getNotificationData();
  }, []);
    /**
     * Description
     * -----------
     * This method is to select the notification data
     */
    const notificationSelected = (notification) => {
      sendNotificationData(notification);
    };
  /**
   * Description
   * -----------
   * This method is to discard notification
   */
  const discardNotification = async (notification) => {
    const payload = {
      notificationId: notification?.notificationId || null,
    };
    await platformService.discardNotification(payload);
    getNotificationData();
  };

  return (
    notificationData?.length > 0 && (
      <MotifCarousel
        title="Default Carousel"
        icons={{
          next: <img src={nav_arrow_left} alt="" />,
          previous: <img src={nav_arrow_right} alt="" />,
        }}
      >
        {notificationData?.map((data, index) => (
          <div
            key={index}
            className={
              data.notificationTypeId === 1
                ? "knownIssues card-container"
                : data.notificationTypeId === 2
                ? "plannedMaintainance card-container"
                : "card-container"
            }
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={
                  data.notificationTypeId === 1
                    ? notification_issues
                    : data.notificationTypeId === 2
                    ? warningTriangle
                    : data.notificationTypeId === 3
                    ? pageFlip
                    : info
                }
                alt=""
                style={{ marginRight: "12px" }}
              />
              {data.title}
            </div>
            <div
              className="rightPart"
              style={{ display: "flex", alignItems: "center" }}
            >
              <MotifIconButton
                style={{ display: "flex", alignItems: "center" }}
                className="iconBtn"
                onClick={() => notificationSelected(data)}
              >
                View Details
                <img
                  src={arrow}
                  alt="View Details"
                  style={{ marginLeft: "2px" }}
                />
              </MotifIconButton>
              {(data.notificationTypeId === 3 ||
                data.notificationTypeId === 4) && (
                  <img
                    src={close}
                    onClick={() => discardNotification(data)}
                    alt="close"
                    style={{
                      width: "20px",
                      marginLeft: "8px",
                      cursor: "pointer",
                    }}
                  />
                )}
            </div>
          </div>
        ))}
      </MotifCarousel>
    )
  );
}
