import React from "react";
import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import redWarningInfoIcon from "../../../../assets/image/warning-circle-new.svg";
import PlatformService from "../../../../services/platformService";
import "./DeletePromptPopup.css";

export default function DeleteConfirm(props) {
  const platformService = new PlatformService();

  const btnConfirmClick = async () => {
    if (props.tab === "SaTPromptLibrary") {
      const platformPromptIds1 = props.promptsSelected
        .map((item) => item.saTPromptId)
        .join(",");
      let platformPromptRequest = {};
      platformPromptRequest.Ids = platformPromptIds1;
      await platformService.DeleteSatPlatformPrompts(platformPromptRequest);
      props.setshowDeletedComplete(true);
      props.setShowDeleteConfirmPopup(false);
      props.getSatPrompts();
      props.getSatActivePrompts();
    }
    else {
      const platformPromptIds = props.promptsSelected
        .map((item) => item.platformPromptId)
        .join(",");
      let platformPromptRequest = {};
      platformPromptRequest.Ids = platformPromptIds;
      await platformService.DeletePlatformPrompts(platformPromptRequest);
      props.setshowDeletedComplete(true);
      props.setShowDeleteConfirmPopup(false);
    }

  };

  const btnModalCloseClick = () => {
    props.setShowDeleteConfirmPopup(false);
  };

  return (
    // <div className="discard-modal">
    <MotifModal
      id="discard-modal"
      show={true}
      size="lg"
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={btnModalCloseClick}
      className="dialog-modal"
    >
      <MotifModalHeader className="delete-modal-header">
        <div className="title-container">
          <div className="deletePrompt-icon">
            <img alt="deletePrompt icon" src={redWarningInfoIcon} />
          </div>
          <div className="modalDelete-test-instant-title">Delete Prompt</div>
        </div>
      </MotifModalHeader>
      <MotifModalBody>
        <div className="delete-instant-delete-prompt-container">
          <span>
            Selected prompt(s) will be permanently removed from 'Test Prompts'
            tab. Prompt(s) will also be removed from the 'Active Prompts' tab
            and associated responses will be discarded. This action cannot be
            undone. Click 'Confirm' to continue.
          </span>
          <ul className="delete-testInstant-list">
            {(() => {
              return props.promptsSelected?.map((prop) => {
                if (props.tab === "InstantInsightPrompts") {
                  return <li>{prop?.platformPromptTitle}</li>;
                }
                if (props.tab === "SaTPromptLibrary") {
                  return <li>{prop?.title}</li>;
                }
                return null;
              });
            })()}
          </ul>
        </div>

      </MotifModalBody>
      <MotifModalFooter>
        <div className="share-alert-btns-div">
          <div>
            <MotifButton
              variant="primary-alt"
              onClick={() => {
                btnModalCloseClick();
              }}
              className="Cancel-button"
            >
              Cancel
            </MotifButton>
          </div>
          <div>
            <MotifButton className="confirm-button" onClick={btnConfirmClick}>
              Confirm
            </MotifButton>
          </div>
        </div>
      </MotifModalFooter>
    </MotifModal>
    // </div>

  );
}
