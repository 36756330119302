import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifInput,
  MotifRichTextEditor,
  MotifFormField,
  MotifErrorMessage,
  MotifInlineMessage,
  MotifLabel
} from "@ey-xd/motif-react";
import infoIcon from "../../../assets/image/info-empty.svg";
import { useEffect, useRef, useState } from "react";
import Utils from "../../../utils/Utils";
import { duplicateErrorMsg } from "../../../data/constant";


// Component to render Edit Prompt modal
export default function EditPromptModal(props) {
  const utils = new Utils();
  const [prompt, setPrompt] = useState({});
  const [isPromptEdited, setIsPromptEdited] = useState(false);
  const [isSpecChar, setIsSpecChar] = useState(false);
  const [isMaxChar, setIsMaxChar] = useState(false);
  const [isValidTitle, setIsValidTitle] = useState(false);
  const regex = /[`!@$%^+\]\[\\{};'"\|,~\/€£]/;
  const promptRef = useRef(null);
  const motifReactEditorModules = {
    toolbar: [
      [
        {
          list: "ordered",
        },
        {
          list: "bullet",
        },
      ],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const { isAddMode } = props;
  const [errorMessageForTitle, setErrorMessageForTitle] = useState();
  const { promptPersonalData } = props;
  const [errorMessageForPrompt, setErrorMessageForPrompt] = useState();
  const { currentEditedPrompt } = props;
  const INITIALPROMPTTEXT = currentEditedPrompt?.promptText ? currentEditedPrompt?.promptText : "";
  const INITIALPROMPTITLE = currentEditedPrompt?.promptTitle ? currentEditedPrompt?.promptTitle : "";
  const { matchedTitle } = props;
  const [isConfirmBtnDisabled, setIsConfirmBtnDisabled] = useState();
  useEffect(() => {
    console.log("Initial value of PromptTitle", prompt?.promptTitle);
  }, [prompt]);

  useEffect(() => {
    setPrompt(props?.selectedPrompt);
  }, [props?.selectedPrompt]);

  useEffect(() => {
    const checkModalLoaded = () => {
      const promptEditor = promptRef.current;
      if (promptEditor) {
        const numberedBtn = promptEditor.querySelector(
          `button[value="ordered"]`
        );
        const bulletBtn = promptEditor.querySelector(`button[value="bullet"]`);
        if (numberedBtn) {
          utils.addCustomTooltip(
            numberedBtn,
            "Numbered list",
            "modalTooltipNumberedList",
            "bottom"
          );
        }
        if (bulletBtn) {
          utils.addCustomTooltip(
            bulletBtn,
            "Bulleted list",
            "modalTooltipBulletedList",
            "bottom"
          );
        }
      } else {
        // Retry after a short delay if the modal is not yet loaded
        setTimeout(checkModalLoaded, 10);
      }
    };
    checkModalLoaded();

    return () => {
      let tooltipNumberedList = document.getElementById(
        "modalTooltipNumberedList"
      );
      if (tooltipNumberedList) {
        document.body.removeChild(tooltipNumberedList);
      }
      let tooltipBulletedList = document.getElementById(
        "modalTooltipBulletedList"
      );
      if (tooltipBulletedList) {
        document.body.removeChild(tooltipBulletedList);
      }
    };
  }, []);

  /**
   * This method calls when prompt title text changed
   * @param {*} value - value of the title
   */
  const onPromptTitleChange = (value) => {
    if (value.length > 0) {
      if (value.length <= 50) {
        setIsValidTitle(true);
        setIsMaxChar(false);
        setPrompt((prevState) => ({
          ...prevState,
          promptTitle: value,
        }));
        if (regex.test(value)) {
          setIsValidTitle(false);
          setIsSpecChar(true);
        } else {
          setIsValidTitle(true);
          setIsSpecChar(false);
        }
      } else {
        setIsMaxChar(true);
      }
    } else {
      setIsValidTitle(false);
      setPrompt((prevState) => ({
        ...prevState,
        promptTitle: value,
      }));
      setIsMaxChar(false);
      setIsSpecChar(false);
    }
    //if(isAddMode){
    const filteredData = promptPersonalData?.filter((prompt) =>
      prompt?.promptTitle?.toLowerCase() === value.toLowerCase().trim()
    );

    if (filteredData?.length !== 0) {
      setErrorMessageForTitle(duplicateErrorMsg);
    }
    else {
      setErrorMessageForTitle();
    }
    const newData = filteredData[0];
    if (newData?.promptTitle?.trim() === matchedTitle && !isAddMode) {
      setErrorMessageForTitle("")
    }
    //}
  };

  // This method for checking initial title and previous title to show validation
  useEffect(() => {
    if (!prompt.promptTitle || !prompt.promptText || errorMessageForTitle !== '' || isSpecChar) {
      setIsConfirmBtnDisabled(true);
    }
    else {
      setIsConfirmBtnDisabled(false);
    }
  }, [prompt.promptText, prompt.promptTitle, errorMessageForTitle, errorMessageForPrompt, isSpecChar])

  useEffect(() => {
    if ((prompt?.promptTitle === INITIALPROMPTITLE && prompt?.promptText === INITIALPROMPTTEXT) || !prompt?.promptTitle || !prompt?.promptText
      || errorMessageForPrompt?.length > 0 || errorMessageForTitle?.length > 0 || isSpecChar) {
      setIsConfirmBtnDisabled(true);
    }
    else {
      setIsConfirmBtnDisabled(false);
    }

  }, [prompt?.promptText, prompt?.promptTitle, INITIALPROMPTITLE, INITIALPROMPTTEXT, errorMessageForPrompt, errorMessageForTitle, isSpecChar])
  //   useEffect(() => {
  //     const normalizeText = (text) => {
  //       if (!text || text.trim() === "" || text === "<p><br></p>") return "";
  //       return text.trim();
  //     };

  //     const trimmedTitle = normalizeText(prompt?.promptTitle);
  //     const trimmedPromptText = normalizeText(prompt?.promptText);
  //     const initialTitle = normalizeText(currentEditedPrompt?.promptTitle);
  //     const initialPromptText = normalizeText(currentEditedPrompt?.promptText);

  //     // Detect if the user actually typed or deleted something
  //     const isTitleEdited = prompt?.promptTitle !== currentEditedPrompt?.promptTitle;
  //     const isPromptEdited = prompt?.promptText !== currentEditedPrompt?.promptText;

  //     // If user clears and retypes the same text, still count as a change
  //     const wasTitleCleared = currentEditedPrompt?.promptTitle && trimmedTitle === "";
  //     const wasPromptCleared = currentEditedPrompt?.promptText && trimmedPromptText === "";

  //     const isTitleChanged = isTitleEdited || wasTitleCleared;
  //   const isPromptChanged = isPromptEdited || wasPromptCleared;
  //   const isTitleEmpty = trimmedTitle === "";
  //   const isPromptEmpty = trimmedPromptText === "";
  //   const hasErrors = errorMessageForTitle?.length > 0 || errorMessageForPrompt?.length > 0;

  //   if ((!isTitleChanged && !isPromptChanged) || isTitleEmpty || isPromptEmpty || hasErrors) {
  //     setIsConfirmBtnDisabled(true);
  //   } else {
  //     setIsConfirmBtnDisabled(false);
  //   }
  // }, [prompt?.promptTitle, prompt?.promptText, errorMessageForTitle, errorMessageForPrompt, currentEditedPrompt]);




  /**
   * This method calls when rich text box text changed
   * @param {*} value - value of the text
   */
  const onPromptTextChange = (value) => {
    setPrompt((prevState) => ({
      ...prevState,
      promptText: value,
    }));
  };

  /**
   * This method enables the confirm button when user type something
   */
  const onPromptEdited = () => {
    setIsPromptEdited(true);
  };

  /**
   * Allow special keys like Tab, Enter, Delete, Backspace & Control char combination to enable Confirm button
   */
  const onPromptEditKeyUp = (e) => {
    const key = e?.key;
    const nativeEvent = e?.nativeEvent;
    if (
      key === "Tab" ||
      key === "Enter" ||
      key === "Delete" ||
      key === "Backspace" ||
      (nativeEvent?.composed && nativeEvent?.ctrlKey)
    ) {
      setIsPromptEdited(true);
    }
  };

  /**
   * This method is to enable/disable the confirm button
   * @returns boolean value
   */
  const confirmDisableCheck = () => {
    let promptTextVal = prompt?.promptText || "";
    promptTextVal = promptTextVal?.replace(/<[^>]+>/g, "").trim();
    return (
      promptTextVal.length === 0 || !prompt?.promptTitle.trim() || !isPromptEdited || (prompt?.promptTitle === INITIALPROMPTITLE && promptTextVal === INITIALPROMPTTEXT)
    );
  };

  /**
    * This method removes the max character message 
    */
  const handleBlur = () => {
    setIsMaxChar(false);
  }

  return (
    <MotifModal
      id="badgepopupmodal"
      size="xl"
      show={props.showEditPromptModal}
      onClose={() => {
        if (props?.isOpenPromptModal) {
          setPrompt(props?.selectedPrompt);
        }
        props.toggleEditPromptModal({})
        setErrorMessageForTitle();
        setIsSpecChar(false);
      }}
      className="genericModal modalHeaderVerticalPadding modalMotifFooterModal"
    >
      <MotifModalHeader
        closeButtonProps={{
          "aria-label": "Close Edit Prompt Modal",
          title: !isAddMode ? "Close Edit Prompt Modal" : "Close Add new Prompt Modal",
        }}
      >
        <div className="add-edit-prompt-modal-header add-edit-prompt-modal-header-title">
          {!isAddMode ? 'Edit prompt' : 'Add New Prompt'}
        </div>
        {/* Edit prompt */}
      </MotifModalHeader>
      <MotifModalBody>
        <div>
          <MotifFormField className="title-label">
            <MotifLabel>
              Title
            </MotifLabel>
          </MotifFormField>
        </div>
        {/* <div className="info"> */}
        {/* <span className="prompt-title">Title</span> */}
        <MotifFormField className="password-input-wrapper">
          <MotifInput
            label="inputtitle"
            onChange={(e) => {
              onPromptTitleChange(e.target.value);
            }}
            type="text"
            placeholder={"Enter a title"}
            value={prompt?.promptTitle}
            onKeyPress={onPromptEdited}
            onKeyUp={onPromptEditKeyUp}
            className={`prompt-textarea ${errorMessageForTitle ? 'error-border' : ''}`}
            onBlur={handleBlur}
          />
        </MotifFormField>
        {/* </div> */}
        <div className="error_container">
          {isSpecChar && (
            <MotifErrorMessage >
              Special characters not allowed
            </MotifErrorMessage>
          )}
          {!isSpecChar && isMaxChar && (
            <MotifInlineMessage className="max-char-reached-messaage">
              Maximum limit reached - 50 characters
            </MotifInlineMessage>
          )}
          {errorMessageForTitle && (
            <MotifErrorMessage>
              {duplicateErrorMsg}
            </MotifErrorMessage>
          )}
        </div>
        <div className="add-new-personal-prompt">
          < MotifFormField className="password-input-wrapper">
            <MotifLabel>
              Prompt
            </MotifLabel>
          </MotifFormField>
        </div>
        <div ref={promptRef}>
          <MotifRichTextEditor
            className="input-title"
            id="rtePromptText"
            placeholder="Enter a prompt"
            value={prompt?.promptText}
            modules={motifReactEditorModules}
            onChange={onPromptTextChange}
            onKeyPress={onPromptEdited}
            onKeyUp={onPromptEditKeyUp}
          />
        </div>
        <div className="edit-prompt-disclaimer">
          <img
            src={infoIcon}
            className="edit-prompt-disclaimer-icon"
            alt="icon"
          />

          <MotifLabel className="prompt-title">
            Please ensure client information is not included in the prompt
          </MotifLabel>

        </div>
      </MotifModalBody>
      <MotifModalFooter className="prompt-modal-footer">
        <MotifButton
          id="btnConfirm"
          onClick={() => props.handleEditPrompt(prompt)}
          size="medium"
          type="button"
          className="prompt-modal-confirm-button"
          disabled={isConfirmBtnDisabled || confirmDisableCheck()}
        >
          Confirm
        </MotifButton>

        <MotifButton
          id="btnCancel"
          onClick={() => {
            if (props?.isOpenPromptModal) {
              setPrompt(props?.selectedPrompt);
            }
            props.toggleEditPromptModal({});
            setErrorMessageForTitle();
            setIsSpecChar(false);
          }}
          size="medium"
          type="button"
          variant="secondary"
          className="prompt-modal-cancel-button"
        >
          Cancel
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
}
