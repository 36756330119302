import React from "react";
import PropTypes from "prop-types";
import { MotifButton } from "@ey-xd/motif-react";

const ButtonWithAddon = ({
  children,
  startAddon = null,
  endAddon = null,
  onClick,
  variant = "primary",
  size = "small",
  disabled = false,
  className = "",
  ...props
}) => {
  return (
    <MotifButton
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
      className={className}
      {...props}
    >
      {startAddon && (
        <span
          style={{ display: "flex", justifyContent: "center", marginRight: 8 }}
        >
          {startAddon}
        </span>
      )}
      <span>{children}</span>
      {endAddon && (
        <span
          style={{ display: "flex", justifyContent: "center", marginLeft: 8 }}
        >
          {endAddon}
        </span>
      )}
    </MotifButton>
  );
};

ButtonWithAddon.propTypes = {
  children: PropTypes.node.isRequired,
  startAddon: PropTypes.node,
  endAddon: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ButtonWithAddon;
