import React, { useEffect, useState, useContext } from "react";
import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import { MotifChip } from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/components/button";
import MotifCheckbox from "@ey-xd/motif-react/Checkbox";
import editPencil from "../../../../../assets/image/edit.svg";
import PropTypes from "prop-types";

const PromptAccordion = (props) => {
  /**
   * This method is a click operation of the accordion click
   * @param {*} event
   */
  const onClickAccordion = (event) => {
    if (event.target.className === "motif-checkbox-custom") {
      props.onCheckPrompt(props?.prompt?.platformPromptId, true);
    } else if (
      event.target.parentNode.className === "motif-checkbox-checked-icon"
    ) {
      props.onCheckPrompt(props?.prompt?.platformPromptId, false);
    } else if (event.target.nodeName === "svg" || event.target.nodeName === "path") {
      props.onAccordionClick(props?.prompt?.platformPromptId);
    } else if (
      event.target.nodeName === "svg" ||
      (event.target.className?.trim() !== "ii-prompt-detail-text" &&
        event.target.className?.trim() !== "motif-accordion-content")
    ) {
     props.onAccordionClick(props?.prompt?.platformPromptId);
    }

  };

  return (
    <MotifAccordion
      open={props.isOpen}
      alignIconRight={true}
      useChevronIcon={true}
      onClose={() => { }}
      onOpen={() => { }}
      onClick={($event) => onClickAccordion($event)}
      className={
        props?.prompt?.isChecked
          ? "ii-prompt-selected-accordion"
          : "ii-prompt-accordion"
      }
      data-testid="accordionPromptTitle"
    >
      <MotifAccordionTrigger>
        <div className="accordion-title">
          <div className="ii-response-accordion-trigger">
            {(() => {
              if (
                props.type === "activePrompt"
              ) {
                return "";
              }
              else {
                return (
                  <MotifCheckbox
                    name="chkPrompt"
                    className={props?.prompt?.isChecked ? "motif-checkbox-checked" : ""}
                    checked={props?.prompt?.isChecked}
                    value={props?.prompt?.isChecked}
                    disabled={!props?.prompt?.isEditFlag}
                  ></MotifCheckbox>
                );
              }
            })()}
            {
              props?.prompt?.platformPromptTitle
            }
            {
              props.type === "testPrompt" && props?.prompt?.isEditFlag &&
              <MotifChip
                onClick={null}
                title="Edited"
                className="edited-chip"
              >
                Edited
              </MotifChip>
            }
          </div>
          <div>
            {
              props.type === 'testPrompt' &&
              <MotifIconButton onClick={props.onEditIconClick}>
                <img alt='icon' src={editPencil} />
              </MotifIconButton>
            }
          </div>
        </div>
      </MotifAccordionTrigger>
      <MotifAccordionContent>
        {
          (() => {
            if (props?.prompt?.platformPromptText != null) {
              return (<div className="ii-prompt-detail-text">{props?.prompt?.platformPromptText}</div>)
            } else {
              return (<div className="ii-prompt-detail-text">No prompt found</div>)
            }
          })()
        }
      </MotifAccordionContent>
    </MotifAccordion>
  );
};

PromptAccordion.propTypes = {
  prompt: PropTypes.object,
  tab: PropTypes.string,
  node: PropTypes.object,
  type: PropTypes.string,
  onCheckPrompt: PropTypes.func,
  onEditIconClick: PropTypes.func,
  editTextPrompts: PropTypes.func
}

export default PromptAccordion;
